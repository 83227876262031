<div class="row pt-4 pb-2">
    <div class="col-md d-grid mx-auto">
        <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link" routerLink="/tabnew" routerLinkActive="active"><img class="ps_img_resize" title="Public grid" src="assets/images/1x/asset1.png"></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/tabstorage" routerLinkActive="active"><img class="ps_img_resize" title="Battery" src="assets/images/1x/asset2.png"></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/tabgenerator" routerLinkActive="active"><img class="ps_img_resize" title="Gas Turbine" src="assets/images/1x/asset3.png"></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/tabcharger" routerLinkActive="active"><img class="ps_img_resize" title="Charger" src="assets/images/1x/asset4.png"></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/tabbuilding" routerLinkActive="active"><img class="ps_img_resize" title="Consumer" src="assets/images/1x/asset5.png"></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/tabsolar" routerLinkActive="active"><img class="ps_img_resize" title="Solar PV" src="assets/images/1x/asset6.png"></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/tabwind" routerLinkActive="active"><img class="ps_img_resize" title="Wind Turbine" src="assets/images/1x/asset7.png"></a>
            </li>
        </ul>
    </div>    
</div> 