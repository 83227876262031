<div id="ps_profilepage" class="container bg-light">
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <app-user-main-menu></app-user-main-menu>
    </div>

    <div class="col-6">
      <div class="row pt-4">
          <div class="col d-grid mx-auto">
              <h2>Let's start.</h2>
              <h3>Click on the hexagon to import information and conclude to calculation.</h3>
              
              <div class="row pt-1">
                  <div class="col-4 d-grid mx-auto">
                    <button type="button" class="btn ps_renergise_btn">Tutorial</button>
                  </div>
                  <div class="col-4 d-grid mx-auto">
                    <button type="button" class="btn ps_renergise_btn">Skip</button>
                  </div>
                  <div class="col-4 d-grid mx-auto"></div>
                </div>
          </div>
      </div>
    </div>
</div>
</div>