<div id="ps_tab6" class="container bg-light">
    <!-- <app-project-name-label></app-project-name-label> -->
    <div class="row ps_padding_20">
        <div *ngIf="user">
            <div class="row">
                <p style="font-weight: bold;">How do I use the graph?</p>
                <p style="text-align: justify;">
                    Specific functionality has been added to the chart due to the large amount of presented information.<br/>
                    The user may click the zoom buttons according to the desired zoom depth and then focus on the dates axis by dragging the chart accordingly (click and drag).<br/>
                    It is possible to zoom in and out of the chart using the mouse scroll wheel. <br/>
                    Furthermore, the user may zoom out to the full view of the chart by clicking the reset zoom button. <br/>
                    Finally, the user may have a clearer view of the chart (less data) by disabling specific elements. This can be done by clicking on the specific element/s on the memo of the chart.   
                </p>
                <!-- <p style="font-weight: bold;">How do I see data in graph?</p> -->
                <div style="text-align: justify;">
                    <ol type="1">
                        <li>Click on assets dropdown menus and select an asset for each project.</li>
                        <li>The daily representation is selected by default.</li>
                        <li>By changing the time series representation (daily, weekly, monthly, etc), the previously selected data will be automatically updated.</li>
                    </ol>  
                </div>
            </div>
            <div class="row" >
                <div class="col-sm-2" >
                    <mat-form-field >
                        <!-- <mat-label> Select range </mat-label> -->
                        <mat-select (selectionChange)="onOptionSelect($event)" [(value)] = "selected">
                            <mat-option value ="monthly" >monthly</mat-option>
                            <mat-option value ="weekly" >weekly</mat-option>
                            <mat-option value ="daily" >daily</mat-option>
                            <mat-option value ="hourly" >hourly</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-2" >
                    <mat-form-field >
                        <mat-label> {{project_1}} assets</mat-label>
                        <mat-select [(ngModel)]="selectedValue1" name = "headerChartAsset1" (selectionChange)="onSelect($event)">
                            <mat-option *ngFor="let headerChartAsset1 of headerChartAssets1" [value] ="headerChartAsset1" >{{headerChartAsset1}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-2" >
                    <mat-form-field >
                        <mat-label> {{project_2}} assets</mat-label>
                        <mat-select [(ngModel)]="selectedValue2" name = "headerChartAsset2" (selectionChange)="onSelect($event)">
                            <mat-option *ngFor="let headerChartAsset2 of headerChartAssets2" [value] ="headerChartAsset2" >{{headerChartAsset2}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col" id = "chart">
                    <canvas id="canvas" #canvas>{{ chart }}</canvas>
                </div>
            </div>
            
            <div class="row">
                <div class="col ap_button_centered">
                    <button type="button" class="btn ps_renergise_btn ap_spacing " routerLink="/compare" routerLinkActive="active" title ="Back to compare">Back</button>
                    <button type="button" class="btn ps_renergise_btn zoom_button ap_spacing " (click)="zoomOutOption()" (mouseover)="changeZoomOutImg()" (mouseleave) = "changeZoomOutImgReset()" title ="Zoom out"><img title="Zoom out" src = {{zoomOutImg}}></button>
                    <button type="button" class="btn reset_button ap_spacing" title ="Reset zoom" (click)="resetZoom()">Reset zoom</button>
                    <button type="button" class="btn ps_renergise_btn zoom_button ap_spacing " (click)="zoomInOption()" (mouseover)="changeZoomInImg()" (mouseleave) = "changeZoomInImgReset()" title ="Zoom in"><img title="Zoom in" src = {{zoomInImg}}></button>
                    <button type="button" class="btn ps_renergise_btn ap_spacing " routerLink="/compare-results" routerLinkActive="active" title ="Compare total results">Total Results</button>
                </div>
            </div>
        </div>
    </div>
</div>