<div id="ps_generalpage" class="container bg-light">
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <div class="row pt-4">
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabgeneral" routerLinkActive="active" title="General option"><img title="General Information" class= "img-fluid" src="assets/images/1x/btn_assetGeneralSelected.png"></a></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabnew" routerLinkActive="active" title="New Asset option"><img title="New Asset" class= "img-fluid" src="assets/images/1x/btn_assetNew.png"></a></div>
          <div class="col-sm-6 d-grid mx-auto"></div>
      </div>    
      
      <div class="row">
          <div class="col-sm-1 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabmodify" routerLinkActive="active" title="Asset Modify option"><img title="Asset Modify" class= "img-fluid" src="assets/images/1x/btn_assetModify.png"></a></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/compare" routerLinkActive="active" title="Compare Projects"><img title="Compare Projects" class= "img-fluid" src= "assets/images/1x/btn_assetCompare.png"></a></div>
          <div class="col-sm-5 d-grid mx-auto"></div>
      </div> 
      
      <div class="row">
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/calculate" routerLinkActive="active" title="Calculate option"><img title="Calculate" class= "img-fluid" src="assets/images/1x/btn_calculate.png"></a></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
      </div> 
    </div>
    <!-- <div class="col-md-6 ps_form rounded ps_white" *ngIf = "isCompleted">
            <div class = "progress_position" >
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <p>Downloading environment information (This might take a while.)...Please wait.</p>
            </div>
    </div> -->
    <div class="col-md-6 ps_form rounded ps_white">
      <form [formGroup]="generalForm" (ngSubmit)="btnClickSubmit()">
        <div class="form-group">
          <h1>New project</h1>
          <h2>Please fill in site local (general) information.</h2>

          <div class="row">
            <div class="col-8 d-grid mx-auto">
              <div class="ps_logo_green">What is site information?</div>
              <div>This is the geographical info about the site in which a Local Energy Systems (LES) configuration will be examined and the particular investigation time.</div>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-2">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="project_id" name="project_id" id="project_id" #project_id type="text" class="form-control ps_renergise_btn" title="Project name" placeholder="Project name"/>
                <span *ngIf="!generalForm.get('project_id').valid && generalForm.get('project_id').touched" class="help-block">
                  <span *ngIf="generalForm.get('project_id').errors['required']" style="font-size: small" >This field is required.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input (click)="googleMapsShow()" formControlName="location" name="location" id="location" #location type="text" class="form-control ps_renergise_btn" title="Use map below to enter location coordinates" placeholder="Use map below to enter location coordinates"/>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto">
              <fa-icon [icon]="faMapMarkerAlt" size="2x" [styles]="{ color: '#FCC78C' }"></fa-icon>
            </div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <div [@inOutAnimation] *ngIf="isGoogleMapsShown" class="row container-fluid" id="divGMshow">
                <app-google-maps></app-google-maps>
              </div>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input [(ngModel)]="selectYear" (click)="picker1.open()" [min]="minDate" [max]="maxDate" [matDatepicker]="picker1" formControlName="year" name="year" id="year" #year class="form-control ps_renergise_btn" title = "Select reference year for weather data" placeholder="Select reference year for weather data"/>
                <mat-datepicker #picker1 startView="multi-year" (yearSelected)="chosenYearHandler($event)"></mat-datepicker>
                <span *ngIf="!generalForm.get('year').valid && generalForm.get('year').touched" class="help-block">
                  <span *ngIf="generalForm.get('year').errors['required']" style="font-size: small">This field is required.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto">
              <fa-icon mat-raised-button [icon]="faCalendarWeek" size="2x" [styles]="{ color: '#FCC78C' }"></fa-icon>
            </div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input type="number" formControlName="interest_rate" name="interest_rate" id="interest_rate" #interest_rate class="form-control ps_renergise_btn" title="Current financial interest rate[%]" placeholder="Current financial interest rate[%]"/>
                <span *ngIf="!generalForm.get('interest_rate').valid && generalForm.get('interest_rate').touched" class="help-block">
                  <span *ngIf="generalForm.get('interest_rate').errors['required']" style="font-size: small">This field is required.</span>
                  <span *ngIf="generalForm.get('interest_rate').errors['min']" style="font-size: small">This parameter must be higher than 0.</span>
                  <span *ngIf="generalForm.get('interest_rate').errors['max']" style="font-size: small">This parameter must be less than 100.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
        </div>
      </form>
      <div class="row pt-1">
        <div class="col-4 d-grid mx-auto">
          <button type="button" class="btn ps_renergise_btn" (click)="btnClickClear()">Clear</button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <button type="button" class="btn ps_renergise_btn" (click)="btnClickSubmit()"> Submit</button>
        </div>
        <div class="col-4 d-grid mx-auto"></div>
      </div>
    </div>
  </div>
</div>
