import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { GlobalConstants } from '../common/global-constants';
// import { CustomNameValidators } from './confirmed.validator';
import { AuthResponseData } from '../auth.service';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  @ViewChild('email') email: ElementRef;
  @ViewChild('password') password: ElementRef;
  @ViewChild('confirm_password') confirm_password: ElementRef;
  @ViewChild('privacy_policy') privacy_policy: ElementRef;


  response: Object

  signupForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.pattern('^([a-zA-Z0-9-$@$!%*?_&#^]{6,})$')]],
    confirm_password: ['', [Validators.required, Validators.pattern('^([a-zA-Z0-9-$@$!%*?_&#^]{6,})$')]],
    privacy_policy: ['',[Validators.required]]
  })
  localErrorMessage = '';
  setLocalErrorMessage:boolean = false;
  user_credentials: { email: string, password: string };
  userId = '';
  public showPassword1: boolean = false;
  public showPassword2: boolean = false;

  
  constructor(private fb: FormBuilder, private router: Router, private http: HttpClient, public authService: AuthService) { }

  ngOnInit(): void {}

  btnClickBack() {
    console.log('Btn Back clicked');
    this.router.navigateByUrl('/');
  }

  btnClickSignUp() {
    console.log('Btn Singup clicked');
    if (this.confirm_password.nativeElement.value === this.password.nativeElement.value && this.confirm_password.nativeElement.value !== '' && this.password.nativeElement.value !== '') {
      this.user_credentials = {email: this.email.nativeElement.value, password: this.password.nativeElement.value};
      this.authService.SignUp(this.user_credentials.email, this.user_credentials.password);
    }
    else {
      this.localErrorMessage = 'Passwords do not match.';
      this.setLocalErrorMessage = true;
    }
  }

  public togglePasswordVisibility1(): void {
    this.showPassword1 = !this.showPassword1;
  }

  public togglePasswordVisibility2(): void {
    this.showPassword2 = !this.showPassword2;
  }
}
