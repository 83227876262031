// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {

    apiKey: "AIzaSyB6upPSvenl-n1wpEE6tVYNH25J5mxJvHE",
  
    authDomain: "renergise-6cb54.firebaseapp.com",
  
    databaseURL: "https://renergise-6cb54-default-rtdb.firebaseio.com",
  
    projectId: "renergise-6cb54",
  
    storageBucket: "renergise-6cb54.appspot.com",
  
    messagingSenderId: "1001494078560",
  
    appId: "1:1001494078560:web:4fe22419bca3c51c7c0f05",
  
    measurementId: "G-GBBE01XPZB"
  
  }  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
