import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { GlobalConstants } from '../common/global-constants';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
@Component({
  selector: 'app-modify-asset',
  templateUrl: './modify-asset.component.html',
  styleUrls: ['./modify-asset.component.css']
})
export class ModifyAssetComponent implements OnInit {
  user = GlobalConstants.registeredUser;
  project = GlobalConstants.projectId;
  assets: Object;
  assetIds = []; //for ngFor in html file in order to find assets
  assetArray = []; //for ngFor in html file in order to find assets
  selectedLevel;
  asset: any;


  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private authService: AuthService, public dialog: MatDialog) { }

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    else{
      alert('You must select a project to continue.');
      this.router.navigateByUrl('/myprojects');
    }

    var host = "https://renergisetool.evergi.be/";
    var url = host + "users/" + this.user + "/projects/" + this.project;
    this.assetArray = [];
    this.assetIds = [];

    if(this.user){
      this.http.get<any>(url, { observe: 'response' }).subscribe(
        response => {
          if (response['status'] == 200) {
            console.log("response: " + response);
            this.assets = response.body[this.project]['assets'];
            var assetHeaders = Object.keys(this.assets);
            for (var i = 0; i < assetHeaders.length; i++) {
              if (Object.keys(this.assets[assetHeaders[i]]).length == 0) {
                (document.getElementById(assetHeaders[i]) as HTMLImageElement).src = "assets/images/1x/btn_assetEmpty.png";
              }
              else if (Object.keys(this.assets[assetHeaders[i]]).length < 5) {
                (document.getElementById(assetHeaders[i]) as HTMLImageElement).src = "assets/images/1x/asset_" + assetHeaders[i] + "_" + Object.keys(this.assets[assetHeaders[i]]).length + ".png";
              }
              else {
                (document.getElementById(assetHeaders[i]) as HTMLImageElement).src = "assets/images/1x/asset_" + assetHeaders[i] + "_5.png";
              }
              this.assetArray[i] = Object.keys(this.assets[assetHeaders[i]]);
              if (this.assetArray[i].length === 0) {
                this.assetIds.push([]);
              }
              else {
                var temp = [{ asset_id: 'string', asset_name: 'string' }];
                for (var j = 0; j < this.assetArray[i].length; j++) {
                  temp[j] = ({ asset_id: this.assetArray[i][j], asset_name: this.assets[assetHeaders[i]][this.assetArray[i][j]]["asset_id"] });
                }
                this.assetIds.push(temp)
              }
            }
          }
        },
        error => {
          console.log('error', error);
  
        }
      );
    }
    
  }

  onEdit(assetID: string, assetCategory: string) {
    GlobalConstants.assetToModify = assetID;
    this.router.navigate(["/" + assetCategory], { relativeTo: this.route });
  }
  
  openDialogDelete(assetName:string, assetID: string, assetCategory: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        message: 'Are you sure want to delete asset ' + assetName + '?',
        buttonText: {
          ok: 'Delete',
          cancel: 'Cancel'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        var host = "https://renergisetool.evergi.be/";
        var numberPattern = /\d+/g;
        var assetId_number = assetID.match(numberPattern)[0];
        var url = host + "users/" + this.user + "/projects/" + this.project + "/" + assetCategory + "/" + assetId_number;

        this.http.delete(url, { observe: 'response' }).subscribe(
          response => {
            console.log("Asset with asset id " + assetID + "and asset name " + assetName + "was deleted." + "Response "+ response);
            if (response['status'] === 200) {
              this.ngOnInit();
            }
          },
          error => {
            console.log("error: " + error);
          }
        );
      }
    });
  }
}
