<div id="ps_importdatafile" class="container bg-light">
    <app-project-name-label></app-project-name-label>
    <div class="row ps_padding_20">
        <div class="col-md-6">
            <div class="row pt-4">
                <div class="col-sm-2 d-grid mx-auto"></div>
                <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabgeneral" routerLinkActive="active" title="General option"><img title="General Information" class= "img-fluid" src="assets/images/1x/btn_assetGeneral.png"></a></div>
                <div class="col-sm-2 d-grid mx-auto"></div>
                <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabnew" routerLinkActive="active" title="New Asset option"><img title="New Asset" class= "img-fluid" src="assets/images/1x/btn_assetNew.png"></a></div>
                <div class="col-sm-2 d-grid mx-auto"></div>
            </div>    
            
            <div class="row">
                <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabmodify" routerLinkActive="active" title="Asset Modify option"><img title="Asset Modify" class= "img-fluid" src="assets/images/1x/btn_assetModify.png"></a></div>
                <div class="col-sm-1 d-grid mx-auto"></div>
                <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabimpconfigfile" routerLinkActive="active" title="Import Configuration File option"><img title="Import Configuration File" class= "img-fluid" src="assets/images/1x/btn_impConfFiles.png"></a></div>
                <div class="col-sm-1 d-grid mx-auto"></div>
                <div class="col-md-3 d-grid mx-auto"><img title="Import Data File" class= "img-fluid" src="assets/images/1x/btn_impDataFilesSelected.png"></div>
            </div> 
            
            <div class="row">
                <div class="col-sm-2 d-grid mx-auto"></div>
                <div class="col-md-3 d-grid mx-auto"><a routerLink="/compare" routerLinkActive="active" title="Compare Projects"><img title="Compare Projects" class= "img-fluid" src= "assets/images/1x/btn_assetCompare.png"></a></div>
                <div class="col-sm-2 d-grid mx-auto"></div>
                <div class="col-md-3 d-grid mx-auto"><a routerLink="/calculate" routerLinkActive="active" title="Calculate option"><img title="Calculate" class= "img-fluid" src="assets/images/1x/btn_calculate.png"></a></div>
                <div class="col-sm-2 d-grid mx-auto"></div>
            </div> 
        </div>

      <div class="col-md-6 ps_form">
        <h1><img class="img-fluid ps_imgsize" title="Import Data Files" src="assets/images/1x/importImage.png"> Import Data Files</h1>
        <h2>Please upload existing data files.</h2>
        
        <div class="row ">
            <!-- <div class="col-8 d-grid mx-auto"> -->
                <div class="ps_logo_green">How and why do I import data files?</div>
                <div>This feature allows you to upload previously saved information about individual parts/ assets so that RENERGiSE gives you a benchmarking of the selected alternatives. Just choose the file that incorporates the raw asset data from a local directory of your computer and press submit to load it in the respective list.</div>
            <!-- </div>
            <div class="col-4 d-grid mx-auto"></div> -->
        </div>    
        
        <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
                <form-field appearance="fill">       
                    <select class="form-control ps_renergise_btn" #environment_file >
                        <option *ngFor="let environment of environments" [value]="environment.key_name">
                            {{environment.file_name}}
                        </option>
                    </select>
                </form-field>    
            </div>
            <div class="col-4 d-grid mx-auto">
                <button type="button" class="btn ps_renergise_btn" (click)="btnClickGetItem()">Get file</button>
            </div>
        </div>

        <div class="row pt-1">
            <div class="col d-grid mx-auto"><hr class="ps_horisontal_line"></div>
        </div>

        <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
                <input  name="loadDataFile" id="loadDataFile" #loadDataFile type="file" class="form-control ps_renergise_btn" placeholder="Load local data file or folder" observeFiles (change)="onChange($event)">
            </div>
            <div class="col-4 d-grid mx-auto">
                <!--<fa-icon mat-raised-button [icon]="faSave" size="2x" [styles]="{'color': '#FCC78C'}"></fa-icon>-->
            </div>
        </div> 

        <div class="row pt-1">
            <div class="col-4 d-grid mx-auto">
                <button type="button" class="btn ps_renergise_btn" (click)="btnClickCancel()">Cancel</button>
            </div>
            <div class="col-4 d-grid mx-auto">
                <button type="button" class="btn ps_renergise_btn" (click)="btnClickSubmit()">Submit</button>
            </div>
            <div class="col-4 d-grid mx-auto">
                
            </div>
        </div> 

      </div>
      
  </div>
</div>