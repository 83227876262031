
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { GlobalConstants } from '../common/global-constants';

interface Environment {
  key_name : string,
  file_name: string;
}

@Component({
  selector: 'app-adding-data-files',
  templateUrl: './adding-data-files.component.html',
  styleUrls: ['./adding-data-files.component.css']
})



export class AddingDataFilesComponent implements OnInit {

  constructor(private http: HttpClient) { }

  @ViewChild('alreadyLoadedData') data : ElementRef;
  @ViewChild('environment_file') environment_file: ElementRef;
  @ViewChild('loadDataFile') loadDataFile: ElementRef;

  faSave = faSave;



  private url = "http://localhost:5000/users/1234/projects/jette/environments/";

  files: File[] = null;



  environments: Environment[];

  user = GlobalConstants.registeredUser;

  onChange(event) {
    if (event.target.files[0]) {
      this.files = event.target.files;
    }
    else {
      this.files = null;
    }
  }

  ngOnInit(): void {

    var host = "https://renergisetool.evergi.be/";
    // var host = "http://localhost:5000/"
    var user = 30000;
    var project = "test";

    var url = host + "users/" + user + "/projects/" + project + "/environments/";

    this.http.get(url).subscribe(
      data => {
        this.environments = [];
        for (let key in data) {
          let value : string = data[key];
          if (value.substr(-4,4) == ".csv") {
            this.environments.push({key_name: key, file_name: value});
          }
        }
      }
    )


  }


  btnClickCancel(){
    console.log("Btn Cancel clicked");
    //this.router.navigateByUrl('/signup');
  }

  btnClickSubmit(){
    console.log("Btn Submit clicked");

    // console.log("Btn Add Asset clicked");

    if (this.files == null) {
      // this.post_message.nativeElement.innerHTML = "failed"
      console.log("failed");
      return
    }

    var host = "https://renergisetool.evergi.be/";
    // var host = "http://localhost:5000/"
    var user = 30000;
    var project = GlobalConstants.projectId;

    const formdata = new FormData();

    Array.from(this.files).forEach(file => {
      formdata.append("environment_files",file, file.name);
    })
    

    // formdata.append("powerprofile",this.file,this.file.name)
    const params = new HttpParams();

    var url = host + "users/" + user + "/projects/" + project + "/environments/";
    this.http.post<any>(url + '?' + params.toString(), formdata , {observe : 'response'}).subscribe(
      response => {
        console.log(response);
        if (response['status'] == 200){
          // this.post_message.nativeElement.innerHTML = "succes"
          console.log("succes");
        }
        else {
          // this.post_message.nativeElement.innerHTML = "failed"
          console.log("failed");
        }
      }
    )
    //this.router.navigateByUrl('/newprojectadd');
  }



  btnClickGetItem(){

    console.log(this.url + this.environment_file.nativeElement.value);
    const headers = new HttpHeaders();

    var filename: string = this.environment_file.nativeElement.value + ".csv";

    this.http.get(this.url + this.environment_file.nativeElement.value,{headers, responseType: 'blob' as 'json'}).subscribe(
      (response: any) =>{
          console.log(response.type)
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          console.log(downloadLink)
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          if (filename)
              downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
      });
    //this.router.navigateByUrl('/newprojectadd');
  }
}

