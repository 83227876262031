<div id="ps_footer" class="container">
  <div class="row">
    <div class="col-4">
      <img alt="Renaissance logo" class="img-fluid" src="assets/images/1x/renaissance_logo_footer.png">
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-2">
          <div class="ps_bold">CONTACTS</div>
          <div><a title="Renaissance contact form" href="https://www.renaissance-h2020.eu/contacts/">Contact Form</a></div>
      </div>
        <div class="col-md-2">
          <div class="ps_bold">INFO</div>
          <!-- <div>Info</div> -->
          <div><a title="Renaissance official website" href="https://www.renaissance-h2020.eu/">Renaissance Website</a></div>
        </div>
        <div class="col-md-2">
          <div class="ps_bold">EXPLORE</div>
          <div><a title="Renergise Home page" routerLinkActive="active" routerLink (click)="onClick()">Home</a></div>
          <!-- <div><a title="Renergise About page" routerLink="/about" routerLinkActive="active" style="display: hidden;">About</a></div>   -->
          <!-- <div><a title="My Projects page" routerLink="/myprojects" routerLinkActive="active">My Projects</a></div> -->
        </div>
        <div class="col-md-2">
          <div class="ps_bold">LEGAL</div>
          <div><a title="Privacy Policy page" routerLink="/privacypolicy">Privacy Policy</a></div>
        </div>
        <div class="col-md-2">
          <div class="ps_bold">FOLLOW</div>
          <div><a title="Renaissance Twitter page" href="https://twitter.com/H2020_Ren">Twitter</a></div>
          <div><a title="Renaissance Linkedin page" href="https://www.linkedin.com/company/renaissance-h2020/">LinkedIn</a></div>
        </div>
      </div>
    </div>
</div>

<div class="row pt-5">
  <div class="col ps_copyright"><img class="img-fluid" src="assets/images/EU-European-Union-Flag-icon.png"> This project has received funding from the European Union’s H2020 research and innovation programme under the grant agreement No 824342. All rights reserved.</div>
</div>

</div>
