import { Component, OnInit } from '@angular/core';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { ViewChild, ElementRef } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { trigger, style, animate, transition } from '@angular/animations';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

const leaveTrans = transition(':enter', [
  style({
    opacity: 1
  }),
  animate('3s ease-out', style({
    opacity: 0,
  }))
])

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-new-asset3-generator',
  templateUrl: './new-asset3-generator.component.html',
  styleUrls: ['./new-asset3-generator.component.css'],
  animations: [
    fadeOut,
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('2s ease-out',
              style({ height: 40, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 40, opacity: 1 }),
            animate('2s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class NewAsset3GeneratorComponent implements OnInit {

  @ViewChild('asset_id') asset_id: ElementRef;
  // @ViewChild('power_profile') power_profile: ElementRef;
  @ViewChild('clear_button') clear_button: ElementRef;
  @ViewChild('post_message') post_message: ElementRef;

  faFile = faFile;
  description = GlobalConstants.assetDescription;
  question = GlobalConstants.assetQuestion;
  answer = GlobalConstants.assetAnswer;
  reset_state: boolean = false;
  show: string = "";
  user = GlobalConstants.registeredUser;
  project = GlobalConstants.projectId;
  warning_msg = '';
  setWarning = false;

  constructor(private fb: FormBuilder, private http: HttpClient, private authService: AuthService, private router: Router) { }

  generatorForm = this.fb.group({
    influence_per_kWh: ['', [Validators.required, Validators.min(0)]],
    asset_id: ['', [Validators.required]],
    min_capacity: ['', [Validators.required, Validators.min(0)]],
    max_capacity: ['', [Validators.required, Validators.min(0)]],
    opex: ['', [Validators.required, Validators.min(0)]],
    capex: ['', [Validators.required, Validators.min(0)]],
    lifetime: ['', [Validators.required, Validators.min(1)]]
  });

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    else{
      alert('You must select a project to continue.');
      this.router.navigateByUrl('/myprojects');
    }
    //Set validators for value check
    this.generatorForm.get("min_capacity").valueChanges
      .subscribe(data => {
        this.changeValidators();
      });
    this.generatorForm.get("max_capacity").valueChanges
      .subscribe(data => {
        this.changeValidators();
      });
  }

  changeValidators() {
    var min_capacity = this.generatorForm.get("min_capacity").value;
    var max_capacity = this.generatorForm.get("max_capacity").value;
    if (min_capacity !== null) {
      this.generatorForm.controls["max_capacity"].setValidators([Validators.required, Validators.min(min_capacity)]);
      // this.generatorForm.controls["max_capacity"].updateValueAndValidity;
    }
    if (max_capacity !== null) {
      this.generatorForm.controls["min_capacity"].setValidators([Validators.required, Validators.max(max_capacity), Validators.min(0)]);
      // this.generatorForm.controls["min_capacity"].updateValueAndValidity;

    }
  }

  isShown: boolean = false; // hidden by default
  toggleShow() {
    this.isShown = !this.isShown;
  }

  btnClickClear() {
    console.log("Btn Clear clicked");
    if (this.reset_state) {
      this.generatorForm.reset()
      this.clear_button.nativeElement.innerHTML = "Default"
      this.reset_state = !this.reset_state;
    } else {
      this.generatorForm.patchValue({
        influence_per_kWh: 0.40,
        asset_id: 'default_generator',
        min_capacity: 0,
        max_capacity: 100,
        opex: 10,
        capex: 800,
        lifetime: 25
      });
      this.clear_button.nativeElement.innerHTML = "Clear"
      this.reset_state = !this.reset_state;
    }

    this.generatorForm.enable();
  }

  btnClickAddAsset() {
    console.log("Btn Add Asset clicked");
    var host = "https://renergisetool.evergi.be/";

    const headers = new HttpHeaders()
      .append(
        'Content-Type',
        'application/x-www-form-urlencoded'
      );

    const params = new HttpParams(
      {
        fromObject: this.generatorForm.value
      }
    );

    var url = host + "users/" + this.user + "/projects/" + this.project + "/generators/"
    this.http.post<any>(url + '?' + params.toString(), { headers: headers }, { observe: 'response' }).subscribe(
      response => {
        console.log("response: " + response);
        if (response['status'] == 200) {
          this.show = "success";
          setTimeout(() => {
            this.show = " ";
          }, 2000);
        }
      },
      error => {
        console.log("error: " + error);
        this.show = "failed";
        setTimeout(() => {
          this.show = " ";
        }, 2000);
        if (error.error['warning'] != null) {
          this.warning_msg = error.error['warning'];
          this.setWarning = true;
        }
      }
    );
  }
}
