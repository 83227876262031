import { Component, OnInit } from '@angular/core';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { ViewChild, ElementRef } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
import { FormBuilder, Validators } from '@angular/forms';
import {saveAs} from 'file-saver';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { trigger, style, animate, transition } from '@angular/animations';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
const leaveTrans = transition(':enter', [
  style({
    opacity: 1
  }),
  animate('3s ease-out', style({
    opacity: 0,
  }))
])

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-new-asset4-charger',
  templateUrl: './new-asset4-charger.component.html',
  styleUrls: ['./new-asset4-charger.component.css'],
  animations: [fadeOut]
})
export class NewAsset4ChargerComponent implements OnInit {

  constructor(private fb: FormBuilder, private http: HttpClient, private authService: AuthService, private router: Router) { }
  faFile = faFile;
  show: string = "";

  @ViewChild('time_window') time_window: ElementRef;
  @ViewChild('max_power') max_power: ElementRef;
  @ViewChild('asset_id') asset_id: ElementRef;
  @ViewChild('powerprofile') powerprofile: ElementRef;
  @ViewChild('powerprofile_label') powerprofile_label: ElementRef;
  @ViewChild('clear_button') clear_button: ElementRef;

  description = GlobalConstants.assetDescription;
  question = GlobalConstants.assetQuestion;
  answer = GlobalConstants.assetAnswer;
  globalUser = GlobalConstants.registeredUser;
  user = GlobalConstants.registeredUser;
  project = GlobalConstants.projectId;
  file: File = null;
  reset_state: Boolean = false;
  warning_msg = '';
  setWarning = false;


  chargerForm = this.fb.group({
    time_window: ['', [Validators.required, Validators.min(0), Validators.pattern("^[0-9]*$")]],
    max_power: ['', [Validators.required, Validators.min(0)]],
    asset_id: ['', [Validators.required]],
    powerprofile: ['', [Validators.required]]
  });

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    else{
      alert('You must select a project to continue.');
      this.router.navigateByUrl('/myprojects');
    }
  }


  onChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      this.powerprofile_label.nativeElement.innerHTML = this.file.name;
    }
    else {
      this.file = null
      this.powerprofile_label.nativeElement.innerHTML = "Energy to be provided within the given time window [kWh]"
    }
  }

  isShown: boolean = false; // hidden by default
  toggleShow() {
    this.isShown = !this.isShown;
  }

  onDownloadCharger(){

    const params = new HttpParams(
      {
        fromObject: {
          user_id: GlobalConstants.registeredUser,
          project_id:GlobalConstants.projectId
        }
      }
    )

    var host = "https://renergisetool.evergi.be/";
    var url ="https://renergisetool.evergi.be/information/assets/chargers/powerprofile";
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type','text/csv')

    this.http.get(url +'?' + params.toString(), {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `chargers_powerprofile.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  onDownloadChargerExample(){
    var host = "https://renergisetool.evergi.be/";
    var url ="https://renergisetool.evergi.be/information/assets/chargers/powerprofile"
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type','text/csv')

    this.http.get(url, {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `chargers_powerprofile_example.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  btnClickClear() {
    if (this.reset_state) {
      this.chargerForm.reset()

      this.clear_button.nativeElement.innerHTML = "Default"
      this.reset_state = !this.reset_state;
    } else {
      this.chargerForm.patchValue({
        time_window: '24',
        max_power: '100.0',
        asset_id: 'default_charger',
      });
      this.clear_button.nativeElement.innerHTML = "Clear"
      this.reset_state = !this.reset_state;
    }
    this.chargerForm.enable()
    this.powerprofile_label.nativeElement.innerHTML = "Energy to be provided within the given time window [kWh]"
  }

  btnClickAddAsset() {
    var host = "https://renergisetool.evergi.be/";

    if (!this.file) {
      this.show = "failed";
      setTimeout(() => {
        this.show = " ";
      }, 2000);
      return
    }

    const headers = new HttpHeaders()
      .append(
        'Content-Type',
        'application/x-www-form-urlencoded'
      );

    const params = new HttpParams(
      {
        fromObject: {
          time_window: this.time_window.nativeElement.value,
          max_power: this.max_power.nativeElement.value,
          asset_id: this.asset_id.nativeElement.value
        }
      }
    )

    const formdata = new FormData();

    formdata.append("powerprofile", this.file, this.file.name)

    var url = host + "users/" + this.user + "/projects/" + this.project + "/chargers/"
    
    this.http.post<any>(url + '?' + params.toString(), formdata, { observe: 'response' }).subscribe(
      response => {
        console.log("response: " + response);
        if (response['status'] == 200) {
          this.show = "success";
          setTimeout(() => {
            this.show = " ";
          }, 2000);
        }
      },
      error => {
        console.log("error: " + error);
        this.show = "failed";
        setTimeout(() => {
          this.show = " ";
        }, 2000);
        if (error.error['warning'] != null) {
          this.warning_msg = error.error['warning'];
          this.setWarning = true;
        }
      }
    );
  }
}
