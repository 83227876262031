<div id="ps_tab5" class="container bg-light">
  <app-project-name-label></app-project-name-label>
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <div class="row pt-4">
        <div class="col-md-3 d-grid mx-auto">
          <a
            routerLink="/tabgeneral"
            routerLinkActive="active"
            title="General option"
            ><img
              title="General Information"
              class="img-fluid"
              src="assets/images/1x/btn_assetGeneral.png"
          /></a>
        </div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            routerLink="/tabnew"
            routerLinkActive="active"
            title="New Asset option"
            ><img
              title="New Asset"
              class="img-fluid"
              src="assets/images/1x/btn_assetNew.png"
          /></a>
        </div>
        <div class="col-sm-6 d-grid mx-auto"></div>
      </div>

      <div class="row">
        <div class="col-sm-1 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            routerLink="/tabmodify"
            routerLinkActive="active"
            title="Asset Modify option"
            ><img
              title="Asset Modify"
              class="img-fluid"
              src="assets/images/1x/btn_assetModifySelected.png"
          /></a>
        </div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            routerLink="/compare"
            routerLinkActive="active"
            title="Compare Projects"
            ><img
              title="Compare Projects"
              class="img-fluid"
              src="assets/images/1x/btn_assetCompare.png"
          /></a>
        </div>
        <div class="col-sm-5 d-grid mx-auto"></div>
      </div>

      <div class="row">
        <div class="col-sm-2 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            routerLink="/calculate"
            routerLinkActive="active"
            title="Calculate option"
            ><img
              title="Calculate"
              class="img-fluid"
              src="assets/images/1x/btn_calculate.png"
          /></a>
        </div>
        <div class="col-sm-2 d-grid mx-auto"></div>
        <div class="col-sm-2 d-grid mx-auto"></div>
      </div>
    </div>
    <div class="col-md-6 ps_form rounded ps_white">
      <form [formGroup]="buildingForm" (ngSubmit)="btnClickModifyAsset()">
        <div class="form-group">
          <div class="row pt-4">
            <app-modify-asset-input-data
              [activate_consumers]="'active'"
            ></app-modify-asset-input-data>
            <h1>
              <img
                class="img-fluid ps_imgsize"
                title="Import Data Files"
                src="assets/images/1x/newAssetImage.png"
              />
              Modify asset
            </h1>
            <!-- <h2>{{ description }}</h2> -->
            <div class="ps_logo_green">Why do I modify an Asset?</div>
            <div>
              If you want to apply minor changes into a specified energy system,
              you can chose the Asset that wish to change and re-calculate the
              outcome of the renewed configuration.
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-8 d-grid mx-auto"> -->
            <div class="ps_logo_green">{{ question }}</div>
            <div>{{ answer }}</div>
            <!-- </div> -->
          </div>

          <div class="row pt-2">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="asset_id"
                  name="asset_id"
                  id="asset_id"
                  #asset_id
                  type="text"
                  class="form-control ps_renergise_btn"
                  title="Asset name"
                  placeholder="Asset name"
                />
                <span
                  *ngIf="
                    !buildingForm.get('asset_id').valid &&
                    buildingForm.get('asset_id').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="buildingForm.get('asset_id').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="yearly_consumption"
                  name="yearly_consumption"
                  id="yearly_consumption"
                  #yearly_consumption
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="Yearly consumption [kWh]"
                  placeholder="Yearly consumption [kWh]"
                />
                <span
                  *ngIf="
                    !buildingForm.get('yearly_consumption').valid &&
                    buildingForm.get('yearly_consumption').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="buildingForm.get('yearly_consumption').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 0.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <select
                  formControlName="predefined_profile"
                  class="form-control ps_renergise_btn"
                  title="Select a predefined profile"
                  #predefined_profile
                >
                  <option value="Select a predefined profile" disabled hidden>
                    Select a predefined profile
                  </option>
                  <option value="Belgium_commercial">Belgium_commercial</option>
                  <option value="Belgium_residential">
                    Belgium_residential
                  </option>
                  <option value="Poland">Poland</option>
                </select>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <label
                  class="form-control ps_renergise_btn"
                  #power_profile_label
                  title="Power profile of the asset [kW]"
                  for="power_profile"
                  >Power profile of the asset [kW]</label
                >
                <input
                  formControlName="power_profile"
                  name="power_profile"
                  id="power_profile"
                  #power_profile
                  type="file"
                  class="ps_hidden form-control ps_renergise_btn"
                  title="Power profile of the asset [kW]"
                  placeholder="Power profile of the asset [kW]"
                  observeFiles
                  (change)="onChange($event)"
                />
              </form-field>
            </div>
            <div class="col-2 d-grid mx-auto">
              <div class="col">
                <fa-icon
                  mat-raised-button
                  [icon]="faFile"
                  size="2x"
                  style="cursor: pointer; color: '#FCC78C'"
                  (click)="onDownloadConsumer()"
                  title="Download empty template"
                ></fa-icon>
                <fa-icon
                  title="Download example csv template"
                  mat-raised-button
                  [icon]="faFile"
                  size="2x"
                  style="cursor: pointer; color: '#3babb0'; margin-left: 4px"
                  (click)="onDownloadConsumerExample()"
                ></fa-icon>
              </div>
            </div>
            <div class="col-2 d-grid mx-auto"></div>
          </div>
        </div>
      </form>

      <div class="row pt-1 pb-2">
        <div class="col-4 d-grid mx-auto">
          <!-- <button type="button" #clear_button class="btn ps_renergise_btn" title="Clear form" (click)="btnClickClear()">Clear</button> -->
          <button
            type="button"
            title="Back to modify asset"
            class="btn ps_renergise_btn"
            routerLink="/tabmodify"
            routerLinkActive="active"
          >
            Back
          </button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <button
            type="button"
            class="btn ps_renergise_btn"
            title="Modify asset"
            (click)="btnClickModifyAsset()"
            [disabled]="!buildingForm.valid"
          >
            Modify Asset
          </button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <div *ngIf="show === 'success'" @fadeOut>
            <img
              class="submit_img"
              title="Modified"
              src="assets/images/1x/baseline_task_alt_green_24dp.png"
              #submit_img
            />
          </div>
          <div *ngIf="show === 'failed'" @fadeOut>
            <img
              class="submit_img"
              title="Modified"
              src="assets/images/1x/baseline_highlight_off_red_24dp.png"
              #submit_img
            />
          </div>
        </div>
      </div>
      <div class="row pt-1 pb-2">
        <div class="col-8 d-grid mx-auto">
          <span class="alert alert-warning" role="alert" *ngIf="setWarning">{{
            warning_msg
          }}</span>
        </div>
        <div class="col-4 d-grid mx-auto"></div>
      </div>
    </div>
  </div>
</div>
