import { Component, OnInit } from '@angular/core';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { ViewChild, ElementRef } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
import { VubApiService } from '../common/vub-api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { trigger, style, animate, transition } from '@angular/animations';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';


const leaveTrans = transition(':enter', [
  style({
    opacity: 1
  }),
  animate('3s ease-out', style({
    opacity: 0,
  }))
])

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-new-asset2-storage',
  templateUrl: './new-asset2-storage.component.html',
  styleUrls: ['./new-asset2-storage.component.css'],
  animations: [fadeOut]
})
export class NewAsset2StorageComponent implements OnInit {

  @ViewChild('power_profile_label') power_profile_label: ElementRef;
  @ViewChild('asset_id') asset_id: ElementRef;
  @ViewChild('post_message') post_message: ElementRef;
  @ViewChild('clear_button') clear_button: ElementRef;

  faFile = faFile;
  show: string = '';
  description = GlobalConstants.assetDescription;
  question = GlobalConstants.assetQuestion;
  answer = GlobalConstants.assetAnswer;
  globalUser = GlobalConstants.registeredUser;
  user = GlobalConstants.registeredUser;
  project = GlobalConstants.projectId;
  file: File = null;
  reset_state: boolean = false;
  warning_msg = '';
  setWarning = false;

  constructor(private fb: FormBuilder, private vubSrv: VubApiService, private http: HttpClient, private authService: AuthService, private router: Router) { }

  storageForm = this.fb.group({
    soc_init: [{ value: null, disabled: false }, [Validators.required]],
    soc_min: [{ value: null, disabled: false }, [Validators.required]],
    soc_max: [{ value: null, disabled: false }, [Validators.required]],
    charge_eff: [{ value: null, disabled: false }, [Validators.required, Validators.min(0), Validators.max(1)]],
    disch_eff: [{ value: null, disabled: false }, [Validators.required, Validators.min(0), Validators.max(1)]],
    max_charge: [{ value: null, disabled: false }, [Validators.required, Validators.min(0), Validators.max(1)]],
    max_discharge: [{ value: null, disabled: false }, [Validators.required, Validators.min(0), Validators.max(1)]],
    min_capacity: [{ value: null, disabled: false }, [Validators.required, Validators.min(0)]],
    max_capacity: [{ value: null, disabled: false }, [Validators.required]],
    capex: [{ value: null, disabled: false }, [Validators.required, Validators.min(0)]],
    opex: [{ value: null, disabled: false }, [Validators.required, Validators.min(0)]],
    lifetime: [{ value: null, disabled: false }, [Validators.required, Validators.min(1)]],
    // power_profile: [{value : null, disabled : false}],  
    asset_id: [{ value: '', disabled: false }, [Validators.required]]
  });

  onChange(event) {
    if (event.target.files[0]) {
      this.file = event.target.files[0];
      this.power_profile_label.nativeElement.innerHTML = this.file.name
      for (var i in this.storageForm.controls) {
        if (i != 'power_profile') {
          this.storageForm.controls[i].reset();
          this.storageForm.controls[i].disable();
        }
      }
    }
    else {
      this.file = null
      this.power_profile_label.nativeElement.innerHTML = "Power profile of the asset [kW]"
      for (var i in this.storageForm.controls) {
        this.storageForm.controls[i].enable();
      }
    }
  }

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    else{
      alert('You must select a project to continue.');
      this.router.navigateByUrl('/myprojects');
    }
    
    //Set validators for value check
    this.storageForm.get("soc_min").valueChanges
      .subscribe(data => {
        this.changeValidators();
      });
    this.storageForm.get("soc_max").valueChanges
      .subscribe(data => {
        this.changeValidators();
      });
    this.storageForm.get("min_capacity").valueChanges
      .subscribe(data => {
        this.changeValidators();
      });
      this.storageForm.get("max_capacity").valueChanges
      .subscribe(data => {
        this.changeValidators();
      }); 
  }

  changeValidators() {
    var soc_min = this.storageForm.get("soc_min").value;
    var soc_max = this.storageForm.get("soc_max").value;
    var min_capacity = this.storageForm.get("min_capacity").value;
    var max_capacity = this.storageForm.get("max_capacity").value;
    if(this.storageForm.get("soc_min").valueChanges){
      this.storageForm.controls["soc_max"].setValidators([Validators.required,Validators.min(soc_min), Validators.max(1)]);
    }
    if(soc_max !== null){
      
      this.storageForm.controls["soc_min"].setValidators([Validators.required,Validators.min(0), Validators.max(soc_max)]);
    }
    if (soc_min !== null && soc_max !== null) {
      this.storageForm.controls["soc_init"].setValidators([Validators.required,Validators.min(soc_min), Validators.max(soc_max)]);
    }
    if (min_capacity !== null) {
      
      this.storageForm.controls["max_capacity"].setValidators([Validators.required,Validators.min(0),Validators.min(min_capacity)]);
    }
    if (max_capacity !== null) {
      
      this.storageForm.controls["min_capacity"].setValidators([Validators.required,Validators.max(max_capacity)]);
    }
  }

  isShown: boolean = false; // hidden by default
  toggleShow() {
    this.isShown = !this.isShown;
  }

  btnClickClear() {
    console.log("Btn Clear clicked");
    // this.storageForm.reset()
    if (this.reset_state) {
      this.storageForm.reset()

      this.clear_button.nativeElement.innerHTML = "Default"
      this.reset_state = !this.reset_state;
    } else {
      this.storageForm.patchValue({
        soc_init: 0.1,
        soc_min: 0.1,
        soc_max: 1,
        charge_eff: 0.95,
        disch_eff: 0.95,
        max_charge: 0.9,
        max_discharge: 0.9,
        min_capacity: 0.0,
        max_capacity: 100,
        capex: 800,
        opex: 6.75,
        lifetime: 10,
        asset_id: 'default_battery'
      });
      this.clear_button.nativeElement.innerHTML = "Clear"
      this.reset_state = !this.reset_state;
    }
    this.storageForm.enable()
    // this.power_profile_label.nativeElement.innerHTML = "Power profile of the asset [kW]"
  }

  btnClickAddAsset() {

    var host = "https://renergisetool.evergi.be/";
    const headers = new HttpHeaders()
      .append(
        'Content-Type',
        'application/x-www-form-urlencoded'
      );
      
    const params = new HttpParams(
      {
        fromObject: this.storageForm.value
      }
    )

    var url = host + "users/" + this.user + "/projects/" + this.project + "/batteries/"
    this.http.post<any>(url + '?' + params.toString(), { headers: headers }, { observe: 'response' }).subscribe(
      response => {
        console.log("response: " + response);
        if (response['status'] == 200) {
          this.show = "success";
          setTimeout(() => {
            this.show = " ";
          }, 2000);
        }
      },
      error => {
        console.log("error: " + error);
        this.show = "failed";
        setTimeout(() => {
          this.show = " ";
        }, 2000);
        if (error.error['warning'] != null) {
          this.warning_msg = error.error['warning'];
          this.setWarning = true;
        }
      }
    );

  }

}
