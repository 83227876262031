<mat-dialog-content>
  <div class="form-group">
    <h4 mat-dialog-title>Project to be duplicated: <span>{{data.currentProjectName}}</span></h4>
    <!-- <input class="form-control ps_renergise_btn" placeholder={{data.currentProjectName}} readonly> -->
    <h4 mat-dialog-title>Please insert a new name for the project.</h4>
    <input class="form-control ps_renergise_btn" [(ngModel)]="data.projectName" [formControl]="projectNameControl"
      placeholder="Project name" required>
    <span *ngIf="projectNameControl.invalid && !projectNameControl.touched" style="color:grey; font: small-caption;">You
      must insert a project name.</span>
    <span *ngIf="projectNameControl.invalid && projectNameControl.touched" style="color:red; font: small-caption;">You
      must insert a project name.</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn ps_renergise_btn ap_spacing ap_dialog_button" [disabled]="projectNameControl.invalid"
    [mat-dialog-close]="data.projectName" cdkFocusInitial>OK</button>
  <button class="btn ps_renergise_btn ap_spacing ap_dialog_button" (click)="onNoClick()">CANCEL</button>
</mat-dialog-actions>