<div id="ps_landingpage" class="container bg-light">
  <div class="row ps_padding_20">
    <!-- <div class="col-8" *ngIf="user">
      <div class="row">
        <div class="col-md-4 d-grid mx-auto">
          <div class="white_boxes">
            <h4>New Project</h4>
            <p>Lorem Ipsum</p>
            <button type="button" class="btn ps_renergise_reverse_btn" routerLink="/tabgeneral"
              title="Add a new project">GO TO PAGE</button>
          </div>
        </div>
        <div class="col-md-4 d-grid mx-auto">
          <div class="white_boxes">
            <h4>My Projects</h4>
            <p>Lorem Ipsum</p>
            <button type="button" class="btn ps_renergise_reverse_btn" routerLink="/myprojects" title="My projects">GO
              TO PAGE</button>
          </div>
        </div>
        <div class="col-md-4 d-grid mx-auto"></div>
      </div>
      <div class="row">
        <div class="col-md-4 d-grid mx-auto">
          <div class="white_box">
            <h4>Compare Projects</h4>
            <p>Lorem Ipsum</p>
            <button type="button" class="btn ps_renergise_reverse_btn" routerLink="/compare" title="Compare projects">GO
              TO PAGE</button>
          </div>
        </div>
        <div class="col-md-4 d-grid mx-auto"></div>
        <div class="col-md-4 d-grid mx-auto"></div>
      </div>
    </div>
    <div class="col-4" *ngIf="user"></div> -->
    <div class="col-md-6">
      <app-left-side-logo></app-left-side-logo>
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-4">
      <h2>Let's get started!</h2>
      <h3>Create an account or login.</h3>
      <!--social media row-->
      <!--faceboot twitter in-->
      <app-socialmedia></app-socialmedia>

      <div class="row pt-4">
        <div class="col d-grid mx-auto">
          <button type="button" class="btn ps_renergise_btn" (click)="btnClickSignUp()">SIGN UP</button>
        </div>
      </div>
      <div class="row pt-1">
        <div class="col d-grid mx-auto">
          <button type="button" class="btn ps_renergise_btn" (click)="btnClickLogIn()">LOG IN</button>
        </div>
      </div>

      <div class="row pt-1">
        <div class="col d-grid mx-auto">
          <hr class="ps_horisontal_line">
        </div>
      </div>
      <div class="row pt-1">
        <div class="col d-grid mx-auto">
          <button type="button" class="btn ps_renergise_reverse_btn" (click)="btnDemo()">TRY THE TUTORIAL</button>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
