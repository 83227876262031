<div id="ps_profilepage" class="container bg-light">
  <div class="row ps_padding_20">
    <div class="col-6 d-grid mx-auto">
      
      <div class="row pt-4">
          <div class="col-8 d-grid mx-auto">
              <h2>Please complete your profile.</h2>
              <h3>User </h3>
              <div class="pt-1"><input name="profileName" id="profileName" type="text" class="form-control ps_renergise_btn" placeholder="Name"></div>
              <div class="pt-1"><input name="profileSurname" id="profileSurname" type="text" class="form-control ps_renergise_btn" placeholder="Surname"></div>
              <div class="pt-1"><input name="profileInfo1" id="profileInfo1" type="text" class="form-control ps_renergise_btn" placeholder="Info1"></div>
              <div class="pt-1"><input name="profileInfo2" id="profileInfo2" type="text" class="form-control ps_renergise_btn" placeholder="Info2"></div>
              <div class="pt-1"><input name="profileInfo3" id="profileInfo3" type="text" class="form-control ps_renergise_btn" placeholder="Info3"></div>
          </div>
          <div class="col-4 d-grid mx-auto"></div>
      </div>
      <div class="row pt-1">
        <div class="col-4 d-grid mx-auto">
          <button type="button" class="btn ps_renergise_btn" (click)="btnClickBack()">Back</button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <button type="button" class="btn ps_renergise_btn" (click)="btnClickSignupSave()">Save</button>
        </div>
        <div class="col-4 d-grid mx-auto"></div>
      </div>      
    </div>
    <div class="col-6 d-grid mx-auto">
      <img class="img-fluid ps_myprofile" src="assets/images/1x/myprofile.png">
    </div>
</div>
</div>