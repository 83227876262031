import { Component, OnInit } from '@angular/core';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { ViewChild, ElementRef } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import { trigger, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import * as saveAs from 'file-saver';
const leaveTrans = transition(':enter', [
  style({
    opacity: 1
  }),
  animate('3s ease-out', style({
    opacity: 0,
  }))
])

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-modify-asset-charger',
  templateUrl: './modify-asset-charger.component.html',
  styleUrls: ['./modify-asset-charger.component.css'],
  animations: [fadeOut]
})
export class ModifyAssetChargerComponent implements OnInit {

  
  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router, private authService: AuthService) { }
  faFile = faFile;

  @ViewChild('time_window') time_window: ElementRef;
  @ViewChild('max_power') max_power: ElementRef;
  @ViewChild('asset_id') asset_id: ElementRef;
  @ViewChild('powerprofile') powerprofile: ElementRef;
  @ViewChild('powerprofile_label') powerprofile_label: ElementRef;
  @ViewChild('clear_button') clear_button: ElementRef;
  
  description = GlobalConstants.assetDescription;
  question = GlobalConstants.assetQuestion;
  answer = GlobalConstants.assetAnswer;
  globalUser = GlobalConstants.registeredUser;
  user = GlobalConstants.registeredUser;
  project = GlobalConstants.projectId;
  file: File = null;
  reset_state : Boolean = false;
  show: string = "";
  setWarning: Boolean = false;
  warning_msg: string = "";


  chargerForm = this.fb.group({
    time_window: ['', [Validators.required, Validators.min(0), Validators.pattern("^[0-9]*$")]],
    max_power: ['', [Validators.required, Validators.min(0)]],
    asset_id: ['', [Validators.required]],
    powerprofile: ['',[Validators.required]]
  });
  assetId: string;
  assetToModify = GlobalConstants.assetToModify;

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    var numberPattern = /\d+/g;
    if(this.assetToModify){
      this.assetId = this.assetToModify.match(numberPattern)[0];
    }else{
      this.router.navigateByUrl('/tabmodify');
    }
    if(this.assetId){
      var host = "https://renergisetool.evergi.be/";
      var url = host + "users/" + this.user + "/projects/" + this.project + "/chargers/" + this.assetId;
      console.log("url " + url);
      this.http.get<any>(url).subscribe(
        response => {
          console.log("response: " + response);
          this.chargerForm.patchValue({
            time_window: response[this.assetToModify]["time_window"],
            max_power: response[this.assetToModify]["max_power"],
            asset_id: response[this.assetToModify]["asset_id"]
          });
        }
      );
    }
  }


  onChange(event) {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      this.powerprofile_label.nativeElement.innerHTML = this.file.name
    }
    else {
      this.file = null
      this.powerprofile_label.nativeElement.innerHTML = "Power profile of the asset [kW]"
      this.chargerForm.controls["powerprofile"].setValidators([Validators.required]);
      this.chargerForm.get('powerprofile').updateValueAndValidity();
    }
  }

  onDownloadCharger(){

    const params = new HttpParams(
      {
        fromObject: {
          user_id: GlobalConstants.registeredUser,
          project_id:GlobalConstants.projectId
        }
      }
    )


    var url ="https://renergisetool.evergi.be/information/assets/chargers/powerprofile";

    this.http.get(url +'?' + params.toString(), {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `chargers_powerprofile.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  onDownloadChargerExample(){
    var host = "https://renergisetool.evergi.be/";
    var url ="https://renergisetool.evergi.be/information/assets/chargers/powerprofile"

    this.http.get(url, {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `chargers_powerprofile_example.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }
  
  isShown: boolean = false ; // hidden by default
  toggleShow() {
    this.isShown = ! this.isShown;    
  }

  btnClickClear(){
    console.log("Btn Clear clicked");
    this.chargerForm.reset();
    this.chargerForm.enable();
  }

  btnClickModifyAsset(){
    console.log("Btn Modify clicked");
    var host = "https://renergisetool.evergi.be/";

    const headers = new HttpHeaders()
    .append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    const params = new HttpParams(
      {
        fromObject : {
          time_window : this.time_window.nativeElement.value,
          max_power : this.max_power.nativeElement.value,
          asset_id : this.asset_id.nativeElement.value
        }
      }
    )

    const formdata = new FormData();

    formdata.append("powerprofile",this.file,this.file.name)

    var url = host + "users/" + this.user + "/projects/" + this.project + "/chargers/" + this.assetId
    console.log(url + '?' + params.toString())

    this.http.put<any>(url + '?' + params.toString(), formdata, {observe : 'response'}).subscribe(
      response => {
        console.log("response: " + response);
        if (response['status'] == 200){
          this.show="success";
          setTimeout(() => {
            this.show=" ";
            this.router.navigateByUrl('/tabmodify');
          }, 2000);
        }
      },
      error => {
        console.log("error: " + error);
       this.show = "failed";
       setTimeout(() => {
         this.show = " ";
       }, 2000);
       if (error.error['warning'] != null) {
         this.warning_msg = error.error['warning'];
         this.setWarning = true;
       }
      }
    );
  }
}
