import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalConstants } from '../common/global-constants';

import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../auth.service';
import { HttpClient } from '@angular/common/http';

import { AuthResponseData } from '../auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  response: Array<any> = [];

  constructor(private router: Router, private fb: FormBuilder, public authService: AuthService, private http: HttpClient) { }

  ngOnInit(): void { }

  faKey = faKey;
  faEnvelope = faEnvelope;
  errorMessage = '';
  setErrorMessage: boolean = false;
  public showPassword: boolean = false;

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  btnClickBack() {
    console.log("Btn Back clicked");
    this.router.navigateByUrl('/');
  }

  btnClickLogIn() {
    console.log("Btn LogIn clicked");
    this.authService.LogIn(this.loginForm.get("email").value,this.loginForm.get("password").value);
  }
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
    console.log("Show password: ", this.showPassword)
  }

}
