import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { delayWhen, map, retryWhen, take, takeWhile, tap } from 'rxjs/operators';
import { timer } from 'rxjs';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-calculate',
  templateUrl: './calculate.component.html',
  styleUrls: ['./calculate.component.css']
})
export class CalculateComponent implements OnInit {
  user = GlobalConstants.registeredUser;
  project = GlobalConstants.projectId;
  isCompleted: boolean = false;
  info = '';

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }

    var host = "https://renergisetool.evergi.be/";
    var reqUrl = host + "users/" + this.user + "/projects/" + this.project + "/result/calculate";
    console.log("url " + reqUrl);
    this.http.post<any>(reqUrl, {observe: 'response'}).pipe(
      map((response) =>{
          return response;
      }),
      retryWhen((errors) =>
        { 
          return errors.pipe(
            takeWhile((error)=>{
              if(error['status'] === 400 || error['status'] === 404 || error['status'] === 500){
                console.log("Throw error " + error);
                throw error;
              }
              else{
                console.log("Retry " + error);
                this.info = error.error['result_state']
                return true
              }
            }),
          // restart in 1 second
          delayWhen(() => timer(1 * 1000))
        )}
      ))
      .subscribe(
        response => {
          console.log("Success calculation. " + "Response: " + response);
          if (response['result_state'] === 'FINISHED') {
            this.isCompleted = true;
          }
        },
        error => {
          console.log("error: " + error);
          if (error["status"] === 500) {
            alert("Something went wrong. Server error 500.");
            this.router.navigateByUrl('/tabnew');
          }
          if (error["status"] === 400) {
            if (error.error['warning'] != null && error.error['warning'] !== 'The environment is still calculating, please wait until that is done') {
              alert("Check your assets. " + error.error['warning']);
              this.router.navigateByUrl('/tabnew');
            }
            else{
              alert("Please wait. " + error.error['warning']);
              this.router.navigateByUrl('/myprojects');
            }
          }
          if(error['status']===404 && this.project === null || this.project === '') {
            alert('You must select a project to continue.');
            this.router.navigateByUrl('/myprojects');
          }
        }

      )
  }
}
