import { Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { faSave, faFile } from '@fortawesome/free-solid-svg-icons';
import { trigger, style, animate, transition } from '@angular/animations';

import { GlobalConstants } from '../common/global-constants';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import * as saveAs from 'file-saver';

const leaveTrans = transition(':enter', [
  style({
    opacity: 1
  }),
  animate('3s ease-out', style({
    opacity: 0,
  }))
])

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-modify-asset-public-grid',
  templateUrl: './modify-asset-public-grid.component.html',
  styleUrls: ['./modify-asset-public-grid.component.css'],
  animations: [

    fadeOut,
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('2s ease-out',
              style({ height: 80, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 80, opacity: 1 }),
            animate('2s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ModifyAssetPublicGridComponent implements OnInit {

  faSave = faSave;
  faFile = faFile;
  isShown: boolean = false; // hidden by default
  project = GlobalConstants.projectId;
  user = GlobalConstants.registeredUser;

  @ViewChild('asset_id') asset_id: ElementRef;
  @ViewChild('peak_influence') peak_influence: ElementRef;
  @ViewChild('sell_to_grid_const') sell_to_grid_const: ElementRef;
  @ViewChild('buy_from_grid_const') buy_from_grid_const: ElementRef;
  @ViewChild('sell_to_grid') sell_to_grid: ElementRef;
  @ViewChild('sell_to_grid_label') sell_to_grid_label: ElementRef;
  @ViewChild('buy_from_grid') buy_from_grid: ElementRef;
  @ViewChild('buy_from_grid_label') buy_from_grid_label: ElementRef;
  @ViewChild('clear_button') clear_button: ElementRef;

  description = GlobalConstants.assetDescription;
  question = GlobalConstants.assetQuestion;
  answer = GlobalConstants.assetAnswer;
  file1: File = null;
  file2: File = null;
  show: string = "";
  assetId: string;
  assetToModify = GlobalConstants.assetToModify;
  warning_msg = '';
  setWarning: boolean = false;

  publicForm = this.fb.group({
    asset_id: ['', Validators.required],
    peak_influence: ['', [Validators.min(0), Validators.required]],
    sell_to_grid_const: [''],
    buy_from_grid_const: [''],
    sell_to_grid: [''],
    buy_from_grid: ['']
  });

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    
    var numberPattern = /\d+/g;

    if(this.assetToModify){
      this.assetId = this.assetToModify.match(numberPattern)[0];
    }else{
      this.router.navigateByUrl('/tabmodify');
    }

    if (this.assetId) {
      var host = "https://renergisetool.evergi.be/";
      var url = host + "users/" + this.user + "/projects/" + this.project + "/publicgrids/" + this.assetId;
      console.log("url", url);
      this.http.get<any>(url).subscribe(
        response => {
          console.log("response: " + response);
          this.publicForm.patchValue({
            asset_id: response[this.assetToModify]["asset_id"],
            peak_influence: response[this.assetToModify]["peak_influence"],
            sell_to_grid_const: response[this.assetToModify]["sell_to_grid"],
            buy_from_grid_const:response[this.assetToModify]["buy_from_grid"]
          });
        }
      );
    }
  }

  onChange1(event) {
    if (event.target.files[0]) {
      this.file1 = event.target.files[0];
      this.sell_to_grid_label.nativeElement.innerHTML = this.file1.name
      this.publicForm.controls["sell_to_grid_const"].setValidators([]);
      this.publicForm.get('sell_to_grid_const').updateValueAndValidity();
      for (var i in this.publicForm.controls) {
        if (i === 'sell_to_grid_const' || i === 'buy_from_grid_const') {
          this.publicForm.controls[i].reset();
          this.publicForm.controls[i].disable();
        }
      }
    }
    else {
      this.file1 = null
      this.sell_to_grid_label.nativeElement.innerHTML = "Price you sell electricity to the public grid [€/KWh]"
      this.publicForm.controls["sell_to_grid_const"].setValidators([Validators.required]);
      this.publicForm.get('sell_to_grid_const').updateValueAndValidity();
      this.buy_from_grid_label.nativeElement.innerHTML = "Price you buy electricity from the grid [€/KWh]"
      this.publicForm.controls["buy_from_grid_const"].setValidators([Validators.required]);
      this.publicForm.get('buy_from_grid_const').updateValueAndValidity()
    }
  }

  onChange2(event) {
    if (event.target.files[0]) {
      this.file2 = event.target.files[0];
      this.buy_from_grid_label.nativeElement.innerHTML = this.file2.name
      this.publicForm.controls["buy_from_grid_const"].setValidators([]);
      this.publicForm.get('buy_from_grid_const').updateValueAndValidity();
      for (var i in this.publicForm.controls) {
        if (i === 'sell_to_grid_const' || i === 'buy_from_grid_const') {
          this.publicForm.controls[i].reset();
          this.publicForm.controls[i].disable();
        }
      }
    }
    else {
      this.file2 = null
      this.sell_to_grid_label.nativeElement.innerHTML = "Price you sell electricity to the public grid [€/KWh]"
      this.publicForm.controls["sell_to_grid_const"].setValidators([Validators.required]);
      this.publicForm.get('sell_to_grid_const').updateValueAndValidity();
      this.buy_from_grid_label.nativeElement.innerHTML = "Price you buy electricity from the grid [€/KWh]"
      this.publicForm.controls["buy_from_grid_const"].setValidators([Validators.required]);
      this.publicForm.get('buy_from_grid_const').updateValueAndValidity();
    }
  }

  onDownloadSell(){
    var host = "https://renergisetool.evergi.be/";
    var url ="https://renergisetool.evergi.be/information/environment/sell_to_grid?user_id="+this.user+"&project_id="+this.project;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type','text/csv')

    this.http.get(url, {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `sell_to_grid.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  onDownloadSellExample(){
    var host = "https://renergisetool.evergi.be/";
    var url ="https://renergisetool.evergi.be/information/environment/sell_to_grid"
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type','text/csv')

    this.http.get(url, {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `sell_to_grid_example.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  onDownloadBuy(){
    var host = "https://renergisetool.evergi.be/";
    var url ="https://renergisetool.evergi.be/information/environment/buy_from_grid?user_id="+this.user+"&project_id="+this.project;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type','text/csv')

    this.http.get(url, {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `buy_from_grid.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  onDownloadBuyExample(){
    var host = "https://renergisetool.evergi.be/";
    var url ="https://renergisetool.evergi.be/information/environment/buy_from_grid"
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type','text/csv')

    this.http.get(url, {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `buy_from_grid_example.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  toggleShow() {
    this.isShown = !this.isShown;
  }

  btnClickClear() {
    console.log("Btn Clear clicked");
    this.publicForm.reset()
    this.publicForm.enable()
  }

  btnClickModifyAsset() {
    console.log("Btn Add Modify clicked");

    const headers = new HttpHeaders()
      .append(
        'Content-Type',
        'application/x-www-form-urlencoded'
      );

    var host = "https://renergisetool.evergi.be/";
    var url = host + "users/" + this.user + "/projects/" + this.project + "/publicgrids/" + this.assetId

    if (this.file1 && this.file2) {
      console.log("url " + url)

      const formdata = new FormData();

      formdata.append("buy_from_grid", this.file2, this.file2.name)
      formdata.append("sell_to_grid", this.file1, this.file1.name)
      
      const params = new HttpParams(
        {
          fromObject: {
            peak_influence: this.peak_influence.nativeElement.value,
            asset_id: this.asset_id.nativeElement.value
          }
        }
      )

      this.http.put(url+ '?' + params.toString(), formdata, { observe: 'response' }).subscribe(
        response => {
          if (response['status'] == 200) {
            console.log("response: " + response);
            this.show = 'success';
            setTimeout(() => {
              this.show = '';
              this.router.navigateByUrl('/tabmodify');
            }, 2000);
          }
        },
        error => {
          console.log("error: " + error);
          this.show = 'failed';
          setTimeout(() => {
            this.show = '';
          }, 2000);
        }
      )
    }
    else {
      const params = new HttpParams(
        {
          fromObject: {
            peak_influence: this.peak_influence.nativeElement.value,
            buy_from_grid: this.buy_from_grid_const.nativeElement.value,
            sell_to_grid: this.sell_to_grid_const.nativeElement.value,
            asset_id: this.asset_id.nativeElement.value
          }
        }
      )
      this.http.put<any>(url + '?' + params.toString(), { headers: headers }, { observe: 'response' }).subscribe(
        response => {
          console.log("response: " + response);
          if (response['status'] == 200) {
            this.show = 'success';
            setTimeout(() => {
              this.show = '';
              this.router.navigateByUrl('/tabmodify');
            }, 2000);
          }
        },
        error => {
          console.log("error: " + error);
          this.show = 'failed';
          setTimeout(() => {
            this.show = '';
          }, 2000);
          if (error.error['warning'] != null) {
            this.warning_msg = error.error['warning'];
            this.setWarning = true;
          }
        }
      );
    }
  }
}
