<div id="ps_faq" class="container bg-light">
    <div class="row ps_padding_20">
      <div class="col-md-6">
        <app-left-side-logo></app-left-side-logo>
      </div>
      <div class="col-md-6">
        <h2>Frequently Asked Questions</h2>
        <ol>
          <li>
            <strong>Why should I sign up instead of using the tool as a guest?</strong>
            <div>RENERGiSE tool is clean, intuitive and will not save personal information for other uses. By logging in, the possibilities provided are launched to a new level of experience, as you are able to save your configurations, share and compare alternative scenarios among contacts.</div>
          </li>
          <li>
            <strong>I have a RENAISSANCE account, can I use it to log in the RENERGiSE tool?</strong>
            <div>Of course. RENERGiSE tool and RENAISSANCE project digital platform use the same credentials, so u only need to sign up once.</div>
          </li>
          <li>
            <strong>Do I need to pay for RENERGiSE?</strong>
            <div>RENERGiSE is a free tool that was developed in the context of RENAISSANCE project, funded by the European Commission. Please keep in mind that RENERGiSE will not ask you for money or for any other banking information.</div>
          </li>
          <li>
            <strong>How do I find the information required about the individual assets?</strong>
            <div>If you are not aware of the products available on the market and their technical specifications, or if you are not sure about these, we encourage you to contact a local supplier who can provide you with all the information about the systems you can install on your site.</div>
          </li>
        </ol>
        Still have questions? We will be happy to contact us and solve them for you, by using the provided contact form.
      </div>
  </div>
</div>