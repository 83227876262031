import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';

@Component({
  selector: 'app-project-name-label',
  templateUrl: './project-name-label.component.html',
  styleUrls: ['./project-name-label.component.css']
})
export class ProjectNameLabelComponent implements OnInit {
  project = GlobalConstants.projectId;
  show = false;
  
  constructor() { }

  ngOnInit(): void {
    GlobalConstants.projectId=localStorage.getItem("SELECTED_PROJECT");
    this.project = GlobalConstants.projectId;

    if(this.project != ""){
      this.show = true;
    }
    
  }

}
