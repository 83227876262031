import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-main-menu',
  templateUrl: './user-main-menu.component.html',
  styleUrls: ['./user-main-menu.component.css']
})
export class UserMainMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
