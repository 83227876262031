<div id="#ap_forgotpwpage{
    " class="container bg-light">
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <app-left-side-logo></app-left-side-logo>
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-4">
      <div class="row pt-1">
        <h3>Reset Password</h3>
        <p>Please enter your email address to request a password reset.</p>
        <form>
            <div class="form-group">
                <div class="row pt-1">
                  <div class="col-8 d-grid mx-auto">
                    <input class="form-control ps_renergise_btn" type="email" placeholder="Email" title="Email" #passwordResetEmail required>
                  </div>
                  <div class="col-4 d-grid mx-auto"> <input title= "Reset password" type="submit" class="btn ps_renergise_btn" value="Reset Password"
                    (click)="authService.ForgotPassword(passwordResetEmail.value)"></div>
                </div>
              </div>
        </form>
        <div class="row pt-1">
            <p>Go back to?<a  routerLink="/login" title="Login page"> Login</a></p>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
