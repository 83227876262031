import { Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
import { FormBuilder, Validators } from '@angular/forms';

import { faMapMarkerAlt, faCalendarWeek, faClock, faFile } from '@fortawesome/free-solid-svg-icons';
import { CoordinatesInteractionService } from '../coordinates-interaction.service';

import { trigger, style, animate, transition } from '@angular/animations';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import * as saveAs from 'file-saver';


const leaveTrans = transition(':enter', [
  style({
    opacity: 1
  }),
  animate('3s ease-out', style({
    opacity: 0,
  }))
])

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-modify-asset-solar',
  templateUrl: './modify-asset-solar.component.html',
  styleUrls: ['./modify-asset-solar.component.css'],
  animations: [
    fadeOut,
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ height: 80, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 80, opacity: 1 }),
            animate('1s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ModifyAssetSolarComponent implements OnInit {

  constructor(
    private _coordinatesInteraction: CoordinatesInteractionService,
    private fb: FormBuilder,
    private http: HttpClient, private router: Router,
    private authService: AuthService
  ) { }

  faMapMarkerAlt = faMapMarkerAlt;
  faCalendarWeek = faCalendarWeek;
  faClock = faClock;
  faFile = faFile;
  reset_state: boolean = false;
  show: string = "";
  setWarning: boolean = false;
  warning_msg: string = "";


  @ViewChild('power_profile_label') power_profile_label: ElementRef;
  @ViewChild('post_message') post_message: ElementRef;
  @ViewChild('clear_button') clear_button: ElementRef;
  @ViewChild('asset_id') asset_id: ElementRef;


  solarForm = this.fb.group({
    tilt: ['', [Validators.min(0), Validators.max(90)]],
    azimuth: ['', [Validators.min(0), Validators.max(90)]],
    tracking: ['Is the solar pv a single axis tracking system?'],
    module: ['Select a module'],
    temp_parameters: ['Select a temperature parameters'],
    min_capacity: [{ value: null, disabled: false }, [Validators.required, Validators.min(0)]],
    max_capacity: [{ value: null, disabled: false }, [Validators.required]],
    capex: [{ value: null, disabled: false }, [Validators.required, Validators.min(0)]],
    opex: [{ value: null, disabled: false }, [Validators.required, Validators.min(0)]],
    lifetime: [{ value: null, disabled: false }, [Validators.required, Validators.min(1)]],
    asset_id: ['', [Validators.required]],
    power_profile: ['']
  });

  onChange(event) {
    if (event.target.files[0]) {
      this.file = event.target.files[0];
      this.power_profile_label.nativeElement.innerHTML = this.file.name
      for (var i in this.solarForm.controls) {
        if (i != 'power_profile' && i != 'asset_id' && i != 'min_capacity' && i != 'max_capacity' && i != 'capex' && i != 'opex' && i != 'lifetime') {
          this.solarForm.controls[i].reset();
          this.solarForm.controls[i].disable();
        }
      }
    }
    else {
      this.file = null
      this.power_profile_label.nativeElement.innerHTML = "Power profile of the asset [kW]"
      for (var i in this.solarForm.controls) {
        this.solarForm.controls[i].enable();
      }
    }
  }

  description = GlobalConstants.assetDescription;
  question = GlobalConstants.assetQuestion;
  answer = GlobalConstants.assetAnswer;
  globalUser = GlobalConstants.registeredUser;
  user = GlobalConstants.registeredUser;
  project = GlobalConstants.projectId;
  file: File = null;
  assetId: string;
  assetToModify = GlobalConstants.assetToModify;

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    //Set validators for value check
    this.solarForm.get("min_capacity").valueChanges
      .subscribe(data => {
        this.changeValidators();
      });
    this.solarForm.get("max_capacity").valueChanges
      .subscribe(data => {
        this.changeValidators();
      });

    var numberPattern = /\d+/g;
    if (this.assetToModify) {
      this.assetId = this.assetToModify.match(numberPattern)[0];
    } else {
      this.router.navigateByUrl('/tabmodify');
    }
    if (this.assetId) {
      var host = "https://renergisetool.evergi.be/";
      var url = host + "users/" + this.user + "/projects/" + this.project + "/solarpvs/" + this.assetId;
      console.log("url " + url);
      this.http.get<any>(url).subscribe(
        response => {
          console.log("response: " + response);
          this.solarForm.patchValue({
            tilt: response[this.assetToModify]["tilt"],
            azimuth: response[this.assetToModify]["azimuth"],
            tracking: response[this.assetToModify]["tracking"],
            module: response[this.assetToModify]["module"],
            temp_parameters: response[this.assetToModify]["temp_parameters"],
            min_capacity: response[this.assetToModify]["min_capacity"],
            max_capacity: response[this.assetToModify]["max_capacity"],
            capex: response[this.assetToModify]["capex"],
            opex: response[this.assetToModify]["opex"],
            lifetime: response[this.assetToModify]["lifetime"],
            asset_id: response[this.assetToModify]["asset_id"]
          });
        }
      );
    }
  }

  changeValidators() {

    var min_capacity = this.solarForm.get("min_capacity").value;
    var max_capacity = this.solarForm.get("max_capacity").value;

    if (min_capacity !== null) {
      this.solarForm.controls["max_capacity"].setValidators([Validators.required, Validators.min(min_capacity)]);
      // this.solarForm.controls["max_capacity"].updateValueAndValidity();
    }
    if (max_capacity !== null) {
      this.solarForm.controls["min_capacity"].setValidators([Validators.required, Validators.min(0), Validators.max(max_capacity)]);
      // this.solarForm.controls["min_capacity"].updateValueAndValidity();
    }
  }

  onDownloadSolar() {

    const params = new HttpParams(
      {
        fromObject: {
          user_id: GlobalConstants.registeredUser,
          project_id: GlobalConstants.projectId
        }
      }
    )

    var host = "https://renergisetool.evergi.be/";
    var url = "https://renergisetool.evergi.be/information/assets/solarpvs/powerprofile"

    this.http.get(url + '?' + params.toString(), { responseType: 'blob' }).subscribe(
      (response: any) => {
        saveAs(response, `solarpvs_powerprofile.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  onDownloadSolarExample() {
    var host = "https://renergisetool.evergi.be/";
    var url = "https://renergisetool.evergi.be/information/assets/solarpvs/powerprofile"

    this.http.get(url, { responseType: 'blob' }).subscribe(
      (response: any) => {
        saveAs(response, `solarpvs_powerprofile_example.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  isShown: boolean = false; // hidden by default
  toggleShow() {
    this.isShown = !this.isShown;
  }

  btnClickClear() {
    console.log("Btn Clear clicked");
    this.solarForm.reset();
    this.solarForm.enable();
    this.power_profile_label.nativeElement.innerHTML = "Power profile of the asset [kW]"

  }

  btnClickModifyAsset() {
    console.log("Btn Modify clicked");
    var host = "https://renergisetool.evergi.be/";
    var user = GlobalConstants.registeredUser;
    var project = GlobalConstants.projectId

    const headers = new HttpHeaders()
      .append(
        'Content-Type',
        'application/x-www-form-urlencoded'
      );
    const params = new HttpParams(
      {
        fromObject: this.solarForm.value
      }
    )
    var url = host + "users/" + user + "/projects/" + project + "/solarpvs/" + this.assetId
    if (this.file) {
      const formdata = new FormData();

      formdata.append("powerprofile", this.file, this.file.name)


      this.http.put(url + '?' + params.toString(), formdata, { observe: 'response' }).subscribe(
        response => {
          console.log("response: " + response);
          if (response['status'] == 200) {
            this.show = "success";
            setTimeout(() => {
              this.show = " ";
              this.router.navigateByUrl('/tabmodify');
            }, 2000);
          }
        },
        error => {
          console.log("error: " + error);
          this.show = "failed";
          setTimeout(() => {
            this.show = " ";
          }, 2000);
          if (error.error['warning'] != null) {
            this.warning_msg = error.error['warning'];
            this.setWarning = true;
          }
        }
      )
    } else {
      this.http.put<any>(url + '?' + params.toString(), { headers: headers }, { observe: 'response' }).subscribe(
        response => {
          console.log("response: " + response);
          if (response['status'] == 200) {
            this.show = "success";
            setTimeout(() => {
              this.show = " ";
              this.router.navigateByUrl('/tabmodify');
            }, 2000);

          }
        },
        error => {
          console.log("error: " + error);
          this.show = "failed";
          setTimeout(() => {
            this.show = " ";
          }, 2000);
          if (error.error['warning'] != null) {
            this.warning_msg = error.error['warning'];
            this.setWarning = true;
          }
        }
      )
    }
  }

}
