<div id="ps_ppolicypage" class="container bg-light">
    <div class="row ps_padding_20">
      <div class="col">
        <h3>LEGAL NOTICE</h3>
        <p> </p>
        <p>The content of this website is produced by the RENAISSANCE consortium in the frame of the European Union&rsquo;s Horizon 2020 research and innovation programme, under grant agreement No 824342. The website is maintained to enhance public access to information about the project activities and to disseminate project results.</p>
        <p>All the material in the website belongs to the RENAISSANCE consortium and reflects only the authors&rsquo; view. The European Union is not liable for any use that may be made of the information contained therein.</p>
        <p>The content on this website is subject to copyright.</p>
        <h3>PRIVACY AND COOKIE POLICY</h3>
        <p> </p>
        <h4>We care for you privacy…read how we handle it!</h4>
        <p> </p>
        <p>Data protection is a particularly high priority for the management of a European project. The webpages of RENAISSANCE project can be used without having to specify any personal data. However, should a data subject wish to use special services through our website, the processing of personal data may be required. The processing of personal data will always be in accordance with the General Data Protection Regulation (GDPR).</p>
        <p>The purpose of this data protection statement is to inform you about our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.</p>
        <ol>
          <li>
            <h3>Information collection and use</h3>
            <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
            <h6><strong>Personal Data</strong></h6>
            <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (&ldquo;Personal Data&rdquo;). Personally identifiable information may include, but is not limited to:</p>
            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Your organisation and role</li>
              <li>Cookies and Usage Data</li>
            </ul>
            <h6><strong>Usage Data</strong></h6>
            <p>We may also collect information on how the Service is accessed and used (&ldquo;Usage Data&rdquo;). This Usage Data may include information such as your computer&rsquo;s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
          </li>
          <li>
            <h3>Definitions</h3>
            <h6>Name and address of the controller</h6>
            <p>For the purposes of the General Data Protection Regulation (GDPR), other data protection laws applicable in EU Member states and other provisions related to data protection, the controller is:</p>
            <p><strong>VUB – Vrij University Brussels</strong></p>
            <p>You can contact our data processing officer at <a href="mailto:privacy@renaissance-h2020.eu">privacy@renaissance-h2020.eu</a></p>
            <p>Processing associated to the web services pursuant to this website is performed at the seat of:</p>
            <p><strong>Deep Blue S.r.l., via Ennio Quirino Visconti 8 – 00193 Roma</strong></p>
            <p>The website hosting service is provided by RENAISSANCE project, at the servers kept within the farm of Aruba S.p.A., which guarantees the Data Controller, inasmuch as of its competence, the observance of the normative in force on the matter of processing personal data.</p>
          </li>
          <li>
            <h3>Tracking &amp; cookies data</h3>
            <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
            <p>Cookies are small text files that are used to store small pieces of information. The cookies are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make the website more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.</p>
            <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service. You can learn more how to manage cookies in the Browser Cookies Guide. To find out more out more on how to manage and delete cookies, visit wikipedia.org, www.allaboutcookies.org.</p>
            <p>As most of the online services, our website uses cookies first-party and third-party cookies for a number of purposes. The first-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data.</p>
            <p>The third-party cookies used on our websites are used mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website.</p>
            <h6><strong>Examples of Cookies we use:</strong></h6>
            <p>Essential: some cookies are essential for you to be able to experience the full functionality of our site. They allow us to maintain user sessions and prevent any security threats. They do not collect or store any personal information.</p>
            <p>Statistics: these cookies store information like the number of visitors to the website, the number of unique visitors, which pages of the website have been visited, the source of the visit etc. These data help us understand and analyze how well the website performs and where it needs improvement.</p>
            <p>Functional: these are the cookies that help certain non-essential functionalities on our website. These functionalities include embedding content like videos or sharing contents on the website on social media platforms.</p>
            <p>Preferences: these cookies help us store your settings and browsing preferences like language preferences so that you have a better and efficient experience on future visits to the website.</p>
            <p>Your consent applies to the following domain: <a href="http://www.renaissance-h2020.eu/">www.renaissance-h2020.eu</a>.</p>
          </li>
          <li>
            <h3>Use of data</h3>
            <p><strong>RENAISSANCE uses the collected data for various purposes:</strong></p>
            <ul>
              <li>To provide and maintain the Service</li>
              <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
              <li>To provide analysis or valuable information so that we can improve the Service</li>
              <li>To monitor the usage of the Service</li>
            </ul>
          </li>
          <li>
            <h3>Transfer of data</h3>
            <p>During RENAISSANCE project, access to your personal data will be granted to the departments that need it to perform our contractual and legal obligations.</p>
            <p>We also pass on personal data when we are required to do so by law (Art 6, par 1, point (c) GDPR) or the data subject has given his or her consent to the processing (Art 6, par 1, point (a) GDPR). Under these conditions, recipients of personal data may, for example, be public authorities and institutions in the event of a legal or regulatory obligation.</p>
            <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
            <p>If you are located outside Italy and choose to provide information to us, please note that we transfer the data, including Personal Data, to Italy and process it there.</p>
            <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
            <p>RENAISSANCE will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
          </li>
          <li>
            <h3>Security of data</h3>
            <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
            <h6>Service Providers</h6>
            <p>We may employ third party companies and individuals to facilitate our Service (&ldquo;Service Providers&rdquo;), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
            <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
            <p>The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.</p>
            <h6>Analytics</h6>
            <p>Google Analytics (Google Ireland Limited)</p>
            <p>Google Analytics is a web analysis service provided by Google Ireland Limited (&ldquo;Google&rdquo;). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.</p>
            <p>Google may use the Data collected to contextualize and personalize the ads of its own advertising network.</p>
            <p>Personal Data collected: Cookies; Usage Data.</p>
            <p>Place of processing: Ireland – <a href="https://policies.google.com/privacy">Privacy Policy</a></p>
            <p>Interaction with external social networks and platforms</p>
            <p>This type of service allows interaction with social networks or other external platforms directly from the pages of this Application. The interaction and information obtained through this Application are always subject to the User&rsquo;s privacy settings for each social network. This type of service might still collect traffic data for the pages where the service is installed, even when Users do not use it. It is recommended to log out from the respective services in order to make sure that the processed data on this Application isn&rsquo;t being connected back to the User&rsquo;s profile.</p>
          </li>
          <li>
            <h3>Facebook Like button and social widgets</h3>
            <p> </p>
            <p>The Facebook Like button and social widgets are services allowing interaction with the Facebook social network provided by Facebook, Inc.</p>
            <p>Personal Data collected: Cookies; Usage Data.</p>
            <p>Place of processing: United States – <a href="http://www.facebook.com/">Privacy Policy</a>.</p>
            <p>LinkedIn button and social widgets (LinkedIn Corporation)</p>
            <p> </p>
            <p>The LinkedIn button and social widgets are services allowing interaction with the LinkedIn social network provided by LinkedIn Corporation.</p>
            <p>Personal Data collected: Cookies; Usage Data.</p>
            <p>Place of processing: United States – <a href="http://www.twitter.com/">Privacy Policy</a>.</p>
            <p>Twitter Tweet button and social widgets (Twitter, Inc.)</p>
            <p> </p>
            <p>The Twitter Tweet button and social widgets are services allowing interaction with the Twitter social network provided by Twitter, Inc.</p>
            <p>Personal Data collected: Cookies; Usage Data.</p>
            <p>Place of processing: United States – <a href="http://www.linkedin.com/">Privacy Policy</a>.</p>
            <p> </p>
            <p>Personal Data is collected for the purpose of displaying content from external platforms. This type of service allows you to view content hosted on external platforms directly from the pages of this Application and interact with them.This type of service might still collect web traffic data for the pages where the service is installed, even when Users do not use it.</p>
          </li>
          <li>
            <h3>Google Fonts</h3>
            <p>Google Fonts is a typeface visualization service provided by Google Ireland Limited that allows this Application to incorporate content of this kind on its pages.</p>
            <p>Personal Data collected: Usage Data; various types of Data as specified in the privacy policy of the service.</p>
            <p>Place of processing: Ireland – Privacy Policy.</p>
          </li>
          <li>
            <h3>Contact form</h3>
            <p>By filling in the contact form with their Data, the User authorizes RENAISSANCE project to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form&rsquo;s header.</p>
            <p>Personal Data collected: Email address, First name, Last name</p>
          </li>
          <li>
            <h3>Managing contacts and sending messages through Newsletter service (Mailerlite)</h3>
            <p>By registering on the mailing list or for the newsletter, the User&rsquo;s email address will be added to the contact list of those who may receive email messages containing information of promotional nature concerning RENAISSANCE project. MailerLite is the email address management and message sending service used in this website.</p>
            <p>Personal Data collected: Email address, First name, Last name</p>
            <p>Place of processing: Lithuania, <a href="https://www.mailerlite.com/legal/privacy-policy">Privacy Policy</a></p>
          </li>
          <li>
            <h3>Links To Other Sites</h3>
            <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party&rsquo;s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
            <p>You have the right to obtain from VUB – Vrij University Brussels</p>
            <ul>
              <li>access to your personal data (Art 15 GDPR),</li>
              <li>the rectification of your personal data (Art 16 GDPR),</li>
              <li>the erasure of your personal data (Art 17 GDPR),</li>
              <li>the restriction of the processing of your personal data (Art 18 GDPR) and</li>
              <li>the portability of your personal data (Art 20 GDPR).</li>
            </ul>
            <p>You also have the right to object to the processing of your personal data by VUB – Vrij University Brussels (Art 21 GDPR)</p>
            <p>Insofar as the processing of your personal data by VUB – Vrij University Brussels is based on consent (Art 6, par (1), point (a) GDPR), you can withdraw the consent at any time; the legality of the data processing performed on the basis of the consent remains unaffected until the consent is revoked.</p>
            <p>Should you wish to assert these rights or have any questions concerning personal data, please do not hesitate to contact us at any time by email at privacy@renaissance-h2020.eu or using the contact information provided in the footbar.</p>
            <p>Irrespective of this, you have the right to lodge a complaint with a supervisory authority – in particular in the EU Member State in which you reside, or work or the place of the alleged infringement – if you believe that the processing of your personal data violates the GDPR or other applicable data protection laws (Art 77 GDPR).</p>
          </li>
          <li>
            <h3>Changes To This Privacy Policy</h3>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the &ldquo;effective date&rdquo; at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
          </li>
          <li>
            <h3>Contact Us</h3>
            <p>If you have any questions about this Privacy Policy, please contact us by email: <a href="mailto:privacy@renaissance-h2020.eu">privacy@renaissance-h2020.eu</a></p>
          </li>
        </ol>
        <p>You may visit the Privacy Policy page of the official project website 
          <a href="https://www.renaissance-h2020.eu/privacy-policy/" title="Privacy Policy page of Renaissance">here</a>.
        </p>
      </div>
  
    </div>
</div>
