<div id="ps_demo" class="container bg-light">
    <div class="row ps_padding_20">
      <div class="col-md-6">
        <app-left-side-logo></app-left-side-logo>
      </div>
      <div class="col-md-6">
        <h2>The DEMO of the tool is in progress!</h2>
        <h3>Download the <a href = './assets/pdf/renergise_tool_user_manual_final.pdf'>user manual.</a></h3>
      </div>
  </div>
</div>