<div id="ps_tab2" class="container bg-light">
  <app-project-name-label></app-project-name-label>
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <div class="row pt-4">
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabgeneral" routerLinkActive="active" title="General option"><img title="General Information" class= "img-fluid" src="assets/images/1x/btn_assetGeneral.png"></a></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabnew" routerLinkActive="active" title="New Asset option"><img title="New Asset" class= "img-fluid" src="assets/images/1x/btn_assetNewSelected.png"></a></div>
        <div class="col-sm-6 d-grid mx-auto"></div>
      </div>    
      
      <div class="row">
          <div class="col-sm-1 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabmodify" routerLinkActive="active" title="Asset Modify option"><img title="Asset Modify" class= "img-fluid" src="assets/images/1x/btn_assetModify.png"></a></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/compare" routerLinkActive="active" title="Compare Projects"><img title="Compare Projects" class= "img-fluid" src= "assets/images/1x/btn_assetCompare.png"></a></div>
          <div class="col-sm-5 d-grid mx-auto"></div>
      </div> 
      
      <div class="row">
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/calculate" routerLinkActive="active" title="Calculate option"><img title="Calculate" class= "img-fluid" src="assets/images/1x/btn_calculate.png"></a></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
      </div> 
    </div>

    <div class="col-md-6 ps_form rounded ps_white">
      <form [formGroup]="storageForm" (ngSubmit)="btnClickAddAsset()">
        <div class="form-group">
          <div class="row pt-4">
            <app-menu-input-data></app-menu-input-data>
            <h1>
              <img
                class="img-fluid ps_imgsize"
                title="Import Data Files"
                src="assets/images/1x/newAssetImage.png"
              />
              New asset: Battery
            </h1>
            <h2>{{ description }}</h2>
          </div>

          <div class="row">
            <!-- <div class="col-8 d-grid mx-auto"> -->
            <div class="ps_logo_green">{{ question }}</div>
            <div>{{ answer }}</div>
            <!-- </div> -->
            <!-- <div class="col-4 d-grid mx-auto"> -->
            <!--<form-field appearance="fill">
                                <mat-slide-toggle title="Advanced options" (change)="toggleShow()">Advanced</mat-slide-toggle>                        
                            </form-field>-->
            <!-- </div> -->
          </div>
          <div class="row pt-2">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="asset_id"
                  name="asset_id"
                  id="asset_id"
                  #asset_id
                  type="text"
                  class="form-control ps_renergise_btn"
                  title="Asset name"
                  placeholder="Asset name"
                />
                <span
                  *ngIf="
                    !storageForm.get('asset_id').valid &&
                    storageForm.get('asset_id').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="storageForm.get('asset_id').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="soc_init"
                  name="soc_init"
                  id="soc_init"
                  #soc_init
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="Initial state of charge  of the battery [-]"
                  placeholder="Initial state of charge  of the battery [-]"
                />
                <span
                  *ngIf="
                    !storageForm.get('soc_init').valid &&
                    storageForm.get('soc_init').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="storageForm.get('soc_init').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="storageForm.get('soc_init').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than minimum state.</span
                  >
                  <span
                    *ngIf="storageForm.get('soc_init').errors['max']"
                    style="font-size: small"
                    >This parameter must be less than maximum state.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="soc_min"
                  name="soc_min"
                  id="soc_min"
                  #soc_min
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="Minimum state of charge usable [-]"
                  placeholder="Minimum state of charge usable [-]"
                />
                <span
                  *ngIf="
                    !storageForm.get('soc_min').valid &&
                    storageForm.get('soc_min').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="storageForm.get('soc_min').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="storageForm.get('soc_min').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 0.</span
                  >
                  <span
                    *ngIf="storageForm.get('soc_min').errors['max']"
                    style="font-size: small"
                    >This parameter must be less than maximum state.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="soc_max"
                  name="soc_max"
                  id="soc_max"
                  #soc_max
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="Maximum state of charge usable [-]"
                  placeholder="Maximum state of charge usable [-]"
                />
                <span
                  *ngIf="
                    !storageForm.get('soc_max').valid &&
                    storageForm.get('soc_max').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="storageForm.get('soc_max').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="storageForm.get('soc_max').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than minimum state.</span
                  >
                  <span
                    *ngIf="storageForm.get('soc_max').errors['max']"
                    style="font-size: small"
                    >This parameter must be less than 1.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="charge_eff"
                  name="charge_eff"
                  id="charge_eff"
                  #charge_eff
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="Efficiency of the charging process [-]"
                  placeholder="Efficiency of the charging process [-]"
                />
                <span
                  *ngIf="
                    !storageForm.get('charge_eff').valid &&
                    storageForm.get('charge_eff').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="storageForm.get('charge_eff').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="storageForm.get('charge_eff').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 0.</span
                  >
                  <span
                    *ngIf="storageForm.get('charge_eff').errors['max']"
                    style="font-size: small"
                    >This parameter must be less than 1.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="disch_eff"
                  name="disch_eff"
                  id="disch_eff"
                  #disch_eff
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="Efficiency of the discharging process [-]"
                  placeholder="Efficiency of the discharging process [-]"
                />
                <span
                  *ngIf="
                    !storageForm.get('disch_eff').valid &&
                    storageForm.get('disch_eff').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="storageForm.get('disch_eff').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="storageForm.get('disch_eff').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 0.</span
                  >
                  <span
                    *ngIf="storageForm.get('disch_eff').errors['max']"
                    style="font-size: small"
                    >This parameter must be less than 1.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="max_charge"
                  name="max_charge"
                  id="max_charge"
                  #max_charge
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="Maximum C-rate permitted in charging mode [1/h] (kW/kWh)"
                  placeholder="Maximum C-rate permitted in charging mode [1/h] (kW/kWh)"
                />
                <span
                  *ngIf="
                    !storageForm.get('max_charge').valid &&
                    storageForm.get('max_charge').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="storageForm.get('max_charge').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="storageForm.get('max_charge').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 0.</span
                  >
                  <span
                    *ngIf="storageForm.get('max_charge').errors['max']"
                    style="font-size: small"
                    >This parameter must be less than 1.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="max_discharge"
                  name="max_discharge"
                  id="max_discharge"
                  #max_discharge
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="Maximum C-rate permitted in discharging mode [1/h] (kW/kWh)"
                  placeholder="Maximum C-rate permitted in discharging mode [1/h] (kW/kWh)"
                />
                <span
                  *ngIf="
                    !storageForm.get('max_discharge').valid &&
                    storageForm.get('max_discharge').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="storageForm.get('max_discharge').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="storageForm.get('max_discharge').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 0.</span
                  >
                  <span
                    *ngIf="storageForm.get('max_discharge').errors['max']"
                    style="font-size: small"
                    >This parameter must be less than 1.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="min_capacity"
                  name="min_capacity"
                  id="min_capacity"
                  #min_capacity
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="The minimum energy capacity of the battery [kWh]"
                  placeholder="The minimum energy capacity of the battery [kWh]"
                />
                <span
                  *ngIf="
                    !storageForm.get('min_capacity').valid &&
                    storageForm.get('min_capacity').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="storageForm.get('min_capacity').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="storageForm.get('min_capacity').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 0.</span
                  >
                  <span
                    *ngIf="storageForm.get('min_capacity').errors['max']"
                    style="font-size: small"
                    >This parameter must be less than maximum capacity.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="max_capacity"
                  name="max_capacity"
                  id="max_capacity"
                  #max_capacity
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="The maximum energy capacity of the battery [kWh]"
                  placeholder="The maximum energy capacity of the battery [kWh]"
                />
                <span
                  *ngIf="
                    !storageForm.get('max_capacity').valid &&
                    storageForm.get('max_capacity').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="storageForm.get('max_capacity').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="storageForm.get('max_capacity').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than minimum capacity.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="capex"
                  name="capex"
                  id="capex"
                  #capex
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="Cost of buying the asset per capacity [€/kWh]"
                  placeholder="Cost of buying the asset per capacity [€/kWh]"
                />
                <span
                  *ngIf="
                    !storageForm.get('capex').valid &&
                    storageForm.get('capex').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="storageForm.get('capex').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="storageForm.get('capex').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 0.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="opex"
                  name="opex"
                  id="opex"
                  #opex
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="The yearly operational cost per capacity [€/kWh/year]"
                  placeholder="The yearly operational cost per capacity [€/kWh/year]"
                />
                <span
                  *ngIf="
                    !storageForm.get('opex').valid &&
                    storageForm.get('opex').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="storageForm.get('opex').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="storageForm.get('opex').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 0.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="lifetime"
                  name="lifetime"
                  id="lifetime"
                  #lifetime
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="The lifetime of the asset [years]"
                  placeholder="The lifetime of the asset [years]"
                />
                <span
                  *ngIf="
                    !storageForm.get('lifetime').valid &&
                    storageForm.get('lifetime').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="storageForm.get('lifetime').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="storageForm.get('lifetime').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 1.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <!-- <div class="row pt-1">
                        <div class="col-8 d-grid mx-auto">
                            <form-field appearance="fill">
                                <label class="form-control ps_renergise_btn" #power_profile_label title="Power profile of the asset [kW]" for="power_profile">Power profile of the asset [kW]</label>
                                <input   formControlName="power_profile" name="power_profile" id="power_profile" #power_profile type="file" class="ps_hidden form-control ps_renergise_btn" title="Power profile of the asset [kW]" placeholder="Power profile of the asset [kW]" observeFiles (change)="onChange($event)">
                            </form-field>                
                        </div>
                        <div class="col-4 d-grid mx-auto">
                            <fa-icon mat-raised-button [icon]="faFile" size="2x" [styles]="{'color': '#FCC78C'}"></fa-icon>
                        </div>
                    </div> -->
        </div>
      </form>

      <div class="row pt-1 pb-2">
        <div class="col-4 d-grid mx-auto">
          <button
            type="button"
            title="Clear form"
            #clear_button
            class="btn ps_renergise_btn"
            (click)="btnClickClear()"
          >
            Default
          </button>
        </div>

        <div class="col-4 d-grid mx-auto">
          <button
            type="button"
            title="Add asset"
            class="btn ps_renergise_btn"
            (click)="btnClickAddAsset()"
            [disabled]="!storageForm.valid"
          >
            Add Asset
          </button>
        </div>

        <div class="col-4 d-grid mx-auto">
          <div *ngIf="show === 'success'" @fadeOut>
            <img
              class="submit_img"
              title="Added"
              src="assets/images/1x/baseline_task_alt_green_24dp.png"
              #submit_img
            />
          </div>
          <div *ngIf="show === 'failed'" @fadeOut>
            <img
              class="submit_img"
              title="Added"
              src="assets/images/1x/baseline_highlight_off_red_24dp.png"
              #submit_img
            />
          </div>
        </div>
      </div>
      <div class="row pt-1 pb-2">
        <div class="col-8 d-grid mx-auto">
          <span class="alert alert-warning" role="alert" *ngIf="setWarning">{{
            warning_msg
          }}</span>
        </div>
        <div class="col-4 d-grid mx-auto"></div>
      </div>
    </div>
  </div>
</div>
