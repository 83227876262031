import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { DuplicateDialogFormComponent } from './duplicate-dialog-form/duplicate-dialog-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { AuthService } from '../auth.service';
@Component({
  selector: 'app-myprojects',
  templateUrl: './myprojects.component.html',
  styleUrls: ['./myprojects.component.css']
})

export class MyprojectsComponent implements OnInit {
  projects: [];
  projectsExist: boolean = false;
  isShown:boolean[] = []; //Initialize boolean array
  projectName: string;
  user = GlobalConstants.registeredUser;

  constructor(private http: HttpClient, public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private authService: AuthService) { }
  
  ngOnInit(): void {
    console.log("Registered user Projects?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;

    if(this.user){
      var host = "https://renergisetool.evergi.be/";
      var url = host + "users/" + this.user + "/projects/";
      console.log("url", url);
      this.http.get<any>(url).subscribe(
        response => {
          this.projects = response['projects'];
          GlobalConstants.myprojects = response['projects'];
          for(var i = 0; i < this.projects.length;i++){
            this.isShown.push(false);
          this.projectsExist=true;

          }
        }
      );
    }
    else{
      console.log("There is any user.");
    }
  }

  onSelect(projectId: string){
    // console.log("project", projectId);
    GlobalConstants.projectId = projectId;
    localStorage.setItem("SELECTED_PROJECT", GlobalConstants.projectId);
  }
  
  show(i:number) {
    if( this.isShown[i] === false){
      this.isShown[i] = !this.isShown[i];
    }
  }

  hide(i:number) {
    if( this.isShown[i] === true){
      this.isShown[i] = !this.isShown[i];
    }
  }
  
  openDialogDuplicate(projectId: string){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      projectName: this.projectName = '',
      currentProjectName: projectId
    };

    const dialogRef = this.dialog.open(DuplicateDialogFormComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log("Duplicate the project", projectId);
      this.projectName = result;
      if(this.projectName){
        var host = "https://renergisetool.evergi.be/";
        var url = host + "users/" + this.user + "/projects/";

        const params = new HttpParams(
          {
            fromObject : {
              project_id : this.projectName,
              old_project_id : projectId
            }
          }
        )
        const headers = new HttpHeaders()
          .append(
            'Content-Type',
            'application/x-www-form-urlencoded'
        );
        this.http.post<any>(url + '?' + params.toString(), {headers : headers}, {observe : 'response'}).subscribe(
          response => {
            if (response['status'] === 200){
              console.log("The project was duplicated!");
              this.ngOnInit();
            }
            else {
              console.log("Error-The project was not duplicated!");
            }
          }
        );
      }
    });
  }

  openDialogDelete(projectId: string){
    const dialogRef = this.dialog.open(DeleteDialogComponent,{
      data:{
        message: 'Are you sure want to delete project '+ projectId+'?',
        buttonText: {
          ok: 'Delete',
          cancel: 'Cancel'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        var host = "https://renergisetool.evergi.be/";
        var url = host + "users/" + this.user + "/projects/" + projectId;
        console.log("Delete button", projectId, "URL", url);

        this.http.delete(url, {observe: 'response'}).subscribe(
          response => {console.log("Project with project name ", projectId, "was deleted.","Response", response);
          if (response['status'] === 200){
            GlobalConstants.projectId = "";
            localStorage.removeItem("SELECTED_PROJECT");
            this.ngOnInit();
          }
        }, 
        error => {
          console.warn(error.error['warning']);     
          alert(error.error['warning']+'. '+'Please wait and try to delete this project later.');
        }
        );
      }
    });  
  }
}
