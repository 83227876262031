<div id="ps_about" class="container bg-light">
    <div class="row ps_padding_20">
      <div class="col-md-6">
        <app-left-side-logo></app-left-side-logo>
      </div>
      <div class="col-md-6">
        <h2>RENERGiSE Tool Description</h2>
        <h3></h3>
        <p class="par_style">The RENERGISE Multi-vector Optimization tool provides registered users the possibility to create, edit and compare hypothetical or actual data from local energy networks that might develop as new energy communities. Provide input data and the RENERGISE tool will support decision making by calculating total electricity import and export from and to the grid, the self-consumption and self-sufficiency ratio and the total cost, net of investment and energy import costs. The RENERGISE tool aims at facilitating the growth of flexible networks, fostering the energy transition and creating a more resilient and balanced prosumer oriented system.</p>
      </div>
  </div>
</div>