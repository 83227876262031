import { Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { faMapMarkerAlt, faCalendarWeek, faClock } from '@fortawesome/free-solid-svg-icons';
import { CoordinatesInteractionService } from '../coordinates-interaction.service';

import { trigger, style, animate, transition } from '@angular/animations';
import { GlobalConstants } from '../common/global-constants';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { AuthService } from '../auth.service';


export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-adding-asset-general',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('2s ease-out',
              style({ height: 500, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 500, opacity: 1 }),
            animate('2s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ],
  providers: [ { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] } ]
})
export class AddingAssetGeneralComponent implements OnInit {

  constructor(
    private _coordinatesInteraction: CoordinatesInteractionService,
    private fb: FormBuilder,
    private http: HttpClient,
    private pp: DatePipe,
    private router: Router, 
    private authService: AuthService
  ) { 
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 5, 0, 1);
    this.maxDate = new Date(currentYear -3, 11, 31);
  }

  @ViewChild('project_id') project_id: ElementRef;
  @ViewChild('year') year: ElementRef;
  @ViewChild('location') location: ElementRef;
  @ViewChild('interest_rate') interest_rate: ElementRef;
  @ViewChild('clear_button') clear_button: ElementRef;
  @ViewChild('picker1') picker1: MatDatepicker<Date>;

  faMapMarkerAlt = faMapMarkerAlt;
  faCalendarWeek = faCalendarWeek;
  faClock = faClock;
  isGoogleMapsShown: boolean = false; //initialize it to false - not to be shown in the loading of the page
  isCompleted: boolean = false;
  user = GlobalConstants.registeredUser;
  coords = GlobalConstants.generalCoords;

  generalForm = this.fb.group({
    project_id: ['',  Validators.required],
    year: ['', Validators.required],
    location: [''],
    interest_rate: [ null, [ Validators.min(0), Validators.max(100), Validators.required]]
  });

  start_t: string = null;
  end_t: string = null;
  reset_state: boolean = false;
  minDate: Date;
  maxDate: Date;

  ngOnInit(): void {
    console.log("Registered user new project?",GlobalConstants.registeredUser);
    GlobalConstants.registeredUser= localStorage.getItem("USERNAME");
    this.user = GlobalConstants.registeredUser;

    this._coordinatesInteraction.coordsMessage$.subscribe(
      message => {
        //alert(message);
        this.coords = message;
        this.location.nativeElement.value = message;
        this.isGoogleMapsShown = !this.isGoogleMapsShown;
      }
    )
  }

  selectYear;
  chosenYearHandler(event) { 
    let { _d } = event;
    this.selectYear = _d;
    this.picker1.close(); 
  }

  btnClickClear() {
    console.log("Btn Clear clicked");
    this.project_id.nativeElement.value = '';
    this.year.nativeElement.value = '';
    this.location.nativeElement.value = '';
    this.interest_rate.nativeElement.value = null;
  }

  async btnClickSubmit() {
    console.log("Btn Submit clicked");
    // var user = GlobalConstants.registeredUser;
    var baseUrl = "https://renergisetool.evergi.be/";
    var v1 = this.generalForm.get('project_id').value;
  
    // var start_t = this.generalForm.get('year').value;
    var v2 =  this.generalForm.get('year').value.getFullYear();
    var v3 = this.generalForm.get('interest_rate').value / 100;
    var interest = v3.toString();
    // console.log("interest", typeof(this.generalForm.get('interest_rate').value / 100), typeof(interest))

    var temp = this.coords.split(", ", 2);
    var lat = temp[0];
    var long = temp[1];

    var url_params = new HttpParams({
      fromObject: {
        project_id: v1,
        year: v2,
        latitude: lat,
        longitude: long,
        interest_rate: interest
      }
    });

    var reqUrl = baseUrl + 'users/' + this.user + '/projects/?' + url_params.toString();
    console.log("URL",reqUrl);

    this.isCompleted = true;
    this.http.post(reqUrl, {observe: 'response'}).subscribe(
      response => {
      // console.log("NEW PROJECT", response);
        if (response["success"] === true ) {
          GlobalConstants.projectId = v1;
          localStorage.setItem('SELECTED_PROJECT', GlobalConstants.projectId);
          this.router.navigateByUrl('/tabnew');
        }
      },
      error => {
        // console.log("ERROR", error);
        alert(error.error['warning']+'.');
      }
    );
  }

  googleMapsShow() {
    // console.log("Btn isGoogleMapsShown clicked: " + this.isGoogleMapsShown);
    this.isGoogleMapsShown = !this.isGoogleMapsShown;
  };

}
