<div class="row pt-4">
    <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabgeneral" routerLinkActive="active" title="General option"><img title="General Information" class= "img-fluid" src="assets/images/1x/btn_assetGeneral.png"></a></div>
    <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabnew" routerLinkActive="active" title="New Asset option"><img title="New Asset" class= "img-fluid" src="assets/images/1x/btn_assetNew.png"></a></div>
    <div class="col-sm-6 d-grid mx-auto"></div>
</div>    

<div class="row">
    <div class="col-sm-1 d-grid mx-auto"></div>
    <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabmodify" routerLinkActive="active" title="Asset Modify option"><img title="Asset Modify" class= "img-fluid" src="assets/images/1x/btn_assetModify.png"></a></div>
    <div class="col-md-3 d-grid mx-auto"><a routerLink="/compare" routerLinkActive="active" title="Compare Projects"><img title="Compare Projects" class= "img-fluid" src= "assets/images/1x/btn_assetCompare.png"></a></div>
    <div class="col-sm-5 d-grid mx-auto"></div>
</div> 

<div class="row">
    <div class="col-sm-2 d-grid mx-auto"></div>
    <div class="col-md-3 d-grid mx-auto"></div>
    <div class="col-md-3 d-grid mx-auto"><a routerLink="/calculate" routerLinkActive="active" title="Calculate option"><img title="Calculate" class= "img-fluid" src="assets/images/1x/btn_calculate.png"></a></div>
    <div class="col-sm-2 d-grid mx-auto"></div>
    <div class="col-sm-2 d-grid mx-auto"></div>

</div> 