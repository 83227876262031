import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { GlobalConstants } from '../common/global-constants';

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.css']
})
export class CompareComponent implements OnInit {
  user = GlobalConstants.registeredUser;
  projects = GlobalConstants.myprojects;
  selectedValue1;
  selectedValue2;

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    
    if(this.user){
      var host = "https://renergisetool.evergi.be/";
      var url = host + "users/" + this.user + "/projects/";
      console.log("url " + url);
      this.http.get<any>(url, {observe: 'response'}).subscribe(
        response => {
          console.log("response: " + response);
          if(response.status === 200){
            this.projects = response.body['projects'];
            GlobalConstants.myprojects = response.body['projects'];
          }    
        }
      );
    }
  }

  compareTotalResults(){
    if(this.selectedValue1 ===  this.selectedValue2){
      alert("Please select different projects.");
      this.router.navigateByUrl('/compare');
    }
    else{
      GlobalConstants.compareProjectId_1 = this.selectedValue1;
      GlobalConstants.compareProjectId_2 = this.selectedValue2;
      this.router.navigateByUrl('/compare-results');
    }
 }
 compareChart(){
  this.router.navigateByUrl('/compare-chart');
  if(this.selectedValue1 ===  this.selectedValue2){
    alert("Please select different projects.");
    this.router.navigateByUrl('/compare');
  }
  else{
    GlobalConstants.compareProjectId_1 = this.selectedValue1;
    GlobalConstants.compareProjectId_2 = this.selectedValue2;
    this.router.navigateByUrl('/compare-chart');
  }
 }
    
  
}
