<!--social media row-->
<!--faceboot twitter in-->
<div class="row pt-1">    
    <div class="col-md-2">
        <a href="https://twitter.com/H2020_Ren" title="Renaissance-h2020 twitter"><fa-icon [icon]="faTwitter" size="3x" [styles]="{'color': '#3BABB0'}"></fa-icon></a>
    </div>
    <div class="col-md-2">
        <a href="https://www.linkedin.com/company/renaissance-h2020/" title="Renaissance-h2020 linkedin"><fa-icon [icon]="faLinkedin" size="3x" [styles]="{'color': '#3BABB0'}"></fa-icon></a>
    </div>
    <!-- <div class="col-lg-2"></div>
    <div class="col-lg-2"></div>
    <div class="col-lg-2"></div>
    <div class="col-lg-2"></div> -->
</div>