<div id="ps_profilepage" class="container bg-light">
    <div class="row ps_padding_20">
      <div class="col-md-6">
        <app-user-main-menu></app-user-main-menu>
      </div>

      <div class="col-md-6">
        <h2>Preview assets</h2>
        <div class="row pt-4">
            <div class="col-md-1 d-grid mx-auto"></div>
            <div class="col-md-3 d-grid mx-auto"><a href="#"><img title="Empty area for new Asset" class="img-fluid" src="assets/images/1x/btn_assetEmpty.png"></a></div>
            <div class="col-md-1 d-grid mx-auto"></div>
            <div class="col-md-3 d-grid mx-auto"><a href="#"><img title="Empty area for new Asset" class="img-fluid" src="assets/images/1x/btn_assetEmpty.png"></a></div>
            <div class="col-md-4 d-grid mx-auto"></div>
        </div>    
        
        <div class="row">
            <div class="col-md-3 d-grid mx-auto"></div>
            <div class="col-md-3 d-grid mx-auto"><a href="#"><img title="Empty area for new Asset" class="img-fluid" src="assets/images/1x/btn_assetEmpty.png"></a></div>
            <div class="col-md-1 d-grid mx-auto"></div>
            <div class="col-md-3 d-grid mx-auto"><a href="#"><img title="Empty area for new Asset" class="img-fluid" src="assets/images/1x/btn_assetEmpty.png"></a></div>
            <div class="col-md-2 d-grid mx-auto"></div>
        </div> 

        <div class="row">
            <div class="col-md-1 d-grid mx-auto"></div>
            <div class="col-md-3 d-grid mx-auto"><a href="#"><img title="Empty area for new Asset" class="img-fluid" src="assets/images/1x/btn_assetEmpty.png"></a></div>
            <div class="col-md-1 d-grid mx-auto"></div>
            <div class="col-md-3 d-grid mx-auto"><a href="#"><img title="Empty area for new Asset" class="img-fluid" src="assets/images/1x/btn_assetEmpty.png"></a></div>
            <div class="col-md-4 d-grid mx-auto"></div>
        </div> 
      </div>
      
  </div>
</div>