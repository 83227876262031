import { Component, OnInit } from '@angular/core';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import {saveAs} from 'file-saver';
import { GlobalConstants } from '../common/global-constants';
import { trigger, style, animate, transition } from '@angular/animations';
import { ParseError } from '@angular/compiler';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

const leaveTrans = transition(':enter', [
  style({
    opacity: 1
  }),
  animate('3s ease-out', style({
    opacity: 0,
  }))
])

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-new-asset5-building',
  templateUrl: './new-asset5-building.component.html',
  styleUrls: ['./new-asset5-building.component.css'],
  animations: [fadeOut]
})
export class NewAsset5BuildingComponent implements OnInit {
  

  faFile = faFile;

  @ViewChild('yearly_consumption') yearly_consumption: ElementRef;
  @ViewChild('predefined_profile') predefined_profile: ElementRef;
  @ViewChild('asset_id') asset_id: ElementRef;

  @ViewChild('power_profile') power_profile: ElementRef;
  @ViewChild('power_profile_label') power_profile_label: ElementRef;
  @ViewChild('post_message') post_message: ElementRef;
  @ViewChild('clear_button') clear_button: ElementRef;
  
  description = GlobalConstants.assetDescription;
  question = GlobalConstants.assetQuestion;
  answer = GlobalConstants.assetAnswer;
  file: File = null;
  reset_state : Boolean = false;
  show: string = "";
  setWarning : Boolean = false;
  warning_msg: string = "";

  user = GlobalConstants.registeredUser;
  project = GlobalConstants.projectId;

  buildingForm = this.fb.group({
    yearly_consumption: ['', [Validators.min(0)]],
    predefined_profile:['Select a predefined profile'],
    asset_id: ['', [Validators.required]],
    power_profile: ['']
  });

  constructor(private fb: FormBuilder, private http: HttpClient, private authService: AuthService, private router: Router) { }
  
  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    else{
      alert('You must select a project to continue.');
      this.router.navigateByUrl('/myprojects');
    }
  }

  onChange(event) {
    if (event.target.files[0]) {
      this.file = event.target.files[0];
      this.power_profile_label.nativeElement.innerHTML = this.file.name
      for (var i in this.buildingForm.controls) {
        if (i != 'power_profile' && i!= 'asset_id') {
          this.buildingForm.controls[i].reset();
          this.buildingForm.controls[i].disable();
        }
      }
    }
    else {
      this.file = null
      this.power_profile_label.nativeElement.innerHTML = "Power profile of the asset [kW]"
      for (var i in this.buildingForm.controls) {
        this.buildingForm.controls[i].enable();
      }
    }
  }

  onDownloadConsumer(){

    const params = new HttpParams(
      {
        fromObject: {
          user_id: GlobalConstants.registeredUser,
          project_id:GlobalConstants.projectId
        }
      }
    )

    var host = "https://renergisetool.evergi.be/";
    var url ="https://renergisetool.evergi.be/information/assets/chargers/powerprofile"
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type','text/csv')

    this.http.get(url+'?' + params.toString(), {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `consumers_powerprofile.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  onDownloadConsumerExample(){
    var host = "https://renergisetool.evergi.be/";
    var url ="https://renergisetool.evergi.be/information/assets/chargers/powerprofile"
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type','text/csv')

    this.http.get(url, {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `consumers_powerprofile_example.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  isShown: boolean = false ; // hidden by default
  toggleShow() {
    this.isShown = ! this.isShown;    
  }

  btnClickClear(){
    if (this.reset_state) {
      this.buildingForm.reset()

      this.clear_button.nativeElement.innerHTML = "Default"
      this.reset_state = !this.reset_state;
    } else {
      this.buildingForm.patchValue({
        yearly_consumption: '500000',
        predefined_profile: 'Belgium_commercial',
        asset_id: 'default_consumer'
      });
      this.clear_button.nativeElement.innerHTML = "Clear"
      this.reset_state = !this.reset_state;
    }
    this.buildingForm.enable()
    this.power_profile_label.nativeElement.innerHTML = "Power profile of the asset [kW]"
  }

  btnClickAddAsset(){
    console.log("Btn Add Asset clicked");
    var host = "https://renergisetool.evergi.be/"

    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    if (this.file) {
      const formdata = new FormData();

      formdata.append("powerprofile",this.file,this.file.name);
      const params = new HttpParams(
        {
          fromObject : {
            asset_id: this.asset_id.nativeElement.value
          }
        }
      )

      var url = host + "users/" + this.user + "/projects/" + this.project + "/consumers/"
      this.http.post(url + '?' + params.toString(), formdata, {observe : 'response'}).subscribe(
        response => {
          if (response['status'] == 200){
            console.log("response: " + response);
            this.show="success";
            setTimeout(() => {
              this.show=" ";
            }, 2000);
          }
        },
        error => {
          console.log("error: " + error);
          this.show = 'failed';
          setTimeout(() => {
            this.show = '';
          }, 2000);
          if (error.error['warning'] != null) {
            this.warning_msg = error.error['warning'];
            this.setWarning = true;
          }
        }
      )
    }else{
      const params = new HttpParams(
        {
          fromObject : {
            yearly_consumption : this.yearly_consumption.nativeElement.value,
            predefined_profile : this.predefined_profile.nativeElement.value,
            asset_id: this.asset_id.nativeElement.value
          }
        }
      )
      var url = host + "users/" + this.user + "/projects/" + this.project + "/consumers/";
      this.http.post<any>(url + '?' + params.toString(), {headers : headers} , {observe : 'response'}).subscribe(
        response => {
          console.log("response: " + response);
          if (response['status'] == 200){
            this.show="success";
            setTimeout(() => {
              this.show=" ";
            }, 2000);
          }
        },
        error => {
          console.log("error: " + error);
          this.show = 'failed';
          setTimeout(() => {
            this.show = '';
          }, 2000);
          if (error.error['warning'] != null) {
            this.warning_msg = error.error['warning'];
            this.setWarning = true;
          }
        }
        
      );
    }
  }
}
