<div id="ps_signuppage" class="container bg-light">
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <app-left-side-logo></app-left-side-logo>
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-4">
      <h2>Sign up</h2>
      <h3>Enter your email and password.</h3>
      <app-socialmedia></app-socialmedia>
      <form [formGroup]="signupForm" (ngSubmit)="btnClickSignUp()">
        <div class="form-group">
          <div class="row pt-4">
            <div class="col-8 d-grid mx-auto">
              <input  ngModel formControlName="email" id="email" type="text" class="form-control ps_renergise_btn" placeholder="Email" title="Email" #email>
              <span *ngIf="!signupForm.get('email').valid && signupForm.get('email').touched" class="help-block">
                <span *ngIf="signupForm.get('email').errors['required'] " style="font-size: small">Email is required.</span>
                <span *ngIf="signupForm.get('email').errors['email'] " style="font-size: small">Email not valid.</span>
              </span>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
       
          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <div class="input-group">
                <input ngModel formControlName="password" id="password" [type]="showPassword1 ? 'text' : 'password'" class="form-control ps_renergise_btn" placeholder="Password" title="Password" #password >
                <div class="input-group-append">
                  <span class="input-group-text password_icon">
                    <mat-icon matSuffix (click)="togglePasswordVisibility1()">{{showPassword1?'visibility_off':'visibility'}}</mat-icon>
                  </span>
                </div>
              </div>
              <span *ngIf="!signupForm.get('password').valid && signupForm.get('password').touched" class="help-block">
                  <span *ngIf="signupForm.get('password').errors['required']" style="font-size: small">Password is required.</span>
                  <span *ngIf="signupForm.get('password').errors['pattern']" style="font-size: small">Password must be at least 6 characters.</span>

              </span>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
    
          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <div class="input-group">
                <input ngModel formControlName="confirm_password" id="confirm_password" [type]="showPassword2 ? 'text' : 'password'" class="form-control ps_renergise_btn" placeholder="Confirm Password" title=" Confirm Password" #confirm_password>
                <div class="input-group-append">
                  <span class="input-group-text password_icon">
                    <mat-icon matSuffix (click)="togglePasswordVisibility2()">{{showPassword2?'visibility_off':'visibility'}}</mat-icon>
                  </span>
                </div>
              </div>
              <span *ngIf="!signupForm.get('confirm_password').valid && signupForm.get('confirm_password').touched" class="help-block">
                <span *ngIf="signupForm.get('confirm_password').errors['required']" style="font-size: small">Confirm password is required.</span>
                <span *ngIf="signupForm.get('confirm_password').errors['pattern']" style="font-size: small">Password must be at least 6 characters.</span>
              </span>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
        </div>
        <div class="row pt-1">
          <div class="col-4 d-grid mx-auto">
            <button type="button" class="btn ps_renergise_btn_sm" (click)="btnClickBack()" title="Back">Back</button>
          </div>
          <div class="col-4 d-grid mx-auto">
            <button type="button" class="btn ps_renergise_btn_sm" (click)="btnClickSignUp()" [disabled]="!signupForm.valid || !privacy_policy.checked" title="Sign up">Sign up</button>
          </div>
          <div class="col-4 d-grid mx-auto"></div>
        </div>
  
        <div class="row pt-1">
          <div class="col">
            <input ngModel type="checkbox" class="ps_renergise_btn" id="privacy_policy" formControlName="privacy_policy" #privacy_policy> I agree with <a routerLink="/privacypolicy">Privacy Policy</a>
          </div>
        </div>
      </form>

      
      <div class="row pt-1" *ngIf="this.authService.setErrorMessage">
        <div class="col-6 d-grid mx-auto">
          <div class="alert alert-danger" role="alert" >{{this.authService.errorMessage}}</div>
        </div>
        <div class="col-6 d-grid mx-auto"></div>
      </div>

      <div class="row pt-1" *ngIf="this.setLocalErrorMessage">
        <div class="col-6 d-grid mx-auto">
          <div class="alert alert-danger" role="alert" >{{this.localErrorMessage}}</div>
        </div>
        <div class="col-6 d-grid mx-auto"></div>
      </div>
      <div class="row pt-1">
        <div class="row pt-1">
          <p>Already have an account?<a  routerLink="/login" title="Login page"> Login</a></p>
      </div>
      </div>
     
    </div>
    <div class="col-md-1"></div>

  </div>
</div>
