<div id="ps_tab6" class="container bg-light">
    <app-project-name-label></app-project-name-label>
    <div class="row ps_padding_20">
        <div *ngIf="user">
            <div class="row" *ngIf = "!showSpinner">
                <p style="font-weight: bold;">How do I use the graph?</p>
                <p style="text-align: justify;">
                    Specific functionality has been added to the chart due to the large amount of presented information.<br/>
                    The user may click the zoom buttons according to the desired zoom depth and then focus on the dates axis by dragging the chart accordingly (click and drag).<br/>
                    It is possible to zoom in and out of the chart using the mouse scroll wheel. <br/>
                    Furthermore, the user may zoom out to the full view of the chart by clicking the reset zoom button. <br/>
                    Finally, the user may have a clearer view of the chart (less data) by disabling specific elements. This can be done by clicking on the specific element/s on the memo of the chart.   
                </p>
                <!-- <p style="font-weight: bold;">How do I see data in graph?</p> -->
                <div style="text-align: justify;">
                    <ol type="1">
                        <li>Click on assets dropdown menu and select one or more assets to see the data in the graph.</li>
                        <li>The monthly representation is selected by default.</li>
                        <li>By changing the time series representation (daily, weekly, monthly, etc), the previously selected data will be automatically updated.</li>
                        <li>By clicking an asset, its data will appear.</li>
                        <li>By clicking again any already displayed asset, its data will disappear.</li>
                    </ol>  
                </div>
                
            </div>
            <div class = "spinner_position" *ngIf = "showSpinner">
                <mat-spinner [diameter]="200" ProgressColor color = "#3babb0"></mat-spinner>
            </div>
            <div class="row" style="width: 100%;" *ngIf = "!showSpinner">
                <!-- <div class="col-sm-9"></div> -->
                <div class="col-sm-3" id="dropdown_menu">
                    <ng-multiselect-dropdown class='select-option'
                        [settings]="dropdownSettings2"
                        [(ngModel)]="selected"
                        [data]="[{ item_id: 0, item_text: 'monthly'},{ item_id: 1, item_text: 'weekly'}, { item_id: 2, item_text: 'daily'}, { item_id: 3, item_text: 'hourly'}]"
                        (onSelect)="onOptionSelect($event)"> 
                    </ng-multiselect-dropdown>
                </div>
                <div class="col-sm-3" id="dropdown_menu">
                    <ng-multiselect-dropdown class='select-option'
                        [settings]="dropdownSettings1"
                        [data]="dropdownList"
                        [(ngModel)]="selectedItems"
                        (onSelect)="onItemSelect($event)"
                        (onDeSelect)="onDeSelect($event)"> 
                    </ng-multiselect-dropdown>
                </div>
                
            </div>
            
            <div class="row">
                <div class="col" id = "chart">
                    <canvas #canvas id="canvas" >{{ chart }}</canvas>
                </div>
            </div>
            <div class="row" *ngIf = "!showSpinner">
                <div class="col ap_button_centered">
                    <button type="button" class="btn ps_renergise_btn ap_spacing " routerLink="/calculate" routerLinkActive="active" title ="Back to calculate">Back</button>
                    <button type="button" class="btn ps_renergise_btn zoom_button ap_spacing " (click)="zoomOutOption()" (mouseover)="changeZoomOutImg()" (mouseleave) = "changeZoomOutImgReset()" title ="Zoom out"><img title="Zoom out" src = {{zoomOutImg}}></button>
                    <button type="button" class="btn reset_button ap_spacing " title ="Reset zoom"  (click)="resetZoom()">Reset zoom</button>
                    <button type="button" class="btn ps_renergise_btn zoom_button ap_spacing " (click)="zoomInOption()" (mouseover)="changeZoomInImg()" (mouseleave) = "changeZoomInImgReset()" title ="Zoom in"><img title="Zoom in" src = {{zoomInImg}}></button>
                    <button type="button" class="btn ps_renergise_btn ap_spacing " routerLink="/results" routerLinkActive="active" title ="Total results">Total Results</button>
                </div>
            </div>
        </div>
    </div>
</div>