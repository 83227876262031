import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor( private router: Router, private authService: AuthService) { }

  ngOnInit(): void {

  }

  onClick(){
    if(this.authService.isUserLoggedIn){
      this.router.navigateByUrl('/myprojects')
    }
    else{
      this.router.navigateByUrl('/')
      
    }
  }
}
