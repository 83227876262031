<ul class="nav nav-tabs">
    <li class="nav-item">
      <button class="nav-link" routerLink="/publicgrids" routerLinkActive={{activate_publicgrids}}  [disabled] = 'enable__publicgrids'><img class="ps_img_resize" title="Public grid" src="assets/images/1x/asset1.png"></button>
    </li>
    <li class="nav-item">
      <button class="nav-link" routerLink="/batteries" routerLinkActive={{activate_batteries}} [disabled] = 'enable__batteries'><img class="ps_img_resize" title="Battery" src="assets/images/1x/asset2.png"></button>
    </li>
    <li class="nav-item">
      <button class="nav-link" routerLink="/generators" routerLinkActive={{activate_generators}} [disabled] = 'enable__generators'><img class="ps_img_resize" title="Gas Turbine" src="assets/images/1x/asset3.png"></button>
    </li>
    <li class="nav-item">
        <button class="nav-link" routerLink="/chargers" routerLinkActive={{activate_chargers}} [disabled] = 'enable__chargers'><img class="ps_img_resize" title="Charger" src="assets/images/1x/asset4.png"></button>
    </li>
    <li class="nav-item">
        <button class="nav-link" routerLink="/consumers" routerLinkActive={{activate_consumers}} [disabled] = 'enable__consumers'><img class="ps_img_resize" title="Consumer" src="assets/images/1x/asset5.png"></button>
    </li>
    <li class="nav-item">
        <button class="nav-link" routerLink="/solarpvs" routerLinkActive={{activate_solarpvs}} [disabled] = 'enable__solarpvs'><img class="ps_img_resize" title="Solar PV" src="assets/images/1x/asset6.png"></button>
    </li>
    <li class="nav-item">
        <button class="nav-link" routerLink="/windturbines" routerLinkActive={{activate_windturbines}} [disabled] = 'enable__windturbines'><img class="ps_img_resize" title="Wind Turbine" src="assets/images/1x/asset7.png"></button>
    </li>
</ul>