import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VubApiService {

  constructor(private http: HttpClient) { }

  public url = "https://renergisetool.evergi.be/";

  getServerName(){
    return this.http.get(this.url);
  }

  checkIfUserExists(user: string){
    this.url = this.url + "users/";
    console.log("url = " + this.url);
    console.log("user = " + user);

    var usersList = this.http.get(this.url);
    console.log(JSON.stringify(usersList));

    return this.http.get(this.url);
  }
}
