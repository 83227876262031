import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { GlobalConstants } from '../common/global-constants';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService) { }
  user = GlobalConstants.registeredUser;

  ngOnInit(): void {
    // if(GlobalConstants.registeredUser === ''){
    //   GlobalConstants.registeredUser= localStorage.getItem("USERNAME");
    //   console.log("USER",localStorage.getItem("USERNAME"));
    //   this.user = GlobalConstants.registeredUser;
    //   if(localStorage.getItem("USERNAME") === null){
    //     this.authService.isUserLoggedIn.next(false);
    //   }
    //   else{
    //     this.authService.isUserLoggedIn.next(true);
    //   }
    // }
  }

  btnClickSignUp(){
    console.log("Btn SignUp clicked");
    this.router.navigateByUrl('/signup')
  }

  btnClickLogIn(){
    console.log("Btn LogIn clicked");
    this.router.navigateByUrl('/login')
  }

  btnDemo(){
    console.log("Btn Demo clicked");
    this.router.navigateByUrl('/demo')
  }

}
