import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modify-asset-input-data',
  templateUrl: './modify-asset-input-data.component.html',
  styleUrls: ['./modify-asset-input-data.component.css']
})
export class ModifyAssetInputDataComponent implements OnInit {
  @Input() activate_publicgrids: string = 'inactive';
  @Input() activate_batteries: string = 'inactive' ;
  @Input() activate_generators: string = 'inactive' ;
  @Input() activate_chargers: string = 'inactive' ;
  @Input() activate_consumers: string = 'inactive' ;
  @Input() activate_solarpvs: string = 'inactive' ;
  @Input() activate_windturbines: string = 'inactive' ;

   enable__publicgrids = 'false';
   enable__batteries = 'false';
   enable__generators = 'false';
   enable__chargers = 'false';
   enable__consumers = 'false';
   enable__solarpvs = 'false';
   enable__windturbines = 'false';
   
  constructor() { }

  ngOnInit(): void {}

}
