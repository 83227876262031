<div id="ps_header" class="container ps_bold">
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
          <span class="navbar-brand" title="Renaissance Home page"><img src="assets/images/1x/renaissance_logo.png"></span>
          <button class="navbar-toggler" (click)="collapsed = !collapsed" type="button" >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="navbar-collapse" [class.collapse]="collapsed" (window:resize)="collapsed = true">
            <ul class="nav navbar-nav me-auto mb-2 mb-lg-0">
              <!-- <li class="nav-item">
                <a title="Renaissance Home page" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" aria-current="page" class="nav-link">HOME</a>
              </li> -->
              <li class="nav-item">
                <a routerLink="/about" routerLinkActive="active" title="About page" class="nav-link">ABOUT</a>
              </li>
              <li class="nav-item">
                <a  routerLink="/demo" routerLinkActive="active" title="Tutorial page" class="nav-link">TUTORIAL</a>
              </li>
              <li class="nav-item">
                <a routerLink="/faq" routerLinkActive="active" title="Frequently Asked Questions page" class="nav-link" href="#">FAQ</a>
              </li>
              <li class="nav-item"  *ngIf = 'isUserLoggedIn'>
                <a routerLink="/tabgeneral" routerLinkActive="active" title="New Project page" class="nav-link">NEW PROJECT</a>
              </li>
              <li class="nav-item"  *ngIf = 'isUserLoggedIn'>
                <a routerLink="/myprojects" routerLinkActive="active" title="My Projects page" class="nav-link">MY PROJECTS</a>
              </li>
              <li class="nav-item" *ngIf = '!isUserLoggedIn' >
                <a title="Login page" class="nav-link" routerLink="/login" routerLinkActive="active">LOGIN</a>
              </li>
              <li class="nav-item" *ngIf = '!isUserLoggedIn' >
                <a title="Signup page" class="nav-link" routerLink="/signup" routerLinkActive="active">SIGN UP</a>
              </li>
              <li class="nav-item" *ngIf = 'isUserLoggedIn'>
                <a title="Logout" class="nav-link" (click)="onLogout()" style="cursor: pointer;">LOGOUT</a>
              </li>
              <!--
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Dropdown
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li><hr class="dropdown-divider"></li>
                  <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
              </li>
              <li class="nav-item">
                <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
              </li>-->
            </ul>
          </div>
        </div>
      </nav>
</div>