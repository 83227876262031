<div id="ps_tab6" class="container bg-light">
  <app-project-name-label></app-project-name-label>
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <div class="row pt-4">
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabgeneral" routerLinkActive="active" title="General option"><img title="General Information" class= "img-fluid" src="assets/images/1x/btn_assetGeneral.png"></a></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabnew" routerLinkActive="active" title="New Asset option"><img title="New Asset" class= "img-fluid" src="assets/images/1x/btn_assetNew.png"></a></div>
        <div class="col-sm-6 d-grid mx-auto"></div>
      </div>    
      
      <div class="row">
          <div class="col-sm-1 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabmodify" routerLinkActive="active" title="Asset Modify option"><img title="Asset Modify" class= "img-fluid" src="assets/images/1x/btn_assetModify.png"></a></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/compare" routerLinkActive="active" title="Compare Projects"><img title="Compare Projects" class= "img-fluid" src= "assets/images/1x/btn_assetCompare.png"></a></div>
          <div class="col-sm-5 d-grid mx-auto"></div>
      </div> 
      
      <div class="row">
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/calculate" routerLinkActive="active" title="Calculate option"><img title="Calculate" class= "img-fluid" src="assets/images/1x/btn_calculate.png"></a></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
      </div> 
    </div>
    <div class="col-md-6 pc_orange">
      <div class="progress_position" *ngIf="!isCompleted">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <p>CALCULATING... {{ info }}</p>
      </div>
      <div class="row calculate_row" *ngIf="isCompleted">
        <h4 style="left: 25%; position: relative">Calculation Complete!</h4>
        <div class="col-sm-3 d-grid mx-auto"></div>
        <div class="col-md-6 d-grid mx-auto">
          <h5 style="color: #f47a5c">See the results:</h5>
          <div class="row">
            <div class="col-md-6 d-grid mx-auto">
              <a
                routerLink="/chart"
                routerLinkActive="active"
                title="Graphical Visualization option"
              >
                <img
                  title="Graphical Visualization"
                  class="img-fluid"
                  src="assets/images/1x/Graph Button1.png"
                />
              </a>
            </div>
            <div class="col-md-6 d-grid mx-auto">
              <a
                routerLink="/results"
                routerLinkActive="active"
                title="Total Results option"
              >
                <img
                  title="Total Results"
                  class="img-fluid"
                  src="assets/images/1x/Results Button1.png"
                />
              </a>
            </div>
          </div>
        </div>

        <div class="col-sm-3 d-grid mx-auto"></div>
      </div>
      <!-- <div class="row"></div> -->
      <!-- <div class="progressbar"> -->
      <!-- <div class="progressstatus"> -->
      <!-- <h1>Downloading...</h1> -->
      <!-- <mat-progress-bar mode="indeterminate" *ngIf="isCompleted"></mat-progress-bar> -->
      <!--<h1>Downloading... {{value}}</h1>
                    <div class="progressresult">
                        <mat-progress-bar mode="determinate" [value]="value"></mat-progress-bar>
                    </div>-->
      <!-- </div> -->
      <!-- </div> -->
    </div>
    <!-- <div class="col-md-6" *ngIf="!project">
      <h2>Calculate</h2>
      <h3 class="sel_prj_h3">Please click <a routerLink="/myprojects" routerLinkActive="active"
          title="My Projects">here</a> to select a
        project and continue.</h3>
    </div> -->
  </div>
</div>
