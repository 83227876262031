import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PrivacyPolicyComponent } from './0-privacy-policy/privacy-policy.component';
import { ProfileComponent } from './0-profile/profile.component';

import { SignupComponent } from './2-signup/signup.component';
import { LoginComponent } from './2-login/login.component';
import { DemoToolComponent } from './2-demo-tool/demo-tool.component';

import { NewprojectComponent } from './3-new-project/new-project.component';
import { MyprojectsComponent } from './3-myprojects/myprojects.component';
import { NewprojectaddComponent } from './3-new-project-add/new-project-add.component';

import { AddingDataFilesComponent } from './4-adding-data-files/adding-data-files.component';
import { AddingConfigFilesComponent } from './4-adding-config-files/adding-config-files.component';
import { AddingAssetGeneralComponent } from './4-general-information/general-information.component';
import { ModifyAssetComponent } from './4-modify-asset/modify-asset.component';
import { CalculateComponent } from './4-calculate/calculate.component';

import { FaqComponent } from './0-faq/faq.component';
import { AboutComponent } from './0-about/about.component';

import { AddingAssetNewComponent } from './4-new-asset-1-public-grid/4-new-asset-1-public-grid.component';
import { NewAsset2StorageComponent } from './4-new-asset-2-storage/new-asset2-storage.component';
import { NewAsset3GeneratorComponent } from './4-new-asset-3-generator/new-asset3-generator.component';
import { NewAsset4ChargerComponent } from './4-new-asset-4-charger/new-asset4-charger.component';
import { NewAsset5BuildingComponent } from './4-new-asset-5-building/new-asset5-building.component';
import { NewAsset6SolarComponent } from './4-new-asset-6-solar/new-asset6-solar.component';
import { NewAsset7WindComponent } from './4-new-asset-7-wind/new-asset7-wind.component';
import { StackColumnChartComponent } from './4-project-column-chart/stack-column-chart.component';
import { ProjectTotalResultsComponent } from './4-project-total-results/project-total-results.component';
import { ModifyAssetPublicGridComponent } from './4-modify-asset-1-public-grid/modify-asset-public-grid.component';
import { ModifyAssetStorageComponent } from './4-modify-asset-2-storage/modify-asset-storage.component';
import { ModifyAssetGeneratorComponent } from './4-modify-asset-3-generator/modify-asset-generator.component';
import { ModifyAssetChargerComponent } from './4-modify-asset-4-charger/modify-asset-charger.component';
import { ModifyAssetBuildingComponent } from './4-modify-asset-5-building/modify-asset-building.component';
import { ModifyAssetSolarComponent } from './4-modify-asset-6-solar/modify-asset-solar.component';
import { ModifyAssetWindComponent } from './4-modify-asset-7-wind/modify-asset-wind.component';
import { CompareComponent } from './4-compare-projects/compare.component';
import { CompareTotalResultsComponent } from './compare-total-results/compare-total-results.component';
import { LandingPageComponent } from './1-landing-page/landing-page.component';
import { CompareChartComponent } from './compare-chart/compare-chart.component';
import { AuthGuard } from './auth-guard';
import { VerifyEmailComponent } from './2-verify-email/verify-email.component';
import { ForgotPasswordComponent } from './2-forgot-password/forgot-password.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent},
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent},
  { path: 'verify-email', component: VerifyEmailComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'myprofile', component: ProfileComponent},
  
  { path: 'newproject', component: NewprojectComponent},
  { path: 'newprojectadd', component: NewprojectaddComponent},
  { path: 'myprojects', component: MyprojectsComponent, canActivate: [AuthGuard]},

  { path: 'tabgeneral', component: AddingAssetGeneralComponent,  canActivate: [AuthGuard]},
  // { path: 'tabimpdatafile', component: AddingDataFilesComponent, canActivate: [AuthGuard]},
  // { path: 'tabimpconfigfile', component: AddingConfigFilesComponent, canActivate: [AuthGuard]},
  { path: 'tabmodify', component: ModifyAssetComponent, canActivate: [AuthGuard]},
  { path: 'publicgrids', component: ModifyAssetPublicGridComponent, canActivate: [AuthGuard]},
  { path: 'batteries', component: ModifyAssetStorageComponent, canActivate: [AuthGuard]},
  { path: 'generators', component: ModifyAssetGeneratorComponent, canActivate: [AuthGuard]},
  { path: 'chargers', component: ModifyAssetChargerComponent, canActivate: [AuthGuard]},
  { path: 'consumers', component: ModifyAssetBuildingComponent, canActivate: [AuthGuard]},
  { path: 'solarpvs', component: ModifyAssetSolarComponent, canActivate: [AuthGuard]},
  { path: 'windturbines', component: ModifyAssetWindComponent, canActivate: [AuthGuard]},

  { path: 'calculate', component: CalculateComponent, canActivate: [AuthGuard]},
  { path: 'chart', component: StackColumnChartComponent, canActivate: [AuthGuard]},
  { path: 'results', component: ProjectTotalResultsComponent, canActivate: [AuthGuard]},
  { path: 'compare', component: CompareComponent, canActivate: [AuthGuard]},
  { path: 'compare-results', component: CompareTotalResultsComponent, canActivate: [AuthGuard]},
  { path: 'compare-chart', component: CompareChartComponent, canActivate: [AuthGuard]},

  { path: 'privacypolicy', component: PrivacyPolicyComponent},
  { path: 'faq', component: FaqComponent},
  { path: 'demo', component: DemoToolComponent},
  { path: 'about', component: AboutComponent},

  { path: 'tabnew', component: AddingAssetNewComponent, canActivate: [AuthGuard]},
  { path: 'tabstorage', component: NewAsset2StorageComponent, canActivate: [AuthGuard]},
  { path: 'tabgenerator', component: NewAsset3GeneratorComponent, canActivate: [AuthGuard]},
  { path: 'tabcharger', component: NewAsset4ChargerComponent, canActivate: [AuthGuard]},
  { path: 'tabbuilding', component: NewAsset5BuildingComponent, canActivate: [AuthGuard]},
  { path: 'tabsolar', component: NewAsset6SolarComponent, canActivate: [AuthGuard]},
  { path: 'tabwind', component: NewAsset7WindComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
