import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-duplicate-dialog-form',
  templateUrl: './duplicate-dialog-form.component.html',
  styleUrls: ['./duplicate-dialog-form.component.css']
})
export class DuplicateDialogFormComponent implements OnInit {
  projectName: string;
  projectNameControl = new FormControl('', [Validators.required]);

  constructor(public dialogRef: MatDialogRef<DuplicateDialogFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void { }

  onNoClick(): void {
    this.dialogRef.close();
    this.projectNameControl.reset('');
  }

}
