export class GlobalConstants {

    public static registeredUser: string = "";

    public static projectId: string = "";

    public static generalCoords: string = "";

    public static assetToModify: string = "";

    public static myprojects: string [];

    public static compareProjectId_1: string = "";
    public static compareProjectId_2: string = "";

    public static assetDescription: string = "Please insert energy data.";
    public static assetQuestion: string = "How do I create a new asset?";
    public static assetAnswer: string = "Each one of the above tabs represents a different energy asset. Simply navigate through the tabs, fill in the required information for every asset that you are interested and then press the ‘’Add Asset’’ button to include it into you configuration.";
    
}
