<div id="ps_tab7" class="container bg-light">
  <app-project-name-label></app-project-name-label>
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <div class="row pt-4">
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabgeneral" routerLinkActive="active" title="General option"><img title="General Information" class= "img-fluid" src="assets/images/1x/btn_assetGeneral.png"></a></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabnew" routerLinkActive="active" title="New Asset option"><img title="New Asset" class= "img-fluid" src="assets/images/1x/btn_assetNewSelected.png"></a></div>
        <div class="col-sm-6 d-grid mx-auto"></div>
      </div>    
      
      <div class="row">
          <div class="col-sm-1 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabmodify" routerLinkActive="active" title="Asset Modify option"><img title="Asset Modify" class= "img-fluid" src="assets/images/1x/btn_assetModify.png"></a></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/compare" routerLinkActive="active" title="Compare Projects"><img title="Compare Projects" class= "img-fluid" src= "assets/images/1x/btn_assetCompare.png"></a></div>
          <div class="col-sm-5 d-grid mx-auto"></div>
      </div> 
      
      <div class="row">
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/calculate" routerLinkActive="active" title="Calculate option"><img title="Calculate" class= "img-fluid" src="assets/images/1x/btn_calculate.png"></a></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
      </div> 
    </div>

    <div class="col-md-6 ps_form rounded ps_white">
      <form [formGroup]="windForm" (ngSubmit)="btnClickAddAsset()">
        <div class="form-group">
          <div class="row pt-4">
            <app-menu-input-data></app-menu-input-data>
            <h1>
              <img
                class="img-fluid ps_imgsize"
                title="Import Data Files"
                src="assets/images/1x/newAssetImage.png"
              />
              New asset: Wind Turbine
            </h1>
            <h2>{{ description }}</h2>
          </div>

          <div class="row">
            <!-- <div class="col-8 d-grid mx-auto"> -->
            <div class="ps_logo_green">{{ question }}</div>
            <div>{{ answer }}</div>
            <!-- </div> -->
          </div>

          <div class="row pt-2">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="asset_id"
                  name="asset_id"
                  id="asset_id"
                  #asset_id
                  type="text"
                  class="form-control ps_renergise_btn"
                  title="Asset name"
                  placeholder="Asset name"
                />
                <span
                  *ngIf="
                    !windForm.get('asset_id').valid &&
                    windForm.get('asset_id').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="windForm.get('asset_id').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="hub_height"
                  name="hub_height"
                  id="hub_height"
                  #hub_height
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="The height of the hub [m]"
                  placeholder="The height of the hub [m]"
                />
                <span
                  *ngIf="
                    !windForm.get('hub_height').valid &&
                    windForm.get('hub_height').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="windForm.get('hub_height').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 60.</span
                  >
                  <span
                    *ngIf="windForm.get('hub_height').errors['max']"
                    style="font-size: small"
                    >This parameter must be less than 120.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="rotor_diameter"
                  name="rotor_diameter"
                  id="rotor_diameter"
                  #rotor_diameter
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="The diameter of the rotor [m]"
                  placeholder="The diameter of the rotor [m]"
                />
                <span
                  *ngIf="
                    !windForm.get('rotor_diameter').valid &&
                    windForm.get('rotor_diameter').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="windForm.get('rotor_diameter').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 0.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="cut_off"
                  name="cut_off"
                  id="cut_off"
                  #cut_off
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="Maximum operational wind speed [m/s]"
                  placeholder="Maximum operational wind speed [m/s]"
                />
                <span
                  *ngIf="
                    !windForm.get('cut_off').valid &&
                    windForm.get('cut_off').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="windForm.get('cut_off').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 5.</span
                  >
                  <span
                    *ngIf="windForm.get('cut_off').errors['max']"
                    style="font-size: small"
                    >This parameter must be less than 30.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="model_capacity"
                  name="model_capacity"
                  id="model_capacity"
                  #model_capacity
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="Peak power of a single wind turbine [kW]"
                  placeholder="Peak power of a single wind turbine [kW]"
                />
                <span
                  *ngIf="
                    !windForm.get('model_capacity').valid &&
                    windForm.get('model_capacity').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="windForm.get('model_capacity').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 1000.</span
                  >
                  <span
                    *ngIf="windForm.get('model_capacity').errors['max']"
                    style="font-size: small"
                    >This parameter must be less than 5000.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="min_turbines"
                  name="min_turbines"
                  id="min_turbines"
                  #min_turbines
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="The minimum number of wind turbines to be installed [-]"
                  placeholder="The minimum number of wind turbines to be installed [-]"
                />
                <span
                  *ngIf="
                    !windForm.get('min_turbines').valid &&
                    windForm.get('min_turbines').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="windForm.get('min_turbines').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="windForm.get('min_turbines').errors['pattern']"
                    style="font-size: small"
                    >This parameter must be integer higher than 0.</span
                  >
                  <span
                    *ngIf="windForm.get('min_turbines').errors['max']"
                    style="font-size: small"
                    >This parameter must be less than the maximum
                    turbines.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="max_turbines"
                  name="max_turbines"
                  id="max_turbines"
                  #max_turbines
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="The maximum number of wind turbines to be installed [-]"
                  placeholder="The maximum number of wind turbines to be installed [-]"
                />
                <span
                  *ngIf="
                    !windForm.get('max_turbines').valid &&
                    windForm.get('max_turbines').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="windForm.get('max_turbines').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="windForm.get('max_turbines').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than the minimum
                    turbines.</span
                  >
                  <span
                    *ngIf="windForm.get('max_turbines').errors['pattern']"
                    style="font-size: small"
                    >This parameter must be integer higher than the minimum
                    turbines.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="capex"
                  name="capex"
                  id="capex"
                  #capex
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="Cost of buying a single wind turbine [€/WT]"
                  placeholder="Cost of buying a single wind turbine [€/WT]"
                />
                <span
                  *ngIf="
                    !windForm.get('capex').valid &&
                    windForm.get('capex').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="windForm.get('capex').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="windForm.get('capex').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 0.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="opex"
                  name="opex"
                  id="opex"
                  #opex
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="The yearly operational cost per wind turbine [€/WT/year]"
                  placeholder="The yearly operational cost per wind turbine [€/WT/year]"
                />
                <span
                  *ngIf="
                    !windForm.get('opex').valid && windForm.get('opex').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="windForm.get('opex').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="windForm.get('opex').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 0.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="lifetime"
                  name="lifetime"
                  id="lifetime"
                  #lifetime
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="The lifetime of the asset [years]"
                  placeholder="The lifetime of the asset [years]"
                />
                <span
                  *ngIf="
                    !windForm.get('lifetime').valid &&
                    windForm.get('lifetime').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="windForm.get('lifetime').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="windForm.get('lifetime').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 1.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <label
                  class="form-control ps_renergise_btn"
                  #power_profile_label
                  title="Power profile of a single wind turbine [kW]"
                  for="power_profile"
                  >Power profile of a single wind turbine [kW]</label
                >
                <input
                  formControlName="power_profile"
                  name="power_profile"
                  id="power_profile"
                  #power_profile
                  type="file"
                  class="ps_hidden form-control ps_renergise_btn"
                  title="Power profile of a single wind turbine [kW]"
                  placeholder="Power profile of a single wind turbine [kW]"
                  observeFiles
                  (change)="onChange($event)"
                />
              </form-field>
            </div>
            <div class="col-2 d-grid mx-auto">
              <div class="col">
                <fa-icon
                  title="Download empty template"
                  mat-raised-button
                  [icon]="faFile"
                  size="2x"
                  style="cursor: pointer; color: '#FCC78C'"
                  (click)="onDownloadWind()"
                ></fa-icon>
                <fa-icon title="Download example csv template" mat-raised-button [icon]="faFile" size="2x"
                  style="cursor: pointer; color: '#3babb0';margin-left: 4px;" (click)="onDownloadWindExample()"></fa-icon>
              </div>
            </div>
            <div class="col-2 d-grid mx-auto"></div>
          </div>
        </div>
      </form>

      <div class="row pt-1 pb-2">
        <div class="col-4 d-grid mx-auto">
          <button
            type="button"
            #clear_button
            title="Clear form"
            class="btn ps_renergise_btn"
            (click)="btnClickClear()"
          >
            Default
          </button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <button
            type="button"
            title="Add asset"
            class="btn ps_renergise_btn"
            (click)="btnClickAddAsset()"
            [disabled]="!windForm.valid"
          >
            Add Asset
          </button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <div *ngIf="show === 'success'" @fadeOut>
            <img
              class="submit_img"
              title="Added"
              src="assets/images/1x/baseline_task_alt_green_24dp.png"
              #submit_img
            />
          </div>
          <div *ngIf="show === 'failed'" @fadeOut>
            <img
              class="submit_img"
              title="Added"
              src="assets/images/1x/baseline_highlight_off_red_24dp.png"
              #submit_img
            />
          </div>
        </div>
      </div>
      <div class="row pt-1 pb-2">
        <div class="col-8 d-grid mx-auto">
          <span class="alert alert-warning" role="alert" *ngIf="setWarning">{{
            warning_msg
          }}</span>
        </div>
        <div class="col-4 d-grid mx-auto"></div>
      </div>
    </div>
  </div>
</div>
