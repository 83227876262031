import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'hammerjs';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

Chart.register(zoomPlugin);

@Component({
  selector: 'app-compare-chart',
  templateUrl: './compare-chart.component.html',
  styleUrls: ['./compare-chart.component.css']
})
export class CompareChartComponent implements OnInit {

  @ViewChild('canvas') canvas: ElementRef;

  chart: Chart;

  zoomInImg: string = "assets/images/1x/baseline_zoom_in_diactivated.png";
  zoomOutImg: string = "assets/images/1x/baseline_zoom_out_diactivated.png";

  labels = [];
  user = GlobalConstants.registeredUser;
  project_1 = GlobalConstants.compareProjectId_1;
  project_2 = GlobalConstants.compareProjectId_2;
  headerChartAssets1: string[];
  headerChartAssets2: string[];
  response_1: Object;
  response_2: Object;
  selected = 'daily';
  selectedValue1 = '';
  selectedValue2 = '';
  parameters: HttpParams;

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    console.log("Registered user?", GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;

    var host = "https://renergisetool.evergi.be/";
    if (this.user) {
      var url_1 = host + "users/" + this.user + "/projects/" + this.project_1 + "/result" + "/timeseries";

      var url_2 = host + "users/" + this.user + "/projects/" + this.project_2 + "/result" + "/timeseries";

      console.log("url1: " + url_1 + " url2 " + url_2);
      const params = new HttpParams(
        {
          fromObject: {
            frequency: this.selected
          }
        }
      )
      this.http.get<any>(url_1 + '?' + params.toString(), { observe: 'response' }).subscribe(
        response => {
          console.log("response: ", response);
          if (response['status'] === 200) {
            this.response_1 = response.body['data'];
            this.headerChartAssets1 = Object.keys(response.body['data']);
            var datetimeIndex = this.headerChartAssets1.indexOf('datetime')
            this.headerChartAssets1.splice(datetimeIndex, 1);
            if (response.body['data']['datetime']) {
              this.labels = response.body['data']['datetime'];
            }
            this.chart = new Chart(this.canvas.nativeElement.getContext('2d'), {
              type: 'bar',
              data: {
                labels: this.labels,
                datasets: []
              },
              options: {
                responsive: true,
                scales: {
                  x: {
                    title:{
                      display:true,
                      align: 'center',
                      text:'Time',
                      color:'#3babb0',
                      font:{
                        size:14
                      }
                    }
                  },
                  y: {
                    title:{
                      display:true,
                      align: 'center',
                      text:'Energy (kWh)',
                      color:'#3babb0',
                      font:{
                        size:14
                      }
                    }
                  },
                },
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
                plugins: {
                  zoom: {
                    zoom: {
                      wheel: {
                        enabled: true,
                        speed: 0.1
                      },
                      pinch: {
                        enabled: true
                      },
                      mode: 'x',
                    },
                    pan: {
                      enabled: true,
                      mode: 'xy',
                    },
                  },
                  title: {
                    text: " ",
                    display: true,
                    position: 'top'
                  }
                },

              }
            });
          }
        },
        error => {
          console.log("error: ", error);
          alert(error.error['warning'] + " for project " + this.project_1);
          this.router.navigateByUrl('/myprojects');
        }
      );
      this.http.get<any>(url_2 + '?' + params.toString(), { observe: 'response' }).subscribe(
        response => {
          console.log("response: ", response);
          if (response['status'] === 200) {
            this.response_2 = response.body['data'];
            this.headerChartAssets2 = Object.keys(response.body['data']);
            var datetimeIndex = this.headerChartAssets2.indexOf('datetime')
            this.headerChartAssets2.splice(datetimeIndex, 1);
          }
        },
        error => {
          console.log("error: ", error);
          alert(error.error['warning'] + " for project " + this.project_2);
          this.router.navigateByUrl('/myprojects');
        }
      );

    }
  }
  
  onOptionSelect(event) {
    console.log("option", event.source.value);
    var host = "https://renergisetool.evergi.be/";
    var url_1 = host + "users/" + this.user + "/projects/" + this.project_1 + "/result" + "/timeseries";
    var url_2 = host + "users/" + this.user + "/projects/" + this.project_2 + "/result" + "/timeseries";
    this.parameters = new HttpParams(
      {
        fromObject: {
          frequency: event.source.value
        }
      }
    )
    this.http.get<any>(url_1 + '?' + this.parameters.toString(), { observe: 'response' }).subscribe(
      response_1 => {
        console.log("response: ", response_1);
        if (response_1['status'] === 200) {
          this.response_1 = response_1.body['data'];
          this.headerChartAssets1 = Object.keys(response_1.body['data']);
          var datetimeIndex = this.headerChartAssets1.indexOf('datetime')
          this.headerChartAssets1.splice(datetimeIndex, 1);
          if (response_1.body['data']['datetime']) {
            var labels_temp = response_1.body['data']['datetime'];
            if (event.source.value === 'hourly') {
              for (var i = 0; i < labels_temp.length; i++) {
                var date_temp = new Date(labels_temp[i]),
                  month = '' + (date_temp.getMonth() + 1),
                  day = '' + date_temp.getDate(),
                  year = date_temp.getFullYear(),
                  hour = '' + date_temp.getHours() + 'h';
                if (month.length < 2)
                  month = '0' + month;
                if (day.length < 2)
                  day = '0' + day;
                this.labels[i] = [year, month, day, hour].join('-');
              }
            }
            else {
              this.labels = response_1.body['data']['datetime'];
            }
          }
          this.http.get<any>(url_2 + '?' + this.parameters.toString(), { observe: 'response' }).subscribe(
            response_2 => {
              console.log("response: ", response_2);
              if (response_2['status'] === 200) {
                this.response_2 = response_2.body['data'];
                this.headerChartAssets2 = Object.keys(response_2.body['data']);
                var datetimeIndex = this.headerChartAssets2.indexOf('datetime')
                this.headerChartAssets2.splice(datetimeIndex, 1);
                // this.labels = response_2.body['data']['datetime'];
                if (this.selectedValue1 && this.selectedValue2) {
                  this.chart["data"]["labels"] = this.labels;
                  this.chart["data"]["datasets"][0] = { data: this.response_1[this.selectedValue1], label: this.project_1 + '(' + this.selectedValue1 + ')', backgroundColor: '#3BABB0' };
                  this.chart["data"]["datasets"][1] = { data: this.response_2[this.selectedValue2], label: this.project_2 + '(' + this.selectedValue2 + ')', backgroundColor: '#F57A5C' };
                }
                this.chart.resetZoom();
                this.chart.update();
              }
            },
            error => {
              console.log("error: ", error);
              alert(error.error['warning'] + " for project " + this.project_2);
              this.router.navigateByUrl('/myprojects');
            }
          );
        }
      },
      error => {
        console.log("error: ", error);
        alert(error.error['warning'] + " for project 1" + this.project_1);
        this.router.navigateByUrl('/myprojects');
      }
    );

  }

  onSelect(event) {
    console.log("option 1", this.selectedValue1, "option 2", this.selectedValue2, event.source.value);
    if (this.selectedValue1 && this.selectedValue2) {
      if (this.chart['data']["labels"].length === 0) {
        this.chart['data']["labels"] = this.labels;
      }
      this.chart["data"]["datasets"][0] = { data: this.response_1[this.selectedValue1], label: this.project_1 + '(' + this.selectedValue1 + ')', backgroundColor: '#3BABB0' };
      this.chart["data"]["datasets"][1] = { data: this.response_2[this.selectedValue2], label: this.project_2 + '(' + this.selectedValue2 + ')', backgroundColor: '#F57A5C' };
      this.chart.update();
    }
  }

  resetZoom() {
    this.chart.resetZoom();
  }

  zoomOutOption() {
    this.chart.zoom({ x: -1.1 });
  }

  zoomInOption() {
    this.chart.zoom({ x: 1.1 });
  }

  changeZoomInImg() {
    this.zoomInImg = "assets/images/1x/baseline_zoom_in_white.png";
  }

  changeZoomOutImg() {
    this.zoomOutImg = "assets/images/1x/baseline_zoom_out_white.png";
  }

  changeZoomInImgReset() {
    this.zoomInImg = "assets/images/1x/baseline_zoom_in_diactivated.png";
  }

  changeZoomOutImgReset() {
    this.zoomOutImg = "assets/images/1x/baseline_zoom_out_diactivated.png";
  }

}
