<div id="ap_verifypage" class="container bg-light">
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <app-left-side-logo></app-left-side-logo>
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-4">
      <div class="row pt-1">
        <h3>Thank You for Registering!</h3>
        <!-- <div class="col" *ngIf="authService.userId"> -->
        <div style="text-align: justify;">
          <p>We have sent a confirmation email.</p>
          <p>Please check your email and click on the link to verfiy your email address.</p>
          <p>Check your spam folder.</p>
          <button title="Resend verification email" type="button" class="btn ps_renergise_btn" (click)="authService.SendVerificationMail()">Resend Verification Email</button>
        </div>

        <div class="row pt-1">
          <p>Go back to?<a routerLink="/login" title="Login page"> Login</a></p>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
