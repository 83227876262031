import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-input-data',
  templateUrl: './menu-input-data.component.html',
  styleUrls: ['./menu-input-data.component.css']
})
export class MenuInputDataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
