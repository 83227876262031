<div id="ps_modifyasset" class="container bg-light">
  <app-project-name-label></app-project-name-label>
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <div class="row pt-4">
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabgeneral" routerLinkActive="active" title="General option"><img title="General Information" class= "img-fluid" src="assets/images/1x/btn_assetGeneral.png"></a></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabnew" routerLinkActive="active" title="New Asset option"><img title="New Asset" class= "img-fluid" src="assets/images/1x/btn_assetNew.png"></a></div>
        <div class="col-sm-6 d-grid mx-auto"></div>
      </div>    
      
      <div class="row">
          <div class="col-sm-1 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabmodify" routerLinkActive="active" title="Asset Modify option"><img title="Asset Modify" class= "img-fluid" src="assets/images/1x/btn_assetModifySelected.png"></a></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/compare" routerLinkActive="active" title="Compare Projects"><img title="Compare Projects" class= "img-fluid" src= "assets/images/1x/btn_assetCompare.png"></a></div>
          <div class="col-sm-5 d-grid mx-auto"></div>
      </div> 
      
      <div class="row">
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/calculate" routerLinkActive="active" title="Calculate option"><img title="Calculate" class= "img-fluid" src="assets/images/1x/btn_calculate.png"></a></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
      </div> 
    </div>

    <!-- <div class="col-md-6" *ngIf="!project">
      <h2>My Assets</h2>
      <h3 class = "sel_prj_h3">Please click <a routerLink="/myprojects" routerLinkActive="active" title="My Projects">here</a> to select a
        project and continue.</h3>
    </div> -->
    <div class="col-md-6">
      <h2>My Assets</h2>
      <div class="row pt-4">
        <div class="col-sm-2 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            ><img
              id="publicgrids"
              title="Empty area for new Asset"
              class="img-fluid"
              src="assets/images/1x/btn_assetEmpty.png"
          /></a>
          <mat-select class="pc_select_asset">
            <mat-option *ngFor="let asset of assetIds[4]" [value]="asset">
              {{ asset.asset_name }}
              <a id="edit" (click)="onEdit(asset.asset_id, 'publicgrids')"
                ><img
                  title="Edit Grid"
                  class="img-fluid"
                  src="assets/images/1x/baseline_mode_edit_white_24dp.png"
              /></a>
              <a id="delete" (click)="openDialogDelete(asset.asset_name,asset.asset_id, 'publicgrids')"
                ><img
                  title="Delete Grid"
                  class="img-fluid"
                  src="assets/images/1x/baseline_delete_red_24dp.png"
              /></a>
            </mat-option>
          </mat-select>
        </div>
        <div class="col-sm-2 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            ><img
              id="batteries"
              title="Empty area for new Asset"
              class="img-fluid"
              src="assets/images/1x/btn_assetEmpty.png"
          /></a>
          <mat-select class="pc_select_asset" [(ngModel)]="asset">
            <mat-option *ngFor="let asset of assetIds[0]" [value]="asset">
              {{ asset.asset_name }}
              <a id="edit" (click)="onEdit(asset.asset_id, 'batteries')"
                ><img
                  title="Edit Battery"
                  class="img-fluid"
                  src="assets/images/1x/baseline_mode_edit_white_24dp.png"
              /></a>
              <a id="delete" (click)="openDialogDelete(asset.asset_name,asset.asset_id, 'batteries')"
                ><img
                  title="Delete Battery"
                  class="img-fluid"
                  src="assets/images/1x/baseline_delete_red_24dp.png"
              /></a>
            </mat-option>
          </mat-select>
        </div>

        <div class="col-sm-2 d-grid mx-auto"></div>
      </div>
      <div class="row">
        <div class="col-md-3 d-grid mx-auto">
          <a
            ><img
              id="generators"
              title="Empty area for new Asset"
              class="img-fluid"
              src="assets/images/1x/btn_assetEmpty.png"
          /></a>
          <mat-select class="pc_select_asset">
            <mat-option *ngFor="let asset of assetIds[3]" [value]="asset">
              {{ asset.asset_name }}
              <a id="edit" (click)="onEdit(asset.asset_id, 'generators')"
                ><img
                  title="Edit Generator"
                  class="img-fluid"
                  src="assets/images/1x/baseline_mode_edit_white_24dp.png"
              /></a>
              <a id="delete" (click)="openDialogDelete(asset.asset_name,asset.asset_id, 'generators')"
                ><img
                  title="Delete Generator"
                  class="img-fluid"
                  src="assets/images/1x/baseline_delete_red_24dp.png"
              /></a>
            </mat-option>
          </mat-select>
        </div>
        <div class="col-sm-1 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            ><img
              id="chargers"
              title="Empty area for new Asset"
              class="img-fluid"
              src="assets/images/1x/btn_assetEmpty.png"
          /></a>
          <mat-select class="pc_select_asset">
            <mat-option *ngFor="let asset of assetIds[1]" [value]="asset">
              {{ asset.asset_name }}
              <a id="edit" (click)="onEdit(asset.asset_id, 'chargers')"
                ><img
                  title="Edit Charger"
                  class="img-fluid"
                  src="assets/images/1x/baseline_mode_edit_white_24dp.png"
              /></a>
              <a id="delete" (click)="openDialogDelete(asset.asset_name,asset.asset_id, 'chargers')"
                ><img
                  title="Delete Charger"
                  class="img-fluid"
                  src="assets/images/1x/baseline_delete_red_24dp.png"
              /></a>
            </mat-option>
          </mat-select>
        </div>
        <div class="col-sm-1 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            ><img
              id="consumers"
              title="Empty area for new Asset"
              class="img-fluid"
              src="assets/images/1x/btn_assetEmpty.png"
          /></a>
          <mat-select class="pc_select_asset">
            <mat-option *ngFor="let asset of assetIds[2]" [value]="asset">
              {{ asset.asset_name }}
              <a id="edit" (click)="onEdit(asset.asset_id, 'consumers')"
                ><img
                  title="Edit Consumer"
                  class="img-fluid"
                  src="assets/images/1x/baseline_mode_edit_white_24dp.png"
              /></a>
              <a id="delete" (click)="openDialogDelete(asset.asset_name,asset.asset_id, 'consumers')"
                ><img
                  title="Delete Consumer"
                  class="img-fluid"
                  src="assets/images/1x/baseline_delete_red_24dp.png"
              /></a>
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            ><img
              id="solarpvs"
              title="Empty area for new Asset"
              class="img-fluid"
              src="assets/images/1x/btn_assetEmpty.png"
          /></a>
          <mat-select class="pc_select_asset">
            <mat-option *ngFor="let asset of assetIds[5]" [value]="asset">
              {{ asset.asset_name }}
              <a id="edit" (click)="onEdit(asset.asset_id, 'solarpvs')"
                ><img
                  title="Edit Solar PV"
                  class="img-fluid"
                  src="assets/images/1x/baseline_mode_edit_white_24dp.png"
              /></a>
              <a id="delete" (click)="openDialogDelete(asset.asset_name,asset.asset_id, 'solarpvs')"
                ><img
                  title="Delete Solar PV"
                  class="img-fluid"
                  src="assets/images/1x/baseline_delete_red_24dp.png"
              /></a>
            </mat-option>
          </mat-select>
        </div>
        <div class="col-sm-2 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            ><img
              id="windturbines"
              title="Empty area for new Asset"
              class="img-fluid"
              src="assets/images/1x/btn_assetEmpty.png"
          /></a>
          <mat-select class="pc_select_asset">
            <mat-option *ngFor="let asset of assetIds[6]" [value]="asset">
              {{ asset.asset_name }}
              <a id="edit" (click)="onEdit(asset.asset_id, 'windturbines')"
                ><img
                  title="Edit Wind Turbine"
                  class="img-fluid"
                  src="assets/images/1x/baseline_mode_edit_white_24dp.png"
              /></a>
              <a id="delete" (click)="openDialogDelete(asset.asset_name,asset.asset_id, 'windturbines')"
                ><img
                  title="Delete Wind Turbine"
                  class="img-fluid"
                  src="assets/images/1x/baseline_delete_red_24dp.png"
              /></a>
            </mat-option>
          </mat-select>
        </div>
        <div class="col-sm-2 d-grid mx-auto"></div>
      </div>
    </div>
  </div>
</div>
