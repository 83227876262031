import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'hammerjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { interval, Subscription, timer } from 'rxjs';
import { delay, delayWhen, map, repeat, retryWhen, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';


Chart.register(zoomPlugin);
interface dropDownItemObject {
  item_id: number; 
  item_text: string;
}
@Component({
  selector: 'app-stack-column-chart',
  templateUrl: './stack-column-chart.component.html',
  styleUrls: ['./stack-column-chart.component.css']
})
export class StackColumnChartComponent implements OnInit {

  labels: any[] = [];
  hidden = false;
  headerChartAssets: string[];
  response: Object;

  project = GlobalConstants.projectId;
  user = GlobalConstants.registeredUser;
  chart: Chart;
  zoomInImg: string = "assets/images/1x/baseline_zoom_in_diactivated.png";
  zoomResetImg: string = "assets/images/1x/baseline_restart_alt_white_diactivated.png";
  zoomOutImg: string = "assets/images/1x/baseline_zoom_out_diactivated.png";

  showSpinner: boolean = true;
  setWarning: boolean = false;
  warning_msg = '';

  @ViewChild('canvas') canvas: ElementRef;

  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  selectedItems: Object[] = [];
  selected = [{ item_id: 0, item_text: 'monthly' }];
  dropdownSettings1: IDropdownSettings;
  dropdownSettings2: IDropdownSettings;
  dropdownList: dropDownItemObject[];
  backgroudColors: string[] = [];

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    
    var host = "https://renergisetool.evergi.be/";
    var url = host + "users/" + this.user + "/projects/" + this.project + "/result" + "/timeseries";
    const params = new HttpParams(
      {
        fromObject: {
          frequency: 'monthly'
        }
      }
    )
    console.log("Chart refreshed", "url=> " + url);
    this.http.get<any>(url + '?' + params.toString(), { observe: 'response' }).subscribe(
      response => {
        console.log("response: ", response);
        if (response['status'] === 200) {
          this.showSpinner = false;
          this.response = response.body['data'];
          this.headerChartAssets = Object.keys(this.response);
          var datetimeIndex = this.headerChartAssets.indexOf('datetime')
          this.headerChartAssets.splice(datetimeIndex, 1);

          for (var i = 0; i < this.headerChartAssets.length; i++) {
            var r= Math.floor(Math.random()*((250 - 48) + 48));
            var g= Math.floor(Math.random()*((207 - 80) + 80));
            var b= Math.floor(Math.random()*((201- 80) + 80));
            var hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
            this.backgroudColors.push(hex);
            if (i === 0) {
              this.dropdownList = [{ item_id: i, item_text: this.headerChartAssets[i]}];
            }
            else {
              this.dropdownList.push({ item_id: i, item_text: this.headerChartAssets[i] });
            }
          }
          console.log("backgroudColors =>", this.backgroudColors);
          this.dropdownSettings1 = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: false,
            enableCheckAll: false
          };
          this.dropdownSettings2 = {
            singleSelection: true,
            idField: 'item_id',
            textField: 'item_text',
            itemsShowLimit: 1,
            allowSearchFilter: false,
            enableCheckAll: false
          };
          if (this.response['datetime']) {
            var labels_temp = this.response['datetime'];
            for (var i = 0; i < labels_temp.length; i++) {
              var date_temp = new Date(labels_temp[i]),
                month = '' + (date_temp.getMonth() + 1),
                year = date_temp.getFullYear()
              if (month.length < 2)
                month = '0' + month;
              this.labels[i] = [year, month].join('-');
            }
          }
          this.chart = new Chart(this.canvas.nativeElement.getContext('2d'), {
            type: 'bar',
            data: {
              labels: this.labels,
              datasets: []
            },
            options: {
              responsive: true,
              scales: {

                x: {
                  stacked: true,
                  title:{
                    display:true,
                    align: 'center',
                    text:'Time',
                    color:'#3babb0',
                    font:{
                      size:14
                    }
                  }
                },
                y: {
                  stacked: true,
                  title:{
                    display:true,
                    align: 'center',
                    text:'Energy (kWh)',
                    color:'#3babb0',
                    font:{
                      size:14
                    }
                  }
                },
                // onHover: (e, chart) => {event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';}
              },
              interaction: {
                mode: 'index',
                intersect: false,
              },
              plugins: {
                zoom: {
                  zoom: {
                    wheel: {
                      enabled: true,
                      speed: 0.4
                    },
                    pinch: {
                      enabled: true
                    },
                    mode: 'x',
                  },
                  pan: {
                    enabled: true,
                    mode: 'xy',
                  },
                }
              }
            },

          });

        }
      },
      error => {
        console.log("error: " , error);
      }

    )
  }

  onItemSelect(item: any) {
    console.log("ITEM COLOR=> ", item,this.backgroudColors[item["item_id"]]);
    this.chart["data"]["datasets"].push({
      label: item["item_text"],
      data: this.response[item["item_text"]].map(Number),
      backgroundColor: this.backgroudColors[item["item_id"]],
      hidden: this.hidden
    });
    this.chart.update();
  }

  onDeSelect(item: any) {
    var objToremove = { label: item["item_text"] }
    let removalIndex: number;
    this.chart["data"]["datasets"].forEach((element, index) => {
      if (element["label"] == objToremove["label"]) {
        removalIndex = index;
      }
    });
    if (removalIndex >= 0) {
      this.chart["data"]["datasets"].splice(removalIndex, 1);
    }
    this.chart.update();
  }

  onOptionSelect(item: any) {
    console.log("Option select", item["item_text"], item)
    var host = "https://renergisetool.evergi.be/";
    var url = host + "users/" + this.user + "/projects/" + this.project + "/result" + "/timeseries";
    const params = new HttpParams(
      {
        fromObject: {
          frequency: item["item_text"]
        }
      }
    )
    this.http.get<any>(url + '?' + params.toString(), { observe: 'response' }).subscribe(
      response => {
        console.log("response: " , response , "Available assets" , Object.keys(this.response));
        if (response['status'] === 200) {
          this.showSpinner = false;
          this.response = response.body['data'];
          if (this.response['datetime']) {
            var labels_temp = this.response['datetime'];
            if (item["item_text"] === 'hourly') {
              for (var i = 0; i < labels_temp.length; i++) {
                var date_temp = new Date(labels_temp[i]),
                  month = '' + (date_temp.getMonth() + 1),
                  day = '' + date_temp.getDate(),
                  year = date_temp.getFullYear(),
                  hour = '' + date_temp.getHours() + 'h';
                if (month.length < 2)
                  month = '0' + month;
                if (day.length < 2)
                  day = '0' + day;
                this.labels[i] = [year, month, day, hour].join('-');
              }
            }
            else{
              this.labels = this.response['datetime'];
            }
          }
          this.chart["data"]["labels"] = this.labels;
          this.chart["data"]["datasets"] = [];
          this.chart.update();
          this.chart.resetZoom();
          for (var i = 0; i < this.selectedItems.length; i++) {
            this.chart["data"]["datasets"].push({
              label: this.selectedItems[i]["item_text"],
              data: this.response[this.selectedItems[i]["item_text"]].map(Number),
              backgroundColor: this.backgroudColors[ this.selectedItems[i]["item_id"]],
              hidden: this.hidden
            });
          }
          this.chart.update();
        }
      },
      error => {
        console.log("error: " + error);
      }
    )

  }

  resetZoom() {
    this.chart.resetZoom();
  }

  zoomOutOption() {
    this.chart.zoom({ x: -1.9 });
  }

  zoomInOption() {
    this.chart.zoom({ x: 1.9 });
  }

  changeZoomInImg() {
    this.zoomInImg = "assets/images/1x/baseline_zoom_in_white.png";
  }

  changeZoomResetImg() {
    this.zoomResetImg = "assets/images/1x/baseline_restart_alt_white.png";
  }

  changeZoomOutImg() {
    this.zoomOutImg = "assets/images/1x/baseline_zoom_out_white.png";
  }

  changeZoomInImgReset() {
    this.zoomInImg = "assets/images/1x/baseline_zoom_in_diactivated.png";
  }

  changeZoomResetImgReset() {
    this.zoomResetImg = "assets/images/1x/baseline_restart_alt_white_diactivated.png";
  }

  changeZoomOutImgReset() {
    this.zoomOutImg = "assets/images/1x/baseline_zoom_out_diactivated.png";
  }
}
