import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { GlobalConstants } from '../common/global-constants';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    if(GlobalConstants.registeredUser === ''){
      GlobalConstants.registeredUser= localStorage.getItem("USERNAME");
      // console.log("Registered user",GlobalConstants.registeredUser);
      if(GlobalConstants.registeredUser !== '' && GlobalConstants.registeredUser !== null){
        this.authService.isUserLoggedIn.next(true);
      }
    }
  }

}
