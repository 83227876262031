<div id="ps_myprojects" class="container bg-light">
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <app-user-main-menu></app-user-main-menu>
    </div>
    <div class="col-md-6 ps_form rounded ps_white" id = "projects-list">
      <h2>My Projects</h2>
      <div *ngIf=!projectsExist>
        <h3>Your projects will appear here. Start creating a new project if you do not have one yet.</h3>
      </div>
      <div *ngIf = projectsExist>
        <h3>Please select a project.</h3>
        <table id="myprejects-table" class="myprojects-table" >
          <tr class="table-row" *ngFor = "let projectId of projects; let i=index;"  (mouseover)="show(i)" (mouseleave)="hide(i)">
              <td class="table-col">
                <button class="btn ps_renergise_btn ap_spacing ap_projects_list" title = {{projectId}}  (click) = "onSelect(projectId)">
                  {{projectId}}
                </button>
              </td>
              <td class="table-col" *ngIf = "isShown[i]">
                <button *ngIf = "isShown[i]" class="btn ps_renergise_btn ap_spacing ap_hidden_buttons" name="My assets/ Modify asset" routerLink="/tabmodify" (click) = "onSelect(projectId)">My Assets</button>
              </td>
              <!-- <td class="table-col" *ngIf = "isShown[i]">
                <button *ngIf = "isShown[i]" class="btn ps_renergise_btn ap_spacing ap_hidden_buttons" name="Add asset" routerLink="/tabnew" (click) = "onSelect(projectId)">Add assets</button>
              </td> -->
              <td class="table-col" *ngIf = "isShown[i]">
                <button class="btn ps_renergise_btn ap_spacing ap_hidden_buttons"  name="Duplicate" (click)="openDialogDuplicate(projectId)">Duplicate</button>
              </td>
              <td class="table-col" *ngIf = "isShown[i]">
                <button *ngIf = "isShown[i]" class="btn ps_renergise_btn ap_spacing ap_delete_button" name="Delete" (click) = "openDialogDelete(projectId)">Delete</button>
              </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>