import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { GlobalConstants } from '../common/global-constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  collapsed = true;
  isUserLoggedIn : boolean;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    // Subscribe here, this will automatically update 
    // "isUserLoggedIn" whenever a change to the subject is made.
    this.authService.isUserLoggedIn.subscribe( value => {
      console.log('isUserLoggedIn', value);
        this.isUserLoggedIn = value;
    });
  }

  onLogout(){
    this.authService.LogOut();
  }
}
