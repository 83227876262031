<div id="ps_tab6" class="container bg-light">
    <div class="row ps_padding_20">
        <div class="col-md-6">
            <div class="row">
                <h4 class="spacing">Total Results: <span  class = "ap_table_title">{{project_1}}</span></h4>
                <table id="result-table" class="result-table" >
                    <tr class="table-row row1" *ngFor = 'let result of projectResults_1'>
                        <th class="table-header">{{result.name}}</th>
                        <td class="table-data data_neutral" id = {{result.name}}_{{project_1}}>{{result.value}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-md-6" *ngIf = "user">
            <div class="row">
                <h4 class="spacing">Total Results: <span  class = "ap_table_title">{{project_2}}</span></h4>
                <table id="result-table" class="result-table" >
                    <tr class="table-row row2" *ngFor = 'let result of projectResults_2'>
                        <th class="table-header">{{result.name}}</th>
                        <td class="table-data data_neutral"  id = {{result.name}}_{{project_2}}>{{result.value}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row spacing" >
            <div class="col ap_button_centered">
                <button type="button" class="btn ps_renergise_btn ap_spacing" routerLink="/compare" routerLinkActive="active" title ="Back to compare">Back</button>
                <button type="button" class="btn ps_renergise_btn ap_spacing " routerLink="/compare-chart" routerLinkActive="active" title ="Chart">Chart</button>
            </div>
        </div>
    </div>
</div>



