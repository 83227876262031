<div id="ps_tab4" class="container bg-light">
  <app-project-name-label></app-project-name-label>
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <div class="row pt-4">
        <div class="col-md-3 d-grid mx-auto">
          <a
            routerLink="/tabgeneral"
            routerLinkActive="active"
            title="General option"
            ><img
              title="General Information"
              class="img-fluid"
              src="assets/images/1x/btn_assetGeneral.png"
          /></a>
        </div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            routerLink="/tabnew"
            routerLinkActive="active"
            title="New Asset option"
            ><img
              title="New Asset"
              class="img-fluid"
              src="assets/images/1x/btn_assetNewSelected.png"
          /></a>
        </div>
        <div class="col-sm-6 d-grid mx-auto"></div>
      </div>

      <div class="row">
        <div class="col-sm-1 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            routerLink="/tabmodify"
            routerLinkActive="active"
            title="Asset Modify option"
            ><img
              title="Asset Modify"
              class="img-fluid"
              src="assets/images/1x/btn_assetModify.png"
          /></a>
        </div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            routerLink="/compare"
            routerLinkActive="active"
            title="Compare Projects"
            ><img
              title="Compare Projects"
              class="img-fluid"
              src="assets/images/1x/btn_assetCompare.png"
          /></a>
        </div>
        <div class="col-sm-5 d-grid mx-auto"></div>
      </div>

      <div class="row">
        <div class="col-sm-2 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto">
          <a
            routerLink="/calculate"
            routerLinkActive="active"
            title="Calculate option"
            ><img
              title="Calculate"
              class="img-fluid"
              src="assets/images/1x/btn_calculate.png"
          /></a>
        </div>
        <div class="col-sm-2 d-grid mx-auto"></div>
        <div class="col-sm-2 d-grid mx-auto"></div>
      </div>
    </div>

    <div class="col-md-6 ps_form rounded ps_white">
      <form [formGroup]="chargerForm" (ngSubmit)="btnClickAddAsset()">
        <div class="form-group">
          <div class="row pt-4">
            <app-menu-input-data></app-menu-input-data>
            <h1>
              <img
                class="img-fluid ps_imgsize"
                title="Import Data Files"
                src="assets/images/1x/newAssetImage.png"
              />
              New asset: Charger
            </h1>
            <h2>{{ description }}</h2>
          </div>

          <div class="row">
            <!-- <div class="col-8 d-grid mx-auto"> -->
            <div class="ps_logo_green">{{ question }}</div>
            <div>{{ answer }}</div>
            <!-- </div> -->
            <!-- <div class="col-4 d-grid mx-auto"> -->
            <!--<form-field appearance="fill">
                                <mat-slide-toggle title="Advanced options" (change)="toggleShow()">Advanced</mat-slide-toggle>                        
                            </form-field>-->
            <!-- </div> -->
          </div>

          <div class="row pt-2">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="asset_id"
                  name="asset_id"
                  id="asset_id"
                  #asset_id
                  type="text"
                  class="form-control ps_renergise_btn"
                  title="Asset name"
                  placeholder="Asset name"
                />
                <span
                  *ngIf="
                    !chargerForm.get('asset_id').valid &&
                    chargerForm.get('asset_id').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="chargerForm.get('asset_id').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="time_window"
                  name="time_window"
                  id="time_window"
                  #time_window
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="The time window of when it must be charged [h]"
                  placeholder="The time window of when it must be charged [h]"
                />
                <span
                  *ngIf="
                    !chargerForm.get('time_window').valid &&
                    chargerForm.get('time_window').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="chargerForm.get('time_window').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <!-- <span *ngIf="chargerForm.get('time_window').errors['min']" style="font-size:small">This parameter must be integer higher than 0.</span> -->
                  <span
                    *ngIf="chargerForm.get('time_window').errors['pattern']"
                    style="font-size: small"
                    >This parameter must be integer higher than 0.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input
                  formControlName="max_power"
                  name="max_power"
                  id="max_power"
                  #max_power
                  type="number"
                  class="form-control ps_renergise_btn"
                  title="The max power the charger can provide [kW]"
                  placeholder="The max power the charger can provide [kW]"
                />
                <span
                  *ngIf="
                    !chargerForm.get('max_power').valid &&
                    chargerForm.get('max_power').touched
                  "
                  class="help-block"
                >
                  <span
                    *ngIf="chargerForm.get('max_power').errors['required']"
                    style="font-size: small"
                    >This field is required.</span
                  >
                  <span
                    *ngIf="chargerForm.get('max_power').errors['min']"
                    style="font-size: small"
                    >This parameter must be higher than 0.</span
                  >
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <label
                  class="form-control ps_renergise_btn"
                  #powerprofile_label
                  title="Energy to be provided within the given time window [kWh]"
                  for="powerprofile"
                  >Energy to be provided within the given time window
                  [kWh]</label
                >
                <input
                  formControlName="powerprofile"
                  name="powerprofile"
                  id="powerprofile"
                  #powerprofile
                  type="file"
                  class="ps_hidden form-control ps_renergise_btn"
                  title="Energy to be provided within the given time window [kWh]"
                  placeholder="Energy to be provided within the given time window [kWh]"
                  observeFiles
                  (change)="onChange($event)"
                />
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto">
              <div class="col">
                <fa-icon
                  title="Download empty template"
                  mat-raised-button
                  [icon]="faFile"
                  size="2x"
                  style="cursor: pointer; color: '#FCC78C'"
                  (click)="onDownloadCharger()"
                ></fa-icon>
                <fa-icon
                  title="Download example csv template"
                  mat-raised-button
                  [icon]="faFile"
                  size="2x"
                  style="cursor: pointer; color: '#3babb0'; margin-left: 4px"
                  (click)="onDownloadChargerExample()"
                ></fa-icon>
                <span
                  class="required_msg"
                  *ngIf="chargerForm.controls.powerprofile.errors"
                  >File is required</span
                >
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="row pt-1 pb-2">
        <div class="col-4 d-grid mx-auto">
          <button
            type="button"
            #clear_button
            title="Clear form"
            class="btn ps_renergise_btn"
            (click)="btnClickClear()"
          >
            Default
          </button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <button
            type="button"
            title="Add asset"
            class="btn ps_renergise_btn"
            (click)="btnClickAddAsset()"
            [disabled]="!chargerForm.valid"
          >
            Add Asset
          </button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <div *ngIf="show === 'success'" @fadeOut>
            <img
              class="submit_img"
              title="Added"
              src="assets/images/1x/baseline_task_alt_green_24dp.png"
              #submit_img
            />
          </div>
          <div *ngIf="show === 'failed'" @fadeOut>
            <img
              class="submit_img"
              title="Added"
              src="assets/images/1x/baseline_highlight_off_red_24dp.png"
              #submit_img
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-1 pb-2">
      <div class="col-8 d-grid mx-auto">
        <span class="alert alert-warning" role="alert" *ngIf="setWarning">{{
          warning_msg
        }}</span>
      </div>
      <div class="col-4 d-grid mx-auto"></div>
    </div>
  </div>
</div>
