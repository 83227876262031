import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { GlobalConstants } from '../common/global-constants';

export interface projectResult {
  name : string;
  value : number;
}

@Component({
  selector: 'app-compare-total-results',
  templateUrl: './compare-total-results.component.html',
  styleUrls: ['./compare-total-results.component.css']
})
export class CompareTotalResultsComponent implements OnInit {
  project_1 = GlobalConstants.compareProjectId_1;
  project_2 = GlobalConstants.compareProjectId_2;
  user = GlobalConstants.registeredUser;
  projectResults_1: projectResult [] = [];
  projectResults_2: projectResult [] = [];

  calculatedDone_1 = true;
  calculatedDone_2 = true;

  constructor(private http: HttpClient, private router: Router , private authService: AuthService) { }

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    
    if(this.project_1 && this.calculatedDone_1){
      var host = "https://renergisetool.evergi.be/";
      var url = host + "users/" + this.user + "/projects/"+ this.project_1 + "/result/"+ "/total";
      console.log("url " + url);
      this.http.get<any>(url, {observe: 'response'}).subscribe(
        response => {
          console.log("response: ", response);
          if(response['status'] === 200){
            this.projectResults_1.push({name: "Total Battery capacity (kWh)", value: +(+response.body['data']["ESS capacity (kWh)"]).toFixed(3)});
            this.projectResults_1.push({name: "Total Gas Turbine peak power (kW)", value: +(+response.body['data']["Generator capacity (kWp)"]).toFixed(3)});
            this.projectResults_1.push({name: "Total Solar PV peak power (kW)", value: +(+response.body['data']["PV capacity (kWp)"]).toFixed(3)});
            this.projectResults_1.push({name: "Total Wind Turbines (WT)", value: +(+response.body['data']["WT capacity (WT)"]).toFixed(3)});
            this.projectResults_1.push({name: "Electricity export (kWh)", value: +(+response.body['data']["Electricity export (kWh)"]).toFixed(3)});
            this.projectResults_1.push({name: "Electricity import (kWh)", value: +(+response.body['data']["Electricity import (kWh)"]).toFixed(3)});
            this.projectResults_1.push({name: "Self-consumption ratio (%)", value: +(+response.body['data']["Self-consumption ratio (%)"]).toFixed(3)});
            this.projectResults_1.push({name: "Self-sufficiency ratio (%)", value: +(+response.body['data']["Self-sufficiency ratio (%)"]).toFixed(3)});
            this.projectResults_1.push({name: "Total cost (kEUR)", value: +(+response.body['data']["Total cost (kEUR)"]).toFixed(3)});
          }
        },
        error=>{
          console.log("error: " + error);
          alert(error.error['warning']+" for project "+ this.project_1);
          this.router.navigateByUrl('/myprojects');
        }
      );
    }
    if(this.project_2 && this.calculatedDone_2){
      var host = "https://renergisetool.evergi.be/";
      var url = host + "users/" + this.user + "/projects/"+ this.project_2 + "/result/"+ "/total";
      console.log("url " + url);
      this.http.get<any>(url, {observe: 'response'}).subscribe(
        response => {
          console.log("response: ", response);
          if(response['status'] === 200){
            this.projectResults_2.push({name: "Total Battery capacity (kWh)", value: +(+response.body['data']["ESS capacity (kWh)"]).toFixed(3)});
            this.projectResults_2.push({name: "Total Gas Turbine peak power (kW)", value: +(+response.body['data']["Generator capacity (kWp)"]).toFixed(3)});
            this.projectResults_2.push({name: "Total Solar PV peak power (kW)", value: +(+response.body['data']["PV capacity (kWp)"]).toFixed(3)});
            this.projectResults_2.push({name: "Total Wind Turbines (WT)", value: +(+response.body['data']["WT capacity (WT)"]).toFixed(3)});
            this.projectResults_2.push({name: "Electricity export (kWh)", value: +(+response.body['data']["Electricity export (kWh)"]).toFixed(3)});
            this.projectResults_2.push({name: "Electricity import (kWh)", value: +(+response.body['data']["Electricity import (kWh)"]).toFixed(3)});
            this.projectResults_2.push({name: "Self-consumption ratio (%)", value: +(+response.body['data']["Self-consumption ratio (%)"]).toFixed(3)});
            this.projectResults_2.push({name: "Self-sufficiency ratio (%)", value: +(+response.body['data']["Self-sufficiency ratio (%)"]).toFixed(3)});
            this.projectResults_2.push({name: "Total cost (kEUR)", value: +(+response.body['data']["Total cost (kEUR)"]).toFixed(3)});
          }
        }, 
        error=>{
          console.log("error: " + error);
          alert(error.error['warning']+" for project "+this.project_2);
          this.router.navigateByUrl('/myprojects');
        }
      );
    }
    setTimeout(() => {
      this.setBackroundColor();
    }, 1000);
  }

  setBackroundColor(){
    if (this.projectResults_1[0]['value'] > this.projectResults_2[0]['value']){
      document.getElementById(this.projectResults_1[0]['name']+"_"+this.project_1).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[0]['name']+"_"+this.project_2).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[0]['name']+"_"+this.project_1).style.color = '#3baab0';
      document.getElementById(this.projectResults_1[0]['name']+"_"+this.project_2).style.color = '#f47a5c';
    }
    else if(this.projectResults_1[0]['value'] < this.projectResults_2[0]['value']){
      document.getElementById(this.projectResults_1[0]['name']+"_"+this.project_1).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[0]['name']+"_"+this.project_2).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[0]['name']+"_"+this.project_1).style.color = '#f47a5c';
      document.getElementById(this.projectResults_1[0]['name']+"_"+this.project_2).style.color = '#3baab0';
    }
    if (this.projectResults_1[1]['value'] > this.projectResults_2[1]['value']){
      document.getElementById(this.projectResults_1[1]['name']+"_"+this.project_1).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[1]['name']+"_"+this.project_2).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[1]['name']+"_"+this.project_1).style.color = '#3baab0';
      document.getElementById(this.projectResults_1[1]['name']+"_"+this.project_2).style.color = '#f47a5c';
    }
    else if(this.projectResults_1[1]['value'] < this.projectResults_2[1]['value']){
      document.getElementById(this.projectResults_1[1]['name']+"_"+this.project_1).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[1]['name']+"_"+this.project_2).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[1]['name']+"_"+this.project_1).style.color = '#f47a5c';
      document.getElementById(this.projectResults_1[1]['name']+"_"+this.project_2).style.color = '#3baab0';
    }
    if (this.projectResults_1[2]['value'] > this.projectResults_2[2]['value']){
      document.getElementById(this.projectResults_1[2]['name']+"_"+this.project_1).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[2]['name']+"_"+this.project_2).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[2]['name']+"_"+this.project_1).style.color = '#3baab0';
      document.getElementById(this.projectResults_1[2]['name']+"_"+this.project_2).style.color = '#f47a5c';
    }
    else if(this.projectResults_1[2]['value'] < this.projectResults_2[2]['value']){
      document.getElementById(this.projectResults_1[2]['name']+"_"+this.project_1).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[2]['name']+"_"+this.project_2).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[2]['name']+"_"+this.project_1).style.color = '#f47a5c';
      document.getElementById(this.projectResults_1[2]['name']+"_"+this.project_2).style.color = '#3baab0';
    }
    if (this.projectResults_1[3]['value'] > this.projectResults_2[3]['value']){
      document.getElementById(this.projectResults_1[3]['name']+"_"+this.project_1).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[3]['name']+"_"+this.project_2).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[3]['name']+"_"+this.project_1).style.color = '#3baab0';
      document.getElementById(this.projectResults_1[3]['name']+"_"+this.project_2).style.color = '#f47a5c';
    }
    else if(this.projectResults_1[3]['value'] < this.projectResults_2[3]['value']){
      document.getElementById(this.projectResults_1[3]['name']+"_"+this.project_1).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[3]['name']+"_"+this.project_2).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[3]['name']+"_"+this.project_1).style.color = '#f47a5c';
      document.getElementById(this.projectResults_1[3]['name']+"_"+this.project_2).style.color = '#3baab0';
    }
    if (this.projectResults_1[4]['value'] > this.projectResults_2[4]['value']){
      document.getElementById(this.projectResults_1[4]['name']+"_"+this.project_1).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[4]['name']+"_"+this.project_2).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[4]['name']+"_"+this.project_1).style.color = '#3baab0';
      document.getElementById(this.projectResults_1[4]['name']+"_"+this.project_2).style.color = '#f47a5c';
    }
    else if(this.projectResults_1[4]['value'] < this.projectResults_2[4]['value']){
      document.getElementById(this.projectResults_1[4]['name']+"_"+this.project_1).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[4]['name']+"_"+this.project_2).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[4]['name']+"_"+this.project_1).style.color = '#f47a5c';
      document.getElementById(this.projectResults_1[4]['name']+"_"+this.project_2).style.color = '#3baab0';
    }
    if (this.projectResults_1[5]['value'] < this.projectResults_2[5]['value']){
      document.getElementById(this.projectResults_1[5]['name']+"_"+this.project_1).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[5]['name']+"_"+this.project_2).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[5]['name']+"_"+this.project_1).style.color = '#3baab0';
      document.getElementById(this.projectResults_1[5]['name']+"_"+this.project_2).style.color = '#f47a5c';
    }
    else if(this.projectResults_1[5]['value'] > this.projectResults_2[5]['value']){
      document.getElementById(this.projectResults_1[5]['name']+"_"+this.project_1).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[5]['name']+"_"+this.project_2).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[5]['name']+"_"+this.project_1).style.color = '#f47a5c';
      document.getElementById(this.projectResults_1[5]['name']+"_"+this.project_2).style.color = '#3baab0';
    }
    if (this.projectResults_1[6]['value'] > this.projectResults_2[6]['value']){
      document.getElementById(this.projectResults_1[6]['name']+"_"+this.project_1).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[6]['name']+"_"+this.project_2).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[6]['name']+"_"+this.project_1).style.color = '#3baab0';
      document.getElementById(this.projectResults_1[6]['name']+"_"+this.project_2).style.color = '#f47a5c';
    }
    else if(this.projectResults_1[6]['value'] < this.projectResults_2[6]['value']){
      document.getElementById(this.projectResults_1[6]['name']+"_"+this.project_1).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[6]['name']+"_"+this.project_2).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[6]['name']+"_"+this.project_1).style.color = '#f47a5c';
      document.getElementById(this.projectResults_1[6]['name']+"_"+this.project_2).style.color = '#3baab0';
    } 
    if (this.projectResults_1[7]['value'] > this.projectResults_2[7]['value']){
      document.getElementById(this.projectResults_1[7]['name']+"_"+this.project_1).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[7]['name']+"_"+this.project_2).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[7]['name']+"_"+this.project_1).style.color = '#3baab0';
      document.getElementById(this.projectResults_1[7]['name']+"_"+this.project_2).style.color = '#f47a5c';
    }
    else if(this.projectResults_1[7]['value'] < this.projectResults_2[7]['value']){
      document.getElementById(this.projectResults_1[7]['name']+"_"+this.project_1).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[7]['name']+"_"+this.project_2).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[7]['name']+"_"+this.project_1).style.color = '#f47a5c';
      document.getElementById(this.projectResults_1[7]['name']+"_"+this.project_2).style.color = '#3baab0';
    } 
    if (this.projectResults_1[8]['value'] < this.projectResults_2[8]['value']){
      document.getElementById(this.projectResults_1[8]['name']+"_"+this.project_1).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[8]['name']+"_"+this.project_2).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[8]['name']+"_"+this.project_1).style.color = '#3baab0';
      document.getElementById(this.projectResults_1[8]['name']+"_"+this.project_2).style.color = '#f47a5c';
    }
    else if(this.projectResults_1[8]['value'] > this.projectResults_2[8]['value']){
      document.getElementById(this.projectResults_1[8]['name']+"_"+this.project_1).style.backgroundColor = '#f8e6e2';
      document.getElementById(this.projectResults_1[8]['name']+"_"+this.project_2).style.backgroundColor = '#ddedee';
      document.getElementById(this.projectResults_1[8]['name']+"_"+this.project_1).style.color = '#f47a5c';
      document.getElementById(this.projectResults_1[8]['name']+"_"+this.project_2).style.color = '#3baab0';
    } 
  }
}
