<div id="ps_modifyasset" class="container bg-light">
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <div class="row pt-4">
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabgeneral" routerLinkActive="active" title="General option"><img title="General Information" class= "img-fluid" src="assets/images/1x/btn_assetGeneral.png"></a></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabnew" routerLinkActive="active" title="New Asset option"><img title="New Asset" class= "img-fluid" src="assets/images/1x/btn_assetNew.png"></a></div>
        <div class="col-sm-6 d-grid mx-auto"></div>
    </div>    
    
    <div class="row">
        <div class="col-sm-1 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabmodify" routerLinkActive="active" title="Asset Modify option"><img title="Asset Modify" class= "img-fluid" src="assets/images/1x/btn_assetModify.png"></a></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/compare" routerLinkActive="active" title="Compare Projects"><img title="Compare Projects" class= "img-fluid" src= "assets/images/1x/btn_assetCompareSelected.png"></a></div>
        <div class="col-sm-5 d-grid mx-auto"></div>
    </div> 
    
    <div class="row">
        <div class="col-sm-2 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/calculate" routerLinkActive="active" title="Calculate option"><img title="Calculate" class= "img-fluid" src="assets/images/1x/btn_calculate.png"></a></div>
        <div class="col-sm-2 d-grid mx-auto"></div>
        <div class="col-sm-2 d-grid mx-auto"></div>
    </div> 
    </div>
    <div class="col-md-6" *ngIf="user">
      <h2>Please select two projects to compare.</h2>
      <p></p>
      <div class="row pt-4">
        <div class="col-md-2 d-grid mx-auto"></div>
        <div class="col-md-4 d-grid mx-auto">
          <mat-select [(ngModel)]="selectedValue1" class="pc_select_project">
            <mat-option *ngFor="let project of projects" [value]="project">
              {{ project }}
            </mat-option>
          </mat-select>
        </div>
        <div class="col-md-4 d-grid mx-auto">
          <mat-select [(ngModel)]="selectedValue2" class="pc_select_project">
            <mat-option *ngFor="let project of projects" [value]="project">
              {{ project }}
            </mat-option>
          </mat-select>
        </div>
        <div class="col-md-2 d-grid mx-auto"></div>
      </div>
      <div class="row calculate_row">
        <div class="col-sm-3 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto">
          <a title="Graphical Visualization option" (click)="compareChart()">
            <img
              title="Graphical Visualization"
              class="img-fluid"
              src="assets/images/1x/Graph Button1.png"
            />
          </a>
        </div>
        <div class="col-md-3 d-grid mx-auto">
          <a title="Total Results option" (click)="compareTotalResults()">
            <img
              title="Total Results"
              class="img-fluid"
              src="assets/images/1x/Results Button1.png"
            />
          </a>
        </div>
        <div class="col-sm-3 d-grid mx-auto"></div>
      </div>
    </div>
  </div>
</div>
