import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { GlobalConstants } from '../common/global-constants';

@Component({
  selector: 'app-demo-tool',
  templateUrl: './demo-tool.component.html',
  styleUrls: ['./demo-tool.component.css']
})
export class DemoToolComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    if(GlobalConstants.registeredUser === ''){
      GlobalConstants.registeredUser= localStorage.getItem("USERNAME");
      if(GlobalConstants.registeredUser !== '' && GlobalConstants.registeredUser !== null){
        this.authService.isUserLoggedIn.next(true);
      }
    }
  }
}
