import { Component, OnInit } from '@angular/core';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { ViewChild, ElementRef } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { trigger, style, animate, transition } from '@angular/animations';
import { AuthService } from '../auth.service';
import * as saveAs from 'file-saver';
import { Router } from '@angular/router';

const leaveTrans = transition(':enter', [
  style({
    opacity: 1
  }),
  animate('3s ease-out', style({
    opacity: 0,
  }))
])

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-new-asset7-wind',
  templateUrl: './new-asset7-wind.component.html',
  styleUrls: ['./new-asset7-wind.component.css'],
  animations: [
    fadeOut,
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('2s ease-out',
              style({ height: 40, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 40, opacity: 1 }),
            animate('2s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class NewAsset7WindComponent implements OnInit {

  constructor(private fb: FormBuilder, private http: HttpClient, private authService: AuthService, private router: Router) { }
  faFile = faFile;

  @ViewChild('power_profile') power_profile: ElementRef;
  @ViewChild('power_profile_label') power_profile_label: ElementRef;
  @ViewChild('post_message') post_message: ElementRef;
  @ViewChild('clear_button') clear_button: ElementRef;
  @ViewChild('asset_id') asset_id: ElementRef;

  description = GlobalConstants.assetDescription;
  question = GlobalConstants.assetQuestion;
  answer = GlobalConstants.assetAnswer;
  globalUser = GlobalConstants.registeredUser;
  file: File = null;
  reset_state: boolean = false;
  show: string = "";
  warning_msg: string="";
  setWarning: boolean=false;
  user = GlobalConstants.registeredUser;
  project = GlobalConstants.projectId;

  windForm = this.fb.group({
    hub_height: ['', [Validators.min(60), Validators.max(120)]],
    rotor_diameter: ['', [Validators.min(0)]],
    cut_off: ['', [Validators.min(5), Validators.max(30)]],
    model_capacity: ['', [Validators.min(1000), Validators.max(5000)]],
    min_turbines: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    max_turbines: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    capex: ['', [Validators.required, Validators.min(0)]],
    opex: ['', [Validators.required, Validators.min(0)]],
    lifetime: ['', [Validators.required, Validators.min(1)]],
    asset_id: ['', [Validators.required]],
    power_profile: ['']
  });

  onChange(event) {
    if (event.target.files[0]) {
      this.file = event.target.files[0];
      this.power_profile_label.nativeElement.innerHTML = this.file.name;
      for (var i in this.windForm.controls) {
        if (i != 'power_profile' && i != 'asset_id' && i != 'min_turbines' && i != 'max_turbines' && i != 'capex' && i != 'opex'  && i != 'lifetime') {
          this.windForm.controls[i].reset();
          this.windForm.controls[i].disable();
        }
      }
    }
    else {
      this.file = null
      this.power_profile_label.nativeElement.innerHTML = "Power profile of a single wind turbine [kW]";
      for (var i in this.windForm.controls) {
        this.windForm.controls[i].enable();
      }
    }
  }

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    else{
      alert('You must select a project to continue.');
      this.router.navigateByUrl('/myprojects');
    }
    //Set validators for value check
    this.windForm.get("min_turbines").valueChanges
      .subscribe(data => {
        this.changeValidators();
      });
    this.windForm.get("max_turbines").valueChanges
      .subscribe(data => {
        this.changeValidators();
      });

  }

  changeValidators() {

    var min_turbines = this.windForm.get("min_turbines").value;
    var max_turbines = this.windForm.get("max_turbines").value;

    if (min_turbines !== null) {
      this.windForm.controls["max_turbines"].setValidators([Validators.required, Validators.min(min_turbines), Validators.pattern("^[0-9]*$")]);
    }
    if (max_turbines !== null) {
      this.windForm.controls["min_turbines"].setValidators([Validators.required, Validators.max(max_turbines), Validators.pattern("^[0-9]*$")]);
    }
  }
  
  onDownloadWind(){

    const params = new HttpParams(
      {
        fromObject: {
          user_id: GlobalConstants.registeredUser,
          project_id:GlobalConstants.projectId
        }
      }
    )

    var host = "https://renergisetool.evergi.be/";
    var url ="https://renergisetool.evergi.be/information/assets/windturbines/powerprofile"
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type','text/csv')

    this.http.get(url+'?' + params.toString(), {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `windturbines_powerprofile.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  onDownloadWindExample(){
    var host = "https://renergisetool.evergi.be/";
    var url ="https://renergisetool.evergi.be/information/assets/windturbines/powerprofile"
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type','text/csv')

    this.http.get(url, {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `windturbines_powerprofile_example.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  isShown: boolean = false; // hidden by default
  toggleShow() {
    this.isShown = !this.isShown;
  }

  btnClickClear() {
    if (this.reset_state) {
      this.windForm.reset();

      this.clear_button.nativeElement.innerHTML = "Default";
      this.reset_state = !this.reset_state;
    } else {
      this.windForm.patchValue({
        hub_height: 80,
        rotor_diameter: 90,
        cut_off: 25,
        model_capacity: 2000,
        min_turbines: 0,
        max_turbines: 4,
        capex: 2500000,
        opex: 40000,
        lifetime: 25,
        asset_id: 'default_windturbine'
      });
      this.clear_button.nativeElement.innerHTML = "Clear";
      this.reset_state = !this.reset_state;
    }

    this.windForm.enable();
    this.power_profile_label.nativeElement.innerHTML = "Power profile of a single wind turbine [kW]";


  }

  btnClickAddAsset() {
    console.log("Btn Add Asset clicked");
    var host = "https://renergisetool.evergi.be/";

    var user = GlobalConstants.registeredUser;
    var project = GlobalConstants.projectId;

    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    const params = new HttpParams(
    {
      fromObject: this.windForm.value
    });

    var url = host + "users/" + this.user + "/projects/" + this.project + "/windturbines/"

    if (this.file) {
      const formdata = new FormData();

      formdata.append("powerprofile", this.file, this.file.name)

      this.http.post(url + '?' + params.toString(), formdata, { observe: 'response' }).subscribe(
        response => {
          console.log("response: " + response);
          if (response['status'] == 200) {
            this.show = "success";
            setTimeout(() => {
              this.show = " ";
            }, 2000);
          }
        },
        error => {
          console.log("error: " + error);
          this.show = 'failed';
          setTimeout(() => {
            this.show = '';
          }, 2000);
          if (error.error['warning'] != null) {
            this.warning_msg = error.error['warning'];
            this.setWarning = true;
          }
        }
      )
    }else{
      this.http.post<any>(url + '?' + params.toString(), { headers: headers }, { observe: 'response' }).subscribe(
        response => {
          console.log("response: " + response);
          if (response['status'] == 200) {
            this.show = "success";
            setTimeout(() => {
              this.show = " ";
            }, 2000);
          }
        },
        error => {
          console.log("error: " + error);
          this.show = 'failed';
          setTimeout(() => {
            this.show = '';
          }, 2000);
          if (error.error['warning'] != null) {
            this.warning_msg = error.error['warning'];
            this.setWarning = true;
          }
        }
      );
    }
  }
}