import { Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { faSave, faFile } from '@fortawesome/free-solid-svg-icons';
import { trigger, style, animate, transition } from '@angular/animations';

import { GlobalConstants } from '../common/global-constants';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import * as saveAs from 'file-saver';

const leaveTrans = transition(':enter', [
  style({
    opacity: 1
  }),
  animate('3s ease-out', style({
    opacity: 0,
  }))
])

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-adding-asset-new',
  templateUrl: './4-new-asset-1-public-grid.component.html',
  styleUrls: ['./4-new-asset-1-public-grid.component.css'],
  animations: [

    fadeOut,
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('2s ease-out',
              style({ height: 80, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 80, opacity: 1 }),
            animate('2s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class AddingAssetNewComponent implements OnInit {

  @ViewChild('asset_id') asset_id: ElementRef;
  @ViewChild('peak_influence') peak_influence: ElementRef;
  @ViewChild('sell_to_grid_const') sell_to_grid_const: ElementRef;
  @ViewChild('buy_from_grid_const') buy_from_grid_const: ElementRef;
  @ViewChild('sell_to_grid') sell_to_grid: ElementRef;
  @ViewChild('sell_to_grid_label') sell_to_grid_label: ElementRef;
  @ViewChild('buy_from_grid') buy_from_grid: ElementRef;
  @ViewChild('buy_from_grid_label') buy_from_grid_label: ElementRef;
  @ViewChild('post_message') post_message: ElementRef;
  @ViewChild('clear_button') clear_button: ElementRef;

  faSave = faSave;
  faFile = faFile;
  isShown: boolean = false; // hidden by default
  project = GlobalConstants.projectId;
  user = GlobalConstants.registeredUser;

  description = GlobalConstants.assetDescription;
  question = GlobalConstants.assetQuestion;
  answer = GlobalConstants.assetAnswer;
  file1: File = null;
  file2: File = null;
  reset_state: boolean = false;
  show: string = '';
  warning_msg = '';
  setWarning: boolean = false;

  publicForm = this.fb.group({
    asset_id: ['', Validators.required],
    peak_influence: ['', [Validators.min(0), Validators.required]],
    sell_to_grid_const: ['', Validators.required],
    buy_from_grid_const: ['', Validators.required],
    sell_to_grid: [''],
    buy_from_grid: ['']
  });

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    else{
      alert("Your projects will appear here. Start creating a new project if you do not have one yet.");
      this.router.navigateByUrl('/myprojects');
    }
  }

  onChange1(event) {
    if (event.target.files[0]) {
      this.file1 = event.target.files[0];
      this.sell_to_grid_label.nativeElement.innerHTML = this.file1.name
      this.publicForm.controls["sell_to_grid_const"].setValidators([]);
      this.publicForm.get('sell_to_grid_const').updateValueAndValidity();
      for (var i in this.publicForm.controls) {
        if (i === 'sell_to_grid_const' || i === 'buy_from_grid_const') {
          this.publicForm.controls[i].reset();
          this.publicForm.controls[i].disable();
        }
      }
    }
    else {
      this.file1 = null
      this.sell_to_grid_label.nativeElement.innerHTML = "Price you sell electricity to the public grid [€/KWh]"
      this.publicForm.controls["sell_to_grid_const"].setValidators([Validators.required]);
      this.publicForm.get('sell_to_grid_const').updateValueAndValidity();
      this.buy_from_grid_label.nativeElement.innerHTML = "Price you buy electricity from the grid [€/KWh]"
      this.publicForm.controls["buy_from_grid_const"].setValidators([Validators.required]);
      this.publicForm.get('buy_from_grid_const').updateValueAndValidity()
    }
  }

  onChange2(event) {
    if (event.target.files[0]) {
      this.file2 = event.target.files[0];
      this.buy_from_grid_label.nativeElement.innerHTML = this.file2.name
      this.publicForm.controls["buy_from_grid_const"].setValidators([]);
      this.publicForm.get('buy_from_grid_const').updateValueAndValidity();
      for (var i in this.publicForm.controls) {
        if (i === 'sell_to_grid_const' || i === 'buy_from_grid_const') {
          this.publicForm.controls[i].reset();
          this.publicForm.controls[i].disable();
        }
      }
    }
    else {
      this.file2 = null
      this.sell_to_grid_label.nativeElement.innerHTML = "Price you sell electricity to the public grid [€/KWh]"
      this.publicForm.controls["sell_to_grid_const"].setValidators([Validators.required]);
      this.publicForm.get('sell_to_grid_const').updateValueAndValidity();
      this.buy_from_grid_label.nativeElement.innerHTML = "Price you buy electricity from the grid [€/KWh]"
      this.publicForm.controls["buy_from_grid_const"].setValidators([Validators.required]);
      this.publicForm.get('buy_from_grid_const').updateValueAndValidity();
    }
  }

  onDownloadSell() {

    const params = new HttpParams(
      {
        fromObject: {
          user_id: GlobalConstants.registeredUser,
          project_id: GlobalConstants.projectId
        }
      }
    )

    var host = "https://renergisetool.evergi.be/";
    var url = "https://renergisetool.evergi.be/information/environment/sell_to_grid";
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'text/csv')

    this.http.get(url + '?' + params.toString(), { responseType: 'blob' }).subscribe(
      (response: any) => {
        saveAs(response, `sell_to_grid.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  onDownloadSellExample() {
    var host = "https://renergisetool.evergi.be/";
    var url = "https://renergisetool.evergi.be/information/environment/sell_to_grid"
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'text/csv')

    this.http.get(url, { responseType: 'blob' }).subscribe(
      (response: any) => {
        saveAs(response, `sell_to_grid_example.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  onDownloadBuy() {
    const params = new HttpParams(
      {
        fromObject: {
          user_id: GlobalConstants.registeredUser,
          project_id: GlobalConstants.projectId
        }
      }
    )

    var host = "https://renergisetool.evergi.be/";
    var url = "https://renergisetool.evergi.be/information/environment/buy_from_grid";
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'text/csv')

    this.http.get(url + '?' + params.toString(), { responseType: 'blob' }).subscribe(
      (response: any) => {
        saveAs(response, `buy_from_grid.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  onDownloadBuyExample() {
    var host = "https://renergisetool.evergi.be/";
    var url = "https://renergisetool.evergi.be/information/environment/buy_from_grid"
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'text/csv')

    this.http.get(url, { responseType: 'blob' }).subscribe(
      (response: any) => {
        saveAs(response, `buy_from_grid_example.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }


  toggleShow() {
    this.isShown = !this.isShown;
  }

  btnClickClear() {
    console.log("Btn Clear clicked");
    if (this.reset_state) {
      this.publicForm.reset();
      for (var i in this.publicForm.controls) {
        if (i === 'sell_to_grid_const' || i === 'buy_from_grid_const') {
          this.publicForm.controls[i].disable();
        }
      }
      this.clear_button.nativeElement.innerHTML = "Default"
      this.sell_to_grid_label.nativeElement.innerHTML = "Price you sell electricity to the public grid [€/KWh]"
      this.buy_from_grid_label.nativeElement.innerHTML = "Price you buy electricity from the grid [€/KWh]"
      this.setWarning = false;
      this.reset_state = !this.reset_state;
    } else {
      this.publicForm.patchValue({
        peak_influence: 4,
        sell_to_grid_const: 0.10,
        buy_from_grid_const: 0.25,
        asset_id: 'default_grid'
      });
      this.clear_button.nativeElement.innerHTML = "Clear"
      this.reset_state = !this.reset_state;
    }
    this.publicForm.enable()
  }

  btnClickAddAsset() {
    console.log("Btn Add Asset clicked");

    const headers = new HttpHeaders()
      .append(
        'Content-Type',
        'application/x-www-form-urlencoded'
      );

    var host = "https://renergisetool.evergi.be/";
    var url = host + "users/" + this.user + "/projects/" + this.project + "/publicgrids/"

    if (this.file1 && this.file2) {
      console.log("requested url " + url)


      const formdata = new FormData();

      formdata.append("buy_from_grid", this.file2, this.file2.name)
      formdata.append("sell_to_grid", this.file1, this.file1.name)
      
      const params = new HttpParams(
        {
          fromObject: {
            peak_influence: this.peak_influence.nativeElement.value,
            asset_id: this.asset_id.nativeElement.value
          }
        }
      )

      this.http.post(url+ '?' + params.toString(), formdata, { observe: 'response' }).subscribe(
        response => {
          console.log("response: ", response);

          if (response['status'] == 200) {
            console.log("response: ", response);
            this.show = 'success';
            setTimeout(() => {
              this.show = '';
            }, 2000);
          }
        },
        error => {
          console.log("error: " + error);
          this.show = 'failed';
          setTimeout(() => {
            this.show = '';
          }, 2000);
        }
      )
    }
    else {
      const params = new HttpParams(
        {
          fromObject: {
            peak_influence: this.peak_influence.nativeElement.value,
            asset_id: this.asset_id.nativeElement.value,
            sell_to_grid: this.sell_to_grid_const.nativeElement.value,
            buy_from_grid: this.buy_from_grid_const.nativeElement.value
          }
        }
      )
      this.http.post<any>(url + '?' + params.toString(), { headers: headers }, { observe: 'response' }).subscribe(
        response => {
          console.log("response: ", response);
          if (response['status'] == 200) {
            this.show = 'success';
            setTimeout(() => {
              this.show = '';
            }, 2000);
          }
        },
        error => {
          console.log("error: " + error);
          this.show = 'failed';
          setTimeout(() => {
            this.show = '';
          }, 2000);
          if (error.error['warning'] != null) {
            this.warning_msg = error.error['warning'];
            this.setWarning = true;
          }
        }
      );
    }
  }
}
