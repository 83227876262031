<div id="ps_tab6" class="container bg-light">
    <app-project-name-label></app-project-name-label>
    <div class="row ps_padding_20">
        <div class="col-md-6">
           <app-user-main-menu></app-user-main-menu>
        </div>
        <div class="col-md-6" *ngIf = "user">
            <h4 class = "ap_table_title spacing">Total Results</h4>
            <div class = "spinner_position" *ngIf = "showSpinner">
                <mat-spinner [diameter]="200" ProgressColor color = "#3babb0"></mat-spinner>
            </div>
            <table id="result-table" class="result-table" *ngIf = "!showSpinner">
                <tr class="table-row" *ngFor = 'let result of projectResults'>
                    <th class="table-header">{{result.name}}</th>
                    <td class="table-data">{{result.value}}</td>
                </tr>
            </table>
            <div class="row spacing" *ngIf = "!showSpinner">
                <div class="col ap_button_centered">
                    <button type="button" class="btn ps_renergise_btn ap_spacing" routerLink="/calculate" routerLinkActive="active" title ="Back to calculate">Back</button>
                <button type="button" class="btn ps_renergise_btn ap_spacing" routerLink="/chart" routerLinkActive="active" title ="Chart">Chart</button>

                </div>
            </div>
        </div>
    </div>
</div>



