<div id="ps_tab6" class="container bg-light">
  <app-project-name-label></app-project-name-label>
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <div class="row pt-4">
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabgeneral" routerLinkActive="active"
            title="General option"><img title="General Information" class="img-fluid"
              src="assets/images/1x/btn_assetGeneral.png"></a></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabnew" routerLinkActive="active"
            title="New Asset option"><img title="New Asset" class="img-fluid"
              src="assets/images/1x/btn_assetNewSelected.png"></a></div>
        <div class="col-sm-6 d-grid mx-auto"></div>
      </div>

      <div class="row">
        <div class="col-sm-1 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabmodify" routerLinkActive="active"
            title="Asset Modify option"><img title="Asset Modify" class="img-fluid"
              src="assets/images/1x/btn_assetModify.png"></a></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/compare" routerLinkActive="active"
            title="Compare Projects"><img title="Compare Projects" class="img-fluid"
              src="assets/images/1x/btn_assetCompare.png"></a></div>
        <div class="col-sm-5 d-grid mx-auto"></div>
      </div>

      <div class="row">
        <div class="col-sm-2 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto"></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/calculate" routerLinkActive="active"
            title="Calculate option"><img title="Calculate" class="img-fluid"
              src="assets/images/1x/btn_calculate.png"></a></div>
        <div class="col-sm-2 d-grid mx-auto"></div>
        <div class="col-sm-2 d-grid mx-auto"></div>
      </div>
    </div>

    <div class="col-md-6 ps_form rounded ps_white">
      <form [formGroup]="solarForm" (ngSubmit)="btnClickAddAsset()">
        <div class="form-group">
          <div class="row pt-4">
            <app-menu-input-data></app-menu-input-data>
            <h1>
              <img class="img-fluid ps_imgsize" title="Import Data Files" src="assets/images/1x/newAssetImage.png" />
              New asset: Solar PV
            </h1>
            <h2>{{ description }}</h2>
          </div>

          <div class="row">
            <!-- <div class="col-8 d-grid mx-auto"> -->
            <div class="ps_logo_green">{{ question }}</div>
            <div>{{ answer }}</div>
            <!-- </div> -->
          </div>
          <div class="row pt-2">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="asset_id" name="asset_id" id="asset_id" #asset_id type="text"
                  class="form-control ps_renergise_btn" title="Asset name" placeholder="Asset name" />
                <span *ngIf="
                    !solarForm.get('asset_id').valid &&
                    solarForm.get('asset_id').touched
                  " class="help-block">
                  <span *ngIf="solarForm.get('asset_id').errors['required']" style="font-size: small">This field is
                    required.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <!-- <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="lats" name="lats" id="lats" #lats type="number"
                  class="form-control ps_renergise_btn" title="The latitude of the system [°]"
                  placeholder="The latitude of the system [°]" />
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="lons" name="lons" id="lons" #lons type="number"
                  class="form-control ps_renergise_btn" title="The longitude of the system [°]"
                  placeholder="The longitude of the system [°]" />
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div> -->

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="tilt" name="tilt" id="tilt" #tilt type="number"
                  class="form-control ps_renergise_btn" title="The tilt of the solar pv [°]"
                  placeholder="The tilt of the solar pv [°]" />
                <span *ngIf="
                    !solarForm.get('tilt').valid &&
                    solarForm.get('tilt').touched
                  " class="help-block">
                  <span *ngIf="solarForm.get('tilt').errors['min']" style="font-size: small">This parameter must be
                    higher than 0.</span>
                  <span *ngIf="solarForm.get('tilt').errors['max']" style="font-size: small">This parameter must be less
                    than 90.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="azimuth" name="azimuth" id="azimuth" #azimuth type="number"
                  class="form-control ps_renergise_btn" title="The azimuth of the solar pv [°]"
                  placeholder="The azimuth of the solar pv [°]" />
                <span *ngIf="
                    !solarForm.get('azimuth').valid &&
                    solarForm.get('azimuth').touched
                  " class="help-block">
                  <span *ngIf="solarForm.get('azimuth').errors['min']" style="font-size: small">This parameter must be
                    higher than 0.</span>
                  <span *ngIf="solarForm.get('azimuth').errors['max']" style="font-size: small">This parameter must be
                    less than 90.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <select formControlName="tracking" class="form-control ps_renergise_btn"
                  title="Is the solar pv a single axis tracking system?" #tracking>
                  <option value="Is the solar pv a single axis tracking system?" disabled hidden>
                    Is the solar pv a single axis tracking system?
                  </option>
                  <option value="True">True</option>
                  <option value="False">False</option>
                </select>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <select formControlName="module" class="form-control ps_renergise_btn" title="Select a module" #module>
                  <option value="Select a module" disabled hidden>
                    Select a module
                  </option>
                  <option value="mean_Mono_c_Si">mean_Mono_c_Si</option>
                  <option value="mean_Multi_c_Si">mean_Multi_c_Si</option>
                  <option value="mean_Thin_Film">mean_Thin_Film</option>
                </select>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <select formControlName="temp_parameters" class="form-control ps_renergise_btn"
                  title="Select a temperature parameter" #temp_parameters>
                  <option value="Select a temperature parameters" disabled hidden>
                    Select a temperature parameters
                  </option>
                  <option value="insulated">insulated</option>
                  <option value="freestanding">freestanding</option>
                </select>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="min_capacity" name="min_capacity" id="min_capacity" #min_capacity type="number"
                  class="form-control ps_renergise_btn" title="Minimum peak power [kW]"
                  placeholder="Minimum peak power [kW]" />
                <span *ngIf="
                    !solarForm.get('min_capacity').valid &&
                    solarForm.get('min_capacity').touched
                  " class="help-block">
                  <span *ngIf="solarForm.get('min_capacity').errors['required']" style="font-size: small">This field is
                    required.</span>
                  <span *ngIf="solarForm.get('min_capacity').errors['min']" style="font-size: small">This parameter must
                    be higher than 0.</span>
                  <span *ngIf="solarForm.get('min_capacity').errors['max']" style="font-size: small">This parameter must
                    be less than maximum capacity.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="max_capacity" name="max_capacity" id="max_capacity" #max_capacity type="number"
                  class="form-control ps_renergise_btn" title="Maximum peak power [kW]"
                  placeholder="Maximum peak power [kW]" />
                <span *ngIf="
                    !solarForm.get('max_capacity').valid &&
                    solarForm.get('max_capacity').touched
                  " class="help-block">
                  <span *ngIf="solarForm.get('max_capacity').errors['required']" style="font-size: small">This field is
                    required.</span>
                  <span *ngIf="solarForm.get('max_capacity').errors['min']" style="font-size: small">This parameter must
                    be higher than minimum capacity.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="capex" name="capex" id="capex" #capex type="number"
                  class="form-control ps_renergise_btn" title="Cost of buying the asset per peak power [€/kW]"
                  placeholder="Cost of buying the asset per peak power [€/kW]" />
                <span *ngIf="
                    !solarForm.get('capex').valid &&
                    solarForm.get('capex').touched
                  " class="help-block">
                  <span *ngIf="solarForm.get('capex').errors['required']" style="font-size: small">This field is
                    required.</span>
                  <span *ngIf="solarForm.get('capex').errors['min']" style="font-size: small">This parameter must be
                    higher than 0.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="opex" name="opex" id="opex" #fix_opex type="number"
                  class="form-control ps_renergise_btn" title="The yearly operational cost per peak power [€/kW/year]"
                  placeholder="The yearly operational cost per peak power [€/kW/year]" />
                <span *ngIf="
                    !solarForm.get('opex').valid &&
                    solarForm.get('opex').touched
                  " class="help-block">
                  <span *ngIf="solarForm.get('opex').errors['required']" style="font-size: small">This field is
                    required.</span>
                  <span *ngIf="solarForm.get('opex').errors['min']" style="font-size: small">This parameter must be
                    higher than 0.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="lifetime" name="lifetime" id="lifetime" #lifetime type="number"
                  class="form-control ps_renergise_btn" title="The lifetime of the asset [years]"
                  placeholder="The lifetime of the asset [years]" />
                <span *ngIf="
                    !solarForm.get('lifetime').valid &&
                    solarForm.get('lifetime').touched
                  " class="help-block">
                  <span *ngIf="solarForm.get('lifetime').errors['required']" style="font-size: small">This field is
                    required.</span>
                  <span *ngIf="solarForm.get('lifetime').errors['min']" style="font-size: small">This parameter must be
                    higher than 1.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <label class="form-control ps_renergise_btn" #power_profile_label
                  title="Power profile of the asset normalised to a peak power of 1 kW [kW]" for="power_profile">Power
                  profile of the asset normalised to a peak power of 1 kW [kW]</label>
                <input formControlName="power_profile" name="power_profile" id="power_profile" #power_profile
                  type="file" class="ps_hidden form-control ps_renergise_btn"
                  title="Power profile of the asset normalised to a peak power of 1 kW [kW]"
                  placeholder="Power profile of the asset normalised to a peak power of 1 kW [kW]" observeFiles
                  (change)="onChange($event)" />
              </form-field>
            </div>
            <div class="col-2 d-grid mx-auto">
              <div class="col">
                <fa-icon title="Download empty template" mat-raised-button [icon]="faFile" size="2x"
                  style="cursor: pointer; color: '#FCC78C'" (click)="onDownloadSolar()"></fa-icon>
                <fa-icon title="Download example csv template" mat-raised-button [icon]="faFile" size="2x"
                  style="cursor: pointer; color: '#3babb0';margin-left: 4px;" (click)="onDownloadSolarExample()"></fa-icon>
              </div>
            </div>
            <div class="col-2 d-grid mx-auto"></div>
          </div>
        </div>
      </form>

      <div class="row pt-1 pb-2">
        <div class="col-4 d-grid mx-auto">
          <button type="button" #clear_button title="Clear form" class="btn ps_renergise_btn" (click)="btnClickClear()">
            Default
          </button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <button type="button" title="Add asset" class="btn ps_renergise_btn" (click)="btnClickAddAsset()"
            [disabled]="!solarForm.valid">
            Add Asset
          </button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <div *ngIf="show === 'success'" @fadeOut>
            <img class="submit_img" title="Added" src="assets/images/1x/baseline_task_alt_green_24dp.png" #submit_img />
          </div>
          <div *ngIf="show === 'failed'" @fadeOut>
            <img class="submit_img" title="Added" src="assets/images/1x/baseline_highlight_off_red_24dp.png"
              #submit_img />
          </div>
        </div>
      </div>
      <div class="row pt-1 pb-2">
        <div class="col-8 d-grid mx-auto">
          <span class="alert alert-warning" role="alert" *ngIf="setWarning">{{
            warning_msg
          }}</span>
        </div>
        <div class="col-4 d-grid mx-auto"></div>
      </div>
    </div>
  </div>
</div>
