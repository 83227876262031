import { Component, OnInit } from '@angular/core';
import { HttpClient }from '@angular/common/http';
import { GlobalConstants } from '../common/global-constants';
import { delayWhen, map, retryWhen, take, tap } from 'rxjs/operators';
import { timer } from 'rxjs';
import { Router } from '@angular/router';

export interface projectResult {
  name : string;
  value : number;
}

@Component({
  selector: 'app-project-total-results',
  templateUrl: './project-total-results.component.html',
  styleUrls: ['./project-total-results.component.css']
})
export class ProjectTotalResultsComponent implements OnInit {
  projectResults: projectResult [] = [];
  project = GlobalConstants.projectId;
  user = GlobalConstants.registeredUser;
  showSpinner: boolean = true;
  response: Object;
  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    
    var host = "https://renergisetool.evergi.be/";  
    if(this.user){
      var url = host + "users/" + this.user + "/projects/"+ this.project + "/result/"+ "/total";
      console.log("url " + url);
      this.http.get<any>(url, {observe: 'response'}).subscribe(
          response => {
            console.log("response: ", response);
            if(response['status'] === 200){
              this.response = response.body;
              this.showSpinner = false;
              this.createTableData();
            }
          }),
          error => {
            console.log("error: ", error);
          }
    }
    else{
      console.log("There is any user.");
    }
  }

  createTableData(){
    this.projectResults.push({name: "Total Battery capacity (kWh) ", value: +(+this.response['data']["ESS capacity (kWh)"]).toFixed(3)});
    this.projectResults.push({name: "Total Gas Turbine peak power (kW)", value: +(+this.response['data']["Generator capacity (kWp)"]).toFixed(3)});
    this.projectResults.push({name: "Total Solar PV peak power (kW)", value: +(+this.response['data']["PV capacity (kWp)"]).toFixed(3)});
    this.projectResults.push({name: "Total Wind Turbines (WT)", value: +(+this.response['data']["WT capacity (WT)"]).toFixed(3)});
    this.projectResults.push({name: "Electricity export (kWh)", value: +(+this.response['data']["Electricity export (kWh)"]).toFixed(3)});
    this.projectResults.push({name: "Electricity import (kWh)", value: +(+this.response['data']["Electricity import (kWh)"]).toFixed(3)});
    this.projectResults.push({name: "Self-consumption ratio (%)", value: +(+this.response['data']["Self-consumption ratio (%)"]).toFixed(3)});
    this.projectResults.push({name: "Self-sufficiency ratio (%)", value: +(+this.response['data']["Self-sufficiency ratio (%)"]).toFixed(3)});
    this.projectResults.push({name: "Total cost (kEUR)", value: +(+this.response['data']["Total cost (kEUR)"]).toFixed(3)});
  }

}
