import { Component, OnInit } from '@angular/core';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { ViewChild, ElementRef } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { trigger, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

const leaveTrans = transition(':enter', [
  style({
    opacity: 1
  }),
  animate('3s ease-out', style({
    opacity: 0,
  }))
])

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-modify-asset-generator',
  templateUrl: './modify-asset-generator.component.html',
  styleUrls: ['./modify-asset-generator.component.css'],
  animations: [
    fadeOut,
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('2s ease-out',
              style({ height: 40, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 40, opacity: 1 }),
            animate('2s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class ModifyAssetGeneratorComponent implements OnInit {

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router,  private authService: AuthService) { }
  faFile = faFile;

  @ViewChild('asset_id') asset_id: ElementRef;
  // @ViewChild('power_profile') power_profile: ElementRef;
  @ViewChild('clear_button') clear_button: ElementRef;
  @ViewChild('post_message') post_message: ElementRef;


  description = GlobalConstants.assetDescription;
  question = GlobalConstants.assetQuestion;
  answer = GlobalConstants.assetAnswer;
  reset_state: boolean = false;
  show: string = "";
  warning_msg = '';
  setWarning: boolean = false;

  generatorForm = this.fb.group({
    influence_per_kWh: ['', [Validators.required, Validators.min(0)]],
    asset_id: ['', [Validators.required]],
    min_capacity: ['', [Validators.required, Validators.min(0)]],
    max_capacity: ['', [Validators.required, Validators.min(0)]],
    opex: ['', [Validators.required, Validators.min(0)]],
    capex: ['', [Validators.required, Validators.min(0)]],
    lifetime: ['', [Validators.required, Validators.min(1)]]
  });

  user = GlobalConstants.registeredUser;
  project = GlobalConstants.projectId;
  assetId: string;
  assetToModify = GlobalConstants.assetToModify;

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    //Set validators for value check
    this.generatorForm.get("min_capacity").valueChanges
      .subscribe(data => {
        this.changeValidators();
      });
    this.generatorForm.get("max_capacity").valueChanges
      .subscribe(data => {
        this.changeValidators();
      });

    var numberPattern = /\d+/g;
    if(this.assetToModify){
      this.assetId = this.assetToModify.match(numberPattern)[0];
    }else{
      this.router.navigateByUrl('/tabmodify');
    }
    if (this.assetId) {
      var host = "https://renergisetool.evergi.be/";
      var url = host + "users/" + this.user + "/projects/" + this.project + "/generators/" + this.assetId;
      console.log("url " + url);
      this.http.get<any>(url).subscribe(
        response => {
          console.log("response: " + response);
          this.generatorForm.patchValue({
            influence_per_kWh: response[this.assetToModify]["influence_per_kWh"],
            asset_id: response[this.assetToModify]["asset_id"],
            min_capacity: response[this.assetToModify]["min_capacity"],
            max_capacity: response[this.assetToModify]["max_capacity"],
            opex: response[this.assetToModify]["opex"],
            capex: response[this.assetToModify]["capex"],
            lifetime: response[this.assetToModify]["lifetime"],
          });
        }
      );
    }
  }

  changeValidators() {
    var min_capacity = this.generatorForm.get("min_capacity").value;
    var max_capacity = this.generatorForm.get("max_capacity").value;

    if (min_capacity !== null) {
      this.generatorForm.controls["max_capacity"].setValidators([Validators.required, Validators.min(min_capacity)]);
      // this.generatorForm.controls["max_capacity"].updateValueAndValidity;
    }
    if (max_capacity !== null) {
      this.generatorForm.controls["min_capacity"].setValidators([Validators.required, Validators.max(max_capacity), Validators.min(0)]);
      // this.generatorForm.controls["min_capacity"].updateValueAndValidity;

    }
  }

  isShown: boolean = false; // hidden by default
  toggleShow() {
    this.isShown = !this.isShown;
  }

  btnClickClear() {
    console.log("Btn Clear clicked");
    this.generatorForm.reset();
    this.generatorForm.enable();

  }

  btnClickModifyAsset() {
    console.log("Btn Modify clicked");
    var host = "https://renergisetool.evergi.be/";

    const headers = new HttpHeaders()
      .append(
        'Content-Type',
        'application/x-www-form-urlencoded'
      );

    const params = new HttpParams(
      {
        fromObject: this.generatorForm.value
      }
    );

    var url = host + "users/" + this.user + "/projects/" + this.project + "/generators/" + this.assetId
    this.http.put<any>(url + '?' + params.toString(), { headers: headers }, { observe: 'response' }).subscribe(
      response => {
        console.log("response: " + response);
        if (response['status'] == 200) {
          this.show = 'success';
          setTimeout(() => {
            this.show = '';
            this.router.navigateByUrl('/tabmodify');
          }, 2000);
        }
      },
      error => {
        console.log("error: " + error);
        this.show = 'failed';
        setTimeout(() => {
          this.show = '';
        }, 2000);
        if (error.error['warning'] != null) {
          this.warning_msg = error.error['warning'];
          this.setWarning = true;
        }
      }
    );
  }
}
