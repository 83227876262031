import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { AngularFireAuth } from '@angular/fire/auth';
import { GlobalConstants } from "./common/global-constants";
export interface AuthResponseData {
    kind: string;
    idToken: string;
    email: string;
    refreshToken: string;
    expiresIn: string;
    localId: string;
    registered?: boolean;
  }
@Injectable({
    providedIn: 'root',
  })
export class AuthService {
  isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userData: any; // Save logged in user data
  userName = '';
  userId = '';
  selectedProject = '';
  authRespData: AuthResponseData;
  errorMessage = '';
  setErrorMessage: boolean = false;

  constructor(private http: HttpClient, private router: Router, private route:ActivatedRoute,
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public ngZone: NgZone // NgZone service to remove outside scope warning
  )
  {}
  
  // LogIn with email/password
  LogIn(email: string, password: string) {
    this.errorMessage = '';
    this.setErrorMessage = false;
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(
      (result) => {        
        this.userId = result.user?.uid;
        console.log("User: ", this.userId, "email verified: ", result.user?.emailVerified);
        if(result.user?.emailVerified){
          console.log("User signed in: ", result.user?.uid, "email verified: ", result.user?.emailVerified);
          var host = 'https://renergisetool.evergi.be/';
          var getUsers = host + 'users/'+ result.user?.uid;

          this.http.get<any>(getUsers, {observe: 'response',}).subscribe(
            response => {
              if (response['status'] === 200) {
                GlobalConstants.registeredUser = response.body['user'];
                localStorage.setItem("USERNAME", GlobalConstants.registeredUser);
                this.isUserLoggedIn.next(true);
                console.log("registeredUser AFTER: " + GlobalConstants.registeredUser);
                this.router.navigate(['/myprojects'], {relativeTo:this.route});
              }
            },
            error => {
              console.log("error: ", error.statusText);
              this.setErrorMessage = true;
              if(error.statusText === 'Not Found'){
                this.errorMessage = 'User not found.';
              }
              else{
                this.errorMessage = error.statusText;
              }
            }
          )
        }
        else{
          window.alert('Please verify your email address.');
        }
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  // Sign up with email/password
  SignUp(email: string, password: string) {
    console.log("Sign up button clicked");
    this.errorMessage = '';
    this.setErrorMessage = false;
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        console.log("User signed up: ", result.user?.uid, "email verified: ", result.user?.emailVerified);
        this.userId = result.user?.uid;
        if(this.userId !== ''){
          var host = 'https://renergisetool.evergi.be/';
          var getUsers = host + 'users/';
      
          const params = new HttpParams({
            fromObject: {
              user_id: this.userId
            }
          });

          this.http.post<any>(getUsers + '?' + params.toString(), {observe: 'response',}).subscribe(
            response => {
              if (response['success'] === true) {
                console.log('USER CREATED IN VUB DB: ', this.userId);
              }
            },
            error => {
              console.log('ERROR', error);
              if (error['status'] === 409) {
                this.errorMessage = "This user already exists!";
                this.setErrorMessage = true;
              }
            }
          )
        }
        this.SendVerificationMail();
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  
 // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    this.errorMessage = '';
    this.setErrorMessage = false;
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigateByUrl('verify-email');
      });
  }

   // Reset Forggot password
   ForgotPassword(passwordResetEmail: string) {
    this.errorMessage = '';
    this.setErrorMessage = false;
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        console.log("Password reset, check email: ", passwordResetEmail);
        window.alert('Password reset email sent, check your inbox or your spam.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Autologin
  AutoLogin() {
    this.errorMessage = '';
    this.setErrorMessage = false;
    this.userName = localStorage.getItem('USERNAME');
    this.selectedProject = localStorage.getItem('SELECTED_PROJECT');
    if (!this.userName) {
       this.isUserLoggedIn.next(false);
       return;
    }
    this.isUserLoggedIn.next(true);
  }

  // Sign out
  LogOut() {
    this.errorMessage = '';
    this.setErrorMessage = false;
    return this.afAuth.signOut().then(() => {
      localStorage.clear();
      GlobalConstants.registeredUser = '';
      GlobalConstants.projectId = '';
      this.isUserLoggedIn.next(false);
      this.userId = '';
      this.router.navigate(['/'], {relativeTo:this.route});
    });
  }
}