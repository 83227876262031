import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-left-side-logo',
  templateUrl: './left-side-logo.component.html',
  styleUrls: ['./left-side-logo.component.css']
})
export class LeftSideLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
