import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newprojectadd',
  templateUrl: './new-project-add.component.html',
  styleUrls: ['./new-project-add.component.css']
})
export class NewprojectaddComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
