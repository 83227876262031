<div id="ps_tab3" class="container bg-light">
  <app-project-name-label></app-project-name-label>
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <div class="row pt-4">
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabgeneral" routerLinkActive="active" title="General option"><img title="General Information" class= "img-fluid" src="assets/images/1x/btn_assetGeneral.png"></a></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabnew" routerLinkActive="active" title="New Asset option"><img title="New Asset" class= "img-fluid" src="assets/images/1x/btn_assetNew.png"></a></div>
        <div class="col-sm-6 d-grid mx-auto"></div>
      </div>    
      
      <div class="row">
          <div class="col-sm-1 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabmodify" routerLinkActive="active" title="Asset Modify option"><img title="Asset Modify" class= "img-fluid" src="assets/images/1x/btn_assetModifySelected.png"></a></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/compare" routerLinkActive="active" title="Compare Projects"><img title="Compare Projects" class= "img-fluid" src= "assets/images/1x/btn_assetCompare.png"></a></div>
          <div class="col-sm-5 d-grid mx-auto"></div>
      </div> 
      
      <div class="row">
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/calculate" routerLinkActive="active" title="Calculate option"><img title="Calculate" class= "img-fluid" src="assets/images/1x/btn_calculate.png"></a></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
      </div> 
    </div>

    <div class="col-md-6 ps_form rounded ps_white">
      <form [formGroup]="generatorForm" (ngSubmit)="btnClickModifyAsset()">
        <div class="form-group">

          <div class="row pt-4">
            <app-modify-asset-input-data [activate_generators]="'active'"></app-modify-asset-input-data>
            <h1><img class="img-fluid ps_imgsize" title="Import Data Files" src="assets/images/1x/newAssetImage.png">
              Modify asset</h1>
            <!-- <h2>{{ description }}</h2> -->
            <div class="ps_logo_green">Why do I modify an Asset?</div>
            <div>If you want to apply minor changes into a specified energy system, you can chose the Asset that wish to
              change and re-calculate the outcome of the renewed configuration.</div>
          </div>

          <div class="row pt-2">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="asset_id" name="asset_id" id="asset_id" #asset_id type="text"
                  class="form-control ps_renergise_btn" title="Asset name" placeholder="Asset name">
                <span *ngIf="!generatorForm.get('asset_id').valid && generatorForm.get('asset_id').touched"
                  class="help-block">
                  <span *ngIf="generatorForm.get('asset_id').errors['required']" style="font-size:small">This field is
                    required.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="influence_per_kWh" name="influence_per_kWh" id="influence_per_kWh"
                  #influence_per_kWh type="number" class="form-control ps_renergise_btn"
                  title="Cost of producing 1 kWh [€/kWh]" placeholder="Cost of producing 1 kWh [€/kWh]">
                <span
                  *ngIf="!generatorForm.get('influence_per_kWh').valid && generatorForm.get('influence_per_kWh').touched"
                  class="help-block">
                  <span *ngIf="generatorForm.get('influence_per_kWh').errors['required']" style="font-size:small">This
                    field is required.</span>
                  <span *ngIf="generatorForm.get('influence_per_kWh').errors['min']" style="font-size:small">This
                    parameter must be higher than 0.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="min_capacity" name="min_capacity" id="min_capacity" #min_capacity type="number"
                  class="form-control ps_renergise_btn" title="Minimum peak power [kW]"
                  placeholder="Minimum peak power [kW]">
                <span *ngIf="!generatorForm.get('min_capacity').valid && generatorForm.get('min_capacity').touched"
                  class="help-block">
                  <span *ngIf="generatorForm.get('min_capacity').errors['required']" style="font-size:small">This field
                    is required.</span>
                  <span *ngIf="generatorForm.get('min_capacity').errors['min']" style="font-size:small">This parameter
                    must be higher than 0.</span>
                  <span *ngIf="generatorForm.get('min_capacity').errors['max']" style="font-size:small">This parameter
                    must be less than maximum capacity.</span>

                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="max_capacity" name="max_capacity" id="max_capacity" #max_capacity type="number"
                  class="form-control ps_renergise_btn" title="Max peak power [kW]"
                  placeholder="Max peak power [kW]">
                <span *ngIf="!generatorForm.get('max_capacity').valid && generatorForm.get('max_capacity').touched"
                  class="help-block">
                  <span *ngIf="generatorForm.get('max_capacity').errors['required']" style="font-size:small">This field
                    is required.</span>
                  <span *ngIf="generatorForm.get('max_capacity').errors['min']" style="font-size:small">This parameter
                    must be higher than minimum capacity.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="capex" name="capex" id="capex" #capex type="number"
                  class="form-control ps_renergise_btn" title="Cost of buying the asset per peak power [€/kW]"
                  placeholder="Cost of buying the asset per peak power [€/kW]">
                <span *ngIf="!generatorForm.get('capex').valid && generatorForm.get('capex').touched"
                  class="help-block">
                  <span *ngIf="generatorForm.get('capex').errors['required']" style="font-size:small">This field is
                    required.</span>
                  <span *ngIf="generatorForm.get('capex').errors['min']" style="font-size:small">This parameter must be
                    higher than 0.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="opex" name="opex" id="opex" #opex type="number"
                  class="form-control ps_renergise_btn" title="The yearly operational cost per peak power [€/kW/year]"
                  placeholder="The yearly operational cost per peak power [€/kW/year]">
                <span *ngIf="!generatorForm.get('opex').valid && generatorForm.get('opex').touched" class="help-block">
                  <span *ngIf="generatorForm.get('opex').errors['required']" style="font-size:small">This field is
                    required.</span>
                  <span *ngIf="generatorForm.get('opex').errors['min']" style="font-size:small">This parameter must be
                    higher than 0.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="lifetime" name="lifetime" id="lifetime" #lifetime type="number"
                  class="form-control ps_renergise_btn" title="The lifetime of the asset [years]"
                  placeholder="The lifetime of the asset [years]">
                <span *ngIf="!generatorForm.get('lifetime').valid && generatorForm.get('lifetime').touched"
                  class="help-block">
                  <span *ngIf="generatorForm.get('lifetime').errors['required']" style="font-size:small">This field is
                    required.</span>
                  <span *ngIf="generatorForm.get('lifetime').errors['min']" style="font-size:small">This parameter must
                    be higher than 1.</span>
                </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <!-- <div class="row pt-1">
                        <div class="col-8 d-grid mx-auto">
                            <form-field appearance="fill">
                                <label class="form-control ps_renergise_btn" title="Power profile of the asset [kW]" for="power_profile">Power profile of the asset [kW]</label>
                                <input   formControlName="power_profile" name="power_profile" id="power_profile" #power_profile type="file" class="ps_hidden form-control ps_renergise_btn" title="Power profile of the asset [kW]" placeholder="Power profile of the asset [kW]">
                            </form-field>                
                        </div>
                        <div class="col-4 d-grid mx-auto">
                            <fa-icon mat-raised-button [icon]="faFile" size="2x" [styles]="{'color': '#FCC78C'}"></fa-icon>
                        </div>
                    </div> -->
        </div>
      </form>

      <div class="row pt-1 pb-2">
        <div class="col-4 d-grid mx-auto">
          <!-- <button type="button" title="Clear form" #clear_button class="btn ps_renergise_btn" (click)="btnClickClear()">Clear</button> -->
          <button type="button" title="Back to modify asset" class="btn ps_renergise_btn" routerLink="/tabmodify"
            routerLinkActive="active">Back</button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <button type="button" title="Modify asset" class="btn ps_renergise_btn" (click)="btnClickModifyAsset()"
            [disabled]="!generatorForm.valid">Modify Asset</button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <div *ngIf="show === 'success'" @fadeOut>
            <img class="submit_img" title="Added" src="assets/images/1x/baseline_task_alt_green_24dp.png" #submit_img>
          </div>
          <div *ngIf="show === 'failed'" @fadeOut>
            <img class="submit_img" title="Added" src="assets/images/1x/baseline_highlight_off_red_24dp.png"
              #submit_img>
          </div>
        </div>
      </div>
      <div class="row pt-1 pb-2">
        <div class="col-8 d-grid mx-auto">
          <span class="alert alert-warning" role="alert" *ngIf = "setWarning">{{warning_msg}}</span>
        </div>
        <div class="col-4 d-grid mx-auto"></div>
    </div>

    </div>

  </div>
</div>
