<div id="ps_loginpage" class="container bg-light">
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <app-left-side-logo></app-left-side-logo>
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-4">
      <h2>Log in</h2>
      <h3>Insert your email and password.</h3>
      <!--social media row-->
      <!--faceboot twitter in-->
      <app-socialmedia></app-socialmedia>
      <form [formGroup]="loginForm" (ngSubmit)="btnClickLogIn()">
        <div class="form-group">
          <div class="row pt-4">
            <div class="col-8 d-grid mx-auto">
              <input required formControlName="email" name="email" id="email" type="text"
                class="form-control ps_renergise_btn" placeholder="Email" title="Email" #email>
              <span *ngIf="!loginForm.get('email').valid && loginForm.get('email').touched" class="help-block">
                <span *ngIf="loginForm.get('email').errors['required'] " style="font-size: small">Email is required.</span>
                <span *ngIf="loginForm.get('email').errors['email'] " style="font-size: small">Email not valid.</span>
              </span>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
        </div>
        <div class="form-group">
          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <div class="input-group">
                <input required formControlName="password" name="password" id="password" [type]="showPassword ? 'text' : 'password'"
                class="form-control ps_renergise_btn" placeholder="Password" title="Password">
                <div class="input-group-append">
                  <span class="input-group-text password_icon">
                    <mat-icon matSuffix (click)="togglePasswordVisibility()">{{showPassword?'visibility_off':'visibility'}}</mat-icon>
                  </span>
                </div>
              </div>
              <span *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched" class="help-block">
                <span *ngIf="loginForm.get('password').errors['required'] " style="font-size: small">Password is required.</span>
              </span>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>
        </div>
      </form>
      <div class="row pt-1">
        <div class="col-4 d-grid mx-auto">
          <button type="button" class="btn ps_renergise_btn" (click)="btnClickBack()" title="Back">Back</button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <button type="button" class="btn ps_renergise_btn" (click)="btnClickLogIn()" title="Log in">Log in</button>
        </div>
        <div class="col-4 d-grid mx-auto"></div>
      </div>
      <div class="row pt-1">
        <div class="col-2 d-grid mx-auto"></div>
        <div class="col-8 d-grid mx-auto"><a class = "forgotPassword" routerLink="/forgot-password" title="Renaissance forgot password page">Forgot Password?</a></div>
        <div class="col-2 d-grid mx-auto"></div>
      </div>
      <div class="row pt-1" *ngIf="this.authService.setErrorMessage">
        <div class="col-6 d-grid mx-auto">
          <div class="alert alert-danger" role="alert" >{{this.authService.errorMessage}}</div>
        </div>
        <div class="col-6 d-grid mx-auto"></div>
      </div>

    </div>
    <div class="col-md-1"></div>
  </div>
