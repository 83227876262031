<div id="ps_impconfigfile" class="container bg-light">
    <app-project-name-label></app-project-name-label>
    <div class="row ps_padding_20">
        <div class="col-md-6">
            <div class="row pt-4">
                <div class="col-sm-2 d-grid mx-auto"></div>
                <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabgeneral" routerLinkActive="active" title="General option"><img title="General Information" class= "img-fluid" src="assets/images/1x/btn_assetGeneral.png"></a></div>
                <div class="col-sm-2 d-grid mx-auto"></div>
                <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabnew" routerLinkActive="active" title="New Asset option"><img title="New Asset" class= "img-fluid" src="assets/images/1x/btn_assetNew.png"></a></div>
                <div class="col-sm-2 d-grid mx-auto"></div>
            </div>    
            
            <div class="row">
                <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabmodify" routerLinkActive="active" title="Asset Modify option"><img title="Asset Modify" class= "img-fluid" src="assets/images/1x/btn_assetModify.png"></a></div>
                <div class="col-sm-1 d-grid mx-auto"></div>
                <div class="col-md-3 d-grid mx-auto"><img title="Import Configuration File" class= "img-fluid" src="assets/images/1x/btn_impConfigFilesSelected.png"></div>
                <div class="col-sm-1 d-grid mx-auto"></div>
                <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabimpdatafile" routerLinkActive="active" title="Import Data File option"><img title="Import Data File" class= "img-fluid" src="assets/images/1x/btn_impDataFiles.png"></a></div>
            </div> 
            
            <div class="row">
                <div class="col-sm-2 d-grid mx-auto"></div>
                <div class="col-md-3 d-grid mx-auto"><a routerLink="/compare" routerLinkActive="active" title="Compare Projects"><img title="Compare Projects" class= "img-fluid" src= "assets/images/1x/btn_assetCompare.png"></a></div>
                <div class="col-sm-2 d-grid mx-auto"></div>
                <div class="col-md-3 d-grid mx-auto"><a routerLink="/calculate" routerLinkActive="active" title="Calculate option"><img title="Calculate" class= "img-fluid" src="assets/images/1x/btn_calculate.png"></a></div>
                <div class="col-sm-2 d-grid mx-auto"></div>
            </div> 
        </div>

      <div class="col-md-6 ps_form">
        <h1><img class="img-fluid ps_imgsize" title="Import Data Files" src="assets/images/1x/importImage.png"> Import Configuration Files</h1>
        <h2>Please upload existing configuration files.</h2>
        
        <div class="row">
            <!-- <div class="col-8 d-grid mx-auto"> -->
                <div class="ps_logo_green">How and why do I import configuration files?</div>
                <div>This feature allows you to upload previously saved LES configurations so that RENERGiSE gives you a benchmarking of the selected alternatives. The procedure is easy, just choose the file from a local directory of your computer and press submit to load it in the respective list.</div>
            <!-- </div> -->
            <!-- <div class="col-4 d-grid mx-auto"></div> -->
        </div>    
        
        <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
                <form-field appearance="fill">
                    <label>Already loaded config files:</label>
                    <select size="3" matNativeControl name="alreadyLoadedData" id="alreadyLoadedData" #alreadyLoadedData class="form-control ps_renergise_btn">
                        <option value="File 1">File 1</option>
                        <option value="File 2">File 2</option>
                        <option value="File 3">File 3</option>
                        <option value="File 4">File 4</option>
                    </select>
                </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
        </div>

        <div class="row pt-1">
            <div class="col d-grid mx-auto"><hr class="ps_horisontal_line"></div>
        </div>

        <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
                <input name="loadDataFile" id="loadDataFile" #loadDataFile type="file" class="form-control ps_renergise_btn" placeholder="Load local data file or folder">
            </div>
            <div class="col-4 d-grid mx-auto">
                <!--<fa-icon mat-raised-button [icon]="faSave" size="2x" [styles]="{'color': '#FCC78C'}"></fa-icon>-->
            </div>
        </div> 

        <div class="row pt-1">
            <div class="col-4 d-grid mx-auto">
                <button type="button" class="btn ps_renergise_btn" (click)="btnClickCancel()">Cancel</button>
            </div>
            <div class="col-4 d-grid mx-auto">
                <button type="button" class="btn ps_renergise_btn" (click)="btnClickSubmit()">Submit</button>
            </div>
            <div class="col-4 d-grid mx-auto">
                
            </div>
        </div>  

      </div>
      
  </div>
</div>