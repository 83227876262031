import { Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
import { FormBuilder, Validators } from '@angular/forms';

import { faMapMarkerAlt, faCalendarWeek, faClock, faFile } from '@fortawesome/free-solid-svg-icons';
import { CoordinatesInteractionService } from '../coordinates-interaction.service';

import { trigger, style, animate, transition } from '@angular/animations';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth.service';
import * as saveAs from 'file-saver';
import { Router } from '@angular/router';


const leaveTrans = transition(':enter', [
  style({
    opacity: 1
  }),
  animate('3s ease-out', style({
    opacity: 0,
  }))
])

const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-new-asset6-solar',
  templateUrl: './new-asset6-solar.component.html',
  styleUrls: ['./new-asset6-solar.component.css'],
  animations: [
    fadeOut,
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ height: 80, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 80, opacity: 1 }),
            animate('1s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class NewAsset6SolarComponent implements OnInit {

  constructor(
    private _coordinatesInteraction: CoordinatesInteractionService,
    private fb: FormBuilder,
    private http: HttpClient,
    private authService: AuthService, 
    private router: Router
  ) { }

  faMapMarkerAlt = faMapMarkerAlt;
  faCalendarWeek = faCalendarWeek;
  faClock = faClock;
  faFile = faFile;
  reset_state: boolean = false;
  show: string = "";
  setWarning: boolean = false;
  warning_msg: string = "";
  user = GlobalConstants.registeredUser;
  project = GlobalConstants.projectId


  @ViewChild('power_profile_label') power_profile_label: ElementRef;
  @ViewChild('post_message') post_message: ElementRef;
  @ViewChild('clear_button') clear_button: ElementRef;
  @ViewChild('asset_id') asset_id: ElementRef;


  solarForm = this.fb.group({
    tilt: ['', [Validators.min(0), Validators.max(90)]],
    azimuth: ['', [Validators.min(0), Validators.max(90)]],
    tracking: ['Is the solar pv a single axis tracking system?'],
    module: ['Select a module'],
    temp_parameters: ['Select a temperature parameters'],
    min_capacity: [{ value: null, disabled: false }, [Validators.required, Validators.min(0)]],
    max_capacity: [{ value: null, disabled: false }, [Validators.required, Validators.min(0)]],
    capex: [{ value: null, disabled: false }, [Validators.required, Validators.min(0)]],
    opex: [{ value: null, disabled: false }, [Validators.required, Validators.min(0)]],
    lifetime: [{ value: null, disabled: false }, [Validators.required, Validators.min(1)]],
    asset_id: ['', [Validators.required]],
    power_profile: ['']
  });

  onChange(event) {
    if (event.target.files[0]) {
      this.file = event.target.files[0];
      this.power_profile_label.nativeElement.innerHTML = this.file.name
      for (var i in this.solarForm.controls) {
        if (i != 'power_profile' && i != 'asset_id' && i != 'min_capacity' && i != 'max_capacity' && i != 'capex' && i != 'opex' && i != 'lifetime') {
          this.solarForm.controls[i].reset();
          this.solarForm.controls[i].disable();
        }
      }
    }
    else {
      this.file = null
      this.power_profile_label.nativeElement.innerHTML = "Power profile of the asset normalised to a peak power of 1 kW [kW]"
      for (var i in this.solarForm.controls) {
        this.solarForm.controls[i].enable();
      }
    }
  }

  description = GlobalConstants.assetDescription;
  question = GlobalConstants.assetQuestion;
  answer = GlobalConstants.assetAnswer;
  globalUser = GlobalConstants.registeredUser;
  file: File = null;

  ngOnInit(): void {
    console.log("Registered user?",GlobalConstants.registeredUser);
    this.user = GlobalConstants.registeredUser;
    if(GlobalConstants.projectId){
      console.log("Selected project", GlobalConstants.projectId);
      this.project = GlobalConstants.projectId;
    }
    else{
      alert('You must select a project to continue.');
      this.router.navigateByUrl('/myprojects');
    }

    //Set validators for value check
    this.solarForm.get("min_capacity").valueChanges
      .subscribe(data => {
        this.changeValidators();
      });
    this.solarForm.get("max_capacity").valueChanges
      .subscribe(data => {
        this.changeValidators();
      });

  }

  changeValidators() {

    var min_capacity = this.solarForm.get("min_capacity").value;
    var max_capacity = this.solarForm.get("max_capacity").value;

    if (min_capacity !== null) {
      this.solarForm.controls["max_capacity"].setValidators([Validators.required, Validators.min(min_capacity)]);
      // this.solarForm.controls["max_capacity"].updateValueAndValidity();
    }
    if (max_capacity !== null) {
      this.solarForm.controls["min_capacity"].setValidators([Validators.required, Validators.min(0), Validators.max(max_capacity)]);
      // this.solarForm.controls["min_capacity"].updateValueAndValidity();
    }
  }

  onDownloadSolar(){

    const params = new HttpParams(
      {
        fromObject: {
          user_id: GlobalConstants.registeredUser,
          project_id:GlobalConstants.projectId
        }
      }
    )

    var host = "https://renergisetool.evergi.be/";
    var url ="https://renergisetool.evergi.be/information/assets/solarpvs/powerprofile"
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type','text/csv')

    this.http.get(url+'?' + params.toString(), {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `solarpvs_powerprofile.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  onDownloadSolarExample(){
    var host = "https://renergisetool.evergi.be/";
    var url ="https://renergisetool.evergi.be/information/assets/solarpvs/powerprofile"
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type','text/csv')
      
    this.http.get(url, {responseType:'blob'}).subscribe(
      (response:any) => {
        saveAs(response, `solarpvs_powerprofile_example.csv`)
      },
      error => {
        console.log("error: " + error);
      }
    );
  }

  isShown: boolean = false; // hidden by default
  toggleShow() {
    this.isShown = !this.isShown;
  }

  btnClickClear() {
    if (this.reset_state) {
      this.solarForm.reset()

      this.clear_button.nativeElement.innerHTML = "Default"
      this.reset_state = !this.reset_state;
      this.file = null
    } else {
      this.solarForm.patchValue({
        tilt: 50,
        azimuth: 0,
        tracking: 'False',
        module: 'mean_Mono_c_Si',
        temp_parameters: 'freestanding',
        min_capacity: 0,
        max_capacity: 200,
        capex: 800,
        opex: 0.2,
        lifetime: 25,
        asset_id: 'default_solarpv'
      });
      this.file = null
      this.clear_button.nativeElement.innerHTML = "Clear"
      this.reset_state = !this.reset_state;
    }
    this.solarForm.enable()
    this.power_profile_label.nativeElement.innerHTML = "Power profile of the asset normalised to a peak power of 1 kW [kW]"

  }

  btnClickAddAsset() {
    console.log("Btn Add Asset clicked");
    var host = "https://renergisetool.evergi.be/";

    const headers = new HttpHeaders()
      .append(
        'Content-Type',
        'application/x-www-form-urlencoded'
      );
    const params = new HttpParams(
      {
        fromObject: this.solarForm.value
      }
    )
    var url = host + "users/" + this.user + "/projects/" + this.project + "/solarpvs/"
    if (this.file) {
      const formdata = new FormData();

      formdata.append("powerprofile", this.file, this.file.name)


      this.http.post(url + '?' + params.toString(), formdata, { observe: 'response' }).subscribe(
        response => {
          console.log("response: " + response);
          if (response['status'] == 200) {
            this.show = "success";
            setTimeout(() => {
              this.show = " ";
            }, 2000);
          }
        },
        error => {
          console.log("error: " + error);
          this.show = 'failed';
          setTimeout(() => {
            this.show = '';
          }, 2000);
          if (error.error['warning'] != null) {
            this.warning_msg = error.error['warning'];
            this.setWarning = true;
          }
        }
      )
    } else {
      this.http.post<any>(url + '?' + params.toString(), { headers: headers }, { observe: 'response' }).subscribe(
        response => {
          console.log("response: " + response);
          if (response['status'] == 200) {
            this.show = "success";
            setTimeout(() => {
              this.show = " ";
            }, 2000);
          }
        },
        error => {
          console.log("error: " + error);
          this.show = 'failed';
          setTimeout(() => {
            this.show = '';
          }, 2000);
          if (error.error['warning'] != null) {
            this.warning_msg = error.error['warning'];
            this.setWarning = true;
          }
        }
      )
    }





  }
}