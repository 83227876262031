<div id="ps_tab1" class="container bg-light">
  <app-project-name-label></app-project-name-label>
  <div class="row ps_padding_20">
    <div class="col-md-6">
      <div class="row pt-4">
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabgeneral" routerLinkActive="active" title="General option"><img title="General Information" class= "img-fluid" src="assets/images/1x/btn_assetGeneral.png"></a></div>
        <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabnew" routerLinkActive="active" title="New Asset option"><img title="New Asset" class= "img-fluid" src="assets/images/1x/btn_assetNew.png"></a></div>
        <div class="col-sm-6 d-grid mx-auto"></div>
      </div>    
      
      <div class="row">
          <div class="col-sm-1 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/tabmodify" routerLinkActive="active" title="Asset Modify option"><img title="Asset Modify" class= "img-fluid" src="assets/images/1x/btn_assetModifySelected.png"></a></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/compare" routerLinkActive="active" title="Compare Projects"><img title="Compare Projects" class= "img-fluid" src= "assets/images/1x/btn_assetCompare.png"></a></div>
          <div class="col-sm-5 d-grid mx-auto"></div>
      </div> 
      
      <div class="row">
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"></div>
          <div class="col-md-3 d-grid mx-auto"><a routerLink="/calculate" routerLinkActive="active" title="Calculate option"><img title="Calculate" class= "img-fluid" src="assets/images/1x/btn_calculate.png"></a></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
          <div class="col-sm-2 d-grid mx-auto"></div>
      </div> 
    </div>
    <div class="col-md-6 ps_form rounded ps_white">
      <form [formGroup]="publicForm" (ngSubmit)="btnClickModifyAsset()">
        <div class="form-group">
          <div class="row pt-4">
            <app-modify-asset-input-data [activate_publicgrids]="'active'"></app-modify-asset-input-data>
            <h1><img class="img-fluid ps_imgsize" title="Import Data Files" src="assets/images/1x/newAssetImage.png">
              Modify asset</h1>
            <!-- <h2>{{ description }}</h2> -->
            <div class="ps_logo_green">Why do I modify an Asset?</div>
            <div>If you want to apply minor changes into a specified energy system, you can chose the Asset that wish to
              change and re-calculate the outcome of the renewed configuration.</div>
          </div>

          <div class="row pt-2">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input formControlName="asset_id" name="asset_id" id="asset_id" #asset_id type="text"
                  class="form-control ps_renergise_btn" title="Asset name" placeholder="Asset name">
                  <span *ngIf="!publicForm.get('asset_id').valid && publicForm.get('asset_id').touched" class="help-block">
                    <span *ngIf="publicForm.get('asset_id').errors['required']" style="font-size:small">This field is required.</span>
                  </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input type="number" formControlName="peak_influence" ngModel name="peak_influence" id="peak_influence" #peak_influence class="form-control ps_renergise_btn" title="Monthly electricity peak tariff [€/kW/month]"
                  placeholder="Monthly electricity peak tariff [€/kW/month]">
                  <span *ngIf="!publicForm.get('peak_influence').valid && publicForm.get('peak_influence').touched" class="help-block">
                    <span *ngIf="publicForm.get('peak_influence').errors['required']" style="font-size:small">This field is required.</span>
                    <span *ngIf="publicForm.get('peak_influence').errors['min']" style="font-size:small">This parameter must be higher than 0.</span>
                  </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input type="number" formControlName="sell_to_grid_const" ngModel name="sell_to_grid_const" id="sell_to_grid_const" #sell_to_grid_const class="form-control ps_renergise_btn" title="Price at which you sell electricity to the public grid [€/KWh]"
                  placeholder="Price at which you sell electricity to the public grid [€/KWh]" >
                  <span *ngIf="!publicForm.get('sell_to_grid_const').valid && publicForm.get('sell_to_grid_const').touched" class="help-block">
                    <span *ngIf="publicForm.get('sell_to_grid_const').errors['required']" style="font-size:small">This field or files are required.</span>
                  </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <input type="number" formControlName="buy_from_grid_const" ngModel name="buy_from_grid_const" id="buy_from_grid_const" #buy_from_grid_const class="form-control ps_renergise_btn" title="Price at which you buy electricity from the grid [€/KWh]"
                  placeholder="Price at which you buy electricity from the grid [€/KWh]">
                  <span *ngIf="!publicForm.get('buy_from_grid_const').valid && publicForm.get('buy_from_grid_const').touched" class="help-block">
                    <span *ngIf="publicForm.get('buy_from_grid_const').errors['required']" style="font-size:small">This field or files are required.</span>
                  </span>
              </form-field>
            </div>
            <div class="col-4 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <label class="form-control ps_renergise_btn" #sell_to_grid_label
                  title="Price at which you sell electricity to the public grid [&euro;/KWh]" for="sell_to_grid">Price
                  you sell electricity to the public grid [&euro;/KWh]</label>
                <input formControlName="sell_to_grid" ngModel name="sell_to_grid" id="sell_to_grid" #sell_to_grid
                  type="file" class="ps_hidden form-control ps_renergise_btn"
                  title="Price at which you sell electricity to the public grid [&euro;/KWh]"
                  placeholder="Price at which you sell electricity to the public grid [€/KWh]" observeFiles
                  (change)="onChange1($event)">
              </form-field>
            </div>
            <div class="col-2 d-grid mx-auto">
              <div class="col">
                <fa-icon
                  mat-raised-button
                  [icon]="faFile"
                  size="2x"
                  style="cursor: pointer; color: '#FCC78C'"
                  (click)="onDownloadSell()"
                  title="Download empty template"
                ></fa-icon>
                <fa-icon title="Download example csv template" mat-raised-button [icon]="faFile" size="2x"
                    style="cursor: pointer; color: '#3babb0';margin-left: 4px;" (click)="onDownloadSellExample()"></fa-icon>
              </div>
            </div>
            <div class="col-2 d-grid mx-auto"></div>
          </div>

          <div class="row pt-1">
            <div class="col-8 d-grid mx-auto">
              <form-field appearance="fill">
                <label class="form-control ps_renergise_btn" #buy_from_grid_label
                  title="Price at which you buy electricity from the grid [&euro;/KWh]" for="buy_from_grid">Price you
                  buy electricity from the grid [&euro;/KWh]</label>
                <input formControlName="buy_from_grid" ngModel name="buy_from_grid" id="buy_from_grid" #buy_from_grid
                  type="file" class="ps_hidden form-control ps_renergise_btn"
                  title="Price at which you buy electricity from the grid [&euro;/KWh]"
                  placeholder="Price at which you buy electricity from the grid [€/KWh]" observeFiles
                  (change)="onChange2($event)">
              </form-field>
            </div>
            <div class="col-2 d-grid mx-auto">
              <div class="col">
                <fa-icon
                  mat-raised-button
                  [icon]="faFile"
                  size="2x"
                  style="cursor: pointer; color: '#FCC78C'"
                  (click)="onDownloadBuy()"
                  title="Download empty template"
                ></fa-icon>
                <fa-icon title="Download example csv template" mat-raised-button [icon]="faFile" size="2x"
                style="cursor: pointer; color: '#3babb0';margin-left: 4px;" (click)="onDownloadBuyExample()"></fa-icon>
              </div>
            </div>
            <div class="col-2 d-grid mx-auto"></div>
          </div>

        </div>
      </form>

      <div class="row pt-1 pb-2">
        <div class="col-4 d-grid mx-auto">
          <!-- <button type="button" #clear_button title="Clear form" class="btn ps_renergise_btn" (click)="btnClickClear()">Clear</button> -->
          <button type="button" title="Back to modify asset" class="btn ps_renergise_btn" routerLink="/tabmodify"
            routerLinkActive="active">Back</button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <button type="button" title="Modify asset" class="btn ps_renergise_btn" (click)="btnClickModifyAsset()"
            [disabled]="!publicForm.valid">Modify Asset</button>
        </div>
        <div class="col-4 d-grid mx-auto">
          <div *ngIf="show === 'success'" @fadeOut>
            <img class="submit_img" title="Modified" src="assets/images/1x/baseline_task_alt_green_24dp.png"
              #submit_img>
          </div>
          <div *ngIf="show === 'failed'" @fadeOut>
            <img class="submit_img" title="Modified" src="assets/images/1x/baseline_highlight_off_red_24dp.png"
              #submit_img>
          </div>
        </div>
      </div>
      <div class="row pt-1 pb-2">
        <div class="col-8 d-grid mx-auto">
          <span class="alert alert-danger" role="alert" *ngIf = "setWarning">{{warning_msg}}</span>
        </div>
        <div class="col-4 d-grid mx-auto"></div>
      </div>

    </div>

  </div>
</div>
